<template>
    <div :class="display ? 'sai-mobile-modal show' : 'sai-mobile-modal' ">

        <div class="mobile-modal-header">
            <span class="modal-header-left" @click="closeOverlay">
                <i class="si si-chevron-left"></i>
            </span>
            <span class="modal-header-center">
                {{headerTitle}}
            </span>
            <span class="modal-header-right" @click="saveChanges">
                {{saveTitle}}
            </span>
        </div>

        <div class="mobile-modal-content">
            <div class="modal-content-search" v-if="searchable">
                <SaiInput className="content-search-input" v-model="search" placeholder="search" type="text"/>
            </div>

            <ul class="content-list">
                <li v-for="result in filteredResults " :key="result.id">
                    <span>
                       <input type="checkbox" :id="'mob'+result.id"
                              :data-id="result.id" :data-value="result.text"
                              :checked="filteredPreSelected.indexOf(result.text) >= 0"
                              @change="saveSelection">
                        <label :for="'mob'+result.id">{{result.text}}</label>
                    </span>
                </li>
            </ul>

        </div>

        <div class="clear-selected" v-if="hasClearSelect && selectedElements.length">
            <a class="clearBtn" @click="clearSelected">Clear selected</a>
        </div>

    </div>
</template>

<script>
    import {apiGetFieldOptions} from '@/components/SearchBar/api';
    import {createNamespacedHelpers} from 'vuex';

    const {mapGetters, mapActions} = createNamespacedHelpers('searchbar');
    import SaiInput from '@/components/common/SaiInput';

    export default {
        name: "OverlayMultiSelect",
        components: {
            SaiInput
        },
        data() {
            return {
                search: '',
                results: [],
                isOptionsLoaded: false,
                lookupFieldName: '',
                selectedElements: this.preSelectedOptions
            }
        },
        props: {
            display: {type: Boolean, default: false},
            headerTitle: {type: String, default: ''},
            saveTitle: {type: String, default: 'Done'},
            hasClearSelect: {type: Boolean, default: true},
            searchable: {type: Boolean, default: false},
            preSelectedOptions: {type: Array, default: () => []},
            field: {type: String, default: '', required: true},
            options: {type: Array, default: () => []},
            lookupField: {type: String, default: '', required: false},
            initialOptions: { type: Array, default: () => [], required: false }
        },
        mounted() {
            //load options from store, if its already loaded
            this.lookupFieldName = this.lookupField ? this.lookupField : this.field;
            const options = this.getLookUpOptions(this.lookupFieldName);
            if (options && options.length) {
                this.results = this.getUniqueOptions(options);
                this.isOptionsLoaded = true;
            } else {
                this.fetchData(this.field);
            }
        },
        methods: {
            ...mapActions([
                'setLookUpOptions',
            ]),
            clearSelected: function() {
                this.selectedElements = [];
            },
            fetchData: async function (field) {
                let loader = await this.$loading.show();
                const options = await apiGetFieldOptions(field);
                this.results = this.getUniqueOptions(options);
                this.setLookUpOptions({type: this.lookupFieldName, options: options});
                await loader.hide();
            },
            closeOverlay: function () {
                this.search = '';
                this.$emit('closeOverlay')
            },
            saveChanges: function () {
                this.search = '';
                this.$emit('saveChanges', this.selectedElements)
            },
            addToSelection(el) {
                const obj = {id: el.getAttribute("data-id"), text: el.getAttribute("data-value")};
                this.selectedElements.push(obj);
            },
            removeFromSelection(el) {
                this.selectedElements = this.selectedElements.filter(selected => {
                    return selected.id !== el.getAttribute("data-id");
                })
            },
            saveSelection: function ($event) {
                if ($event.target.checked) {
                    this.addToSelection($event.target);
                } else {
                    this.removeFromSelection($event.target);
                }
            },
            getUniqueOptions(options) {
                let vm = this;
                let uniqueOptions = [];
                if(this.initialOptions.length>0) {
                    let filteredOptions = options.filter(function(option) {
                        for (const initOption of vm.initialOptions) {
                            if (initOption.id == option.id) {
                                return false;
                            }
                        }
                        return true;
                    });
                    uniqueOptions = [ ...this.initialOptions, ...filteredOptions]
                } else {
                    uniqueOptions = options;
                }
                return uniqueOptions;
            }
        },
        computed: {
            ...mapGetters([
                'getLookUpOptions'
            ]),
            filteredResults() {
                return this.results.filter(result => {
                    return result.text.toLowerCase().includes(this.search.toLowerCase())
                })
            },
            filteredPreSelected() {
                const preSelected = [];
                this.selectedElements.forEach(sel => {
                    preSelected.push(sel.text)
                });
                return preSelected;
            }
        }
    }
</script>
