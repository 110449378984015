export default {
    getLoadingStatus: state => state.loading,

    getFontSizes: state => state['toolbar'].fontSizes,
    getToolbarSearchPlaceHolder: state => state['toolbar'].searchPlaceholder,
    getToolbarTools: state => state['toolbar'].tools,
    getActiveToolbarTool: state => state['toolbar'].activeTool,
    isActiveToolbarTool: state => tool => (state['toolbar'].activeTool === tool),
    getActiveFontSize: state => state['toolbar'].activeFontSize,
    getTableOfContents: state => state.tableOfContents,
    getTOCSchema: state => state.TOCSchema,
    getUrlParams: state => state.urlParams,
    getSectionContent: state => sectionId => {
        return state.sectionsContent.find(section => section.id === sectionId)
    },
    getSectionContentText: state => sectionId => {
        return state.sectionsContentText.find(section => section.id === sectionId)
    },
    getAllSectionContent: state => state.sectionsContent,
    getAllSectionContentText: state => state.sectionsContentText,
    getSearchItems: state => state.searchItems,
    getSearchText: state => state.searchText,
    getLeftPaneMenuItems: state => state['leftPane'].menuItems,
    getActiveMenuItem: state => state['leftPane'].activeMenuItem,
    isActiveMenuItem: state => menuItem => (state['leftPane'].activeMenuItem === menuItem),
    isActiveMarker: state => marker => (state['leftPane'].activeMarker === marker),

    getBookmarks: state => state.bookmarks,
    getComments: state => state.comments,
    getSearchComments: state => state.searchComments.filter(comment => {
        return comment.sectionId != 0
    }),
    getCommentsByHighlightId: state => highlightId => {
        let highlightComments = [];
        state.comments.forEach(comment => {
            if (comment.parentId == highlightId) {
                highlightComments.push(comment);
            }
        })
        return highlightComments;
    },
    getSearchCommentText: state => state.searchCommentText,
    getSortCommentBy: state => state.sortCommentBy,
    getHighlights: state => state.highlights,
    getHighlightsBySectionId: state => sectionId => {
        let sectionHighlights = [];
        state.highlights.forEach(highlight => {
            if (highlight.sectionId == sectionId) {
                sectionHighlights.push(highlight);
            }
        })
        return sectionHighlights;
    },
    getBookmarkBySectionId: state => bookmarkId => {
        let bookmarkDetails = '';
        state.bookmarks.forEach(bookmark => {
            if (bookmark.sectionId == bookmarkId) {
                bookmarkDetails = bookmark;
            }
        })
        return bookmarkDetails;
    },
    getCommentById: state => commentId => {
        let commentDetails = '';
        state.comments.forEach(comment => {
            if (comment.commentId == commentId) {
                commentDetails = comment;
            }
        })
        return commentDetails;
    },
    getHighlightById: state => highlightId => {
        let highlightDetails = '';
        state.highlights.forEach(highlight => {
            if (highlight.highlightId == highlightId) {
                highlightDetails = highlight;
            }
        })
        return highlightDetails;
    },
    isBookmarked: state => bookmarkId => {
        let res = false;
        state.bookmarks.forEach(bookmark => {
            if (bookmark.sectionId == bookmarkId) {
                res = true;
            }
        })
        return res;
    },
    isCommented: state => commentId => {
        let res = false;
        state.comments.forEach(comment => {
            if (comment[0].sectionId === commentId) {
                res = true;
            }
        })
        return res;
    },
    isBookmarkToolsActive: state => state.isBookmarkToolsActive,
    isCommentToolsActive: state => state.isCommentToolsActive,
    // isHighlightableActive: state => state.tool state.isHighlightableActive,
    isHighlightableActive: state => (state['toolbar'].activeTool === 'highlight'),
    isVisibleMobileLeftPane: state => state['leftPane'].mobileLeftPane,
    getWebreaderUser: state => state.username,

}