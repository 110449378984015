<template>
    <div class="form-row section advanced-metals" style="">
            <div class="form-group col-sm-4 col-md-4 col-lg-4 metalsCountrySearch">
                <FilterMultiSelect :placeholder="$t('fld_country')" name="metals_country" field="metals.country"/>
                <input type="hidden" name="metals_country" :value="getMetalsFilter('country').id" :disabled="isAdvanceFilterDisabled('metals')"/>
            </div>
            <div class="form-group col-sm-4 col-md-4 col-lg-4 metalsFormSearch">
                <FilterMultiSelect :placeholder="$t('frm_form')" name="metals_form" field="metals.form"/>
                <input type="hidden" name="metals_form" :value="getMetalsFilter('form').id" :disabled="isAdvanceFilterDisabled('metals')"/>
            </div>
            <div class="form-group col-sm-4 col-md-4 col-lg-4 metalsStandardSearch">
                <SaiAutoSuggest 
                    labelBy="label" 
                    trackBy="value"
                    className="metalsSection form-control form-control-select shadow-none form-control-dropdown form2row form-control-text-second-row ui-autocomplete-input"
                    api="/management/search/field/metals_standard"
                    :apiExtraParams="{type: 'standard'}"
                    apiHeaderContentType="form-urlencoded"
                    :placeholder="$t('frm_standards')"
                    name="metals_standard" id="metals_standard"
                    :value="getMetalsFilter('standard')"
                    @input="$event => setMetalsFilter({field: 'standard', payload: $event})"
                    @addSuggestValue="$event => setMetalsFilter({field: 'standard', payload: $event})" :disabled="isAdvanceFilterDisabled('metals')"/>
            </div>
            <FilterComposition type="chemicalComposition" :placeholder="$t('frm_element')" :disabled="isAdvanceFilterDisabled('metals')"/>
            <FilterComposition type="mechanicalComposition" :max="9999" :placeholder="$t('frm_property')" :disabled="isAdvanceFilterDisabled('metals')"/>
            <div class="form-group col-sm-4 col-md-4 col-lg-4 metalsKeywordSearch">
                <SaiInput class="metalsSection form-control form-control-select shadow-none form-control-dropdown form2row form-control-text-second-row"
                  :placeholder="$t('frm_metals_keywords')" name="metals_keyword" id="metals_keyword"
                  :value="getMetalsFilter('keyword')"
                  @input="$event => setMetalsFilter({field: 'keyword', payload: $event})" :disabled="isAdvanceFilterDisabled('metals')"/>
            </div>

        </div>
</template>
<script>
    import FilterMultiSelect from '@/components/SearchBar/SearchBarDesktop/FilterMultiSelect';
    import SaiAutoSuggest from '@/components/common/SaiAutoSuggest';
    import SaiInput from '@/components/common/SaiInput';
    import FilterComposition from './FilterComposition';

    import { createNamespacedHelpers } from 'vuex';
    const { mapGetters, mapActions } = createNamespacedHelpers('searchbar');
    export default {
        name: "AdvanceSearchMetal",
         components: {
            FilterMultiSelect,
            FilterComposition,
            SaiAutoSuggest,
            SaiInput
        },
        computed: {
            ...mapGetters([
                'getMetalsFilter',
                'isAdvanceFilterDisabled'
            ])
        },
        methods: {
            ...mapActions([
                'setMetalsFilter'
            ])
        }
    }
</script>

