<template>
    <div class="advanceSearchBox" v-show="isAdvanceSearchActive(getActiveSearchTab) ">
        <AdvanceSearchStandard v-show="isActiveTab('standards')" />
        <AdvanceSearchCollection v-show="isActiveTab('collections')"/>
        <AdvanceSearchMetal v-show="isActiveTab('metals')"/>
        <AdvanceSearchMetalSupplier v-show="isActiveTab('metalsSupplier')"/>
        <AdvanceSearchMaterial v-show="isActiveTab('materials')"/>
    </div>
</template>

<script>
    import { createNamespacedHelpers } from 'vuex';
    const { mapGetters } = createNamespacedHelpers('searchbar');
    import AdvanceSearchStandard from './Standards/AdvanceSearchStandard'
    import AdvanceSearchCollection from './Collections/AdvanceSearchCollection'
    import AdvanceSearchMetal from './Metals/AdvanceSearchMetal'
    import AdvanceSearchMetalSupplier from './MetalsSupplier/AdvanceSearchMetalSupplier'
    import AdvanceSearchMaterial from './Materials/AdvanceSearchMaterial'
    export default {
        name: "AdvancedSearch",
        components: {
            AdvanceSearchStandard,
            AdvanceSearchCollection,
            AdvanceSearchMetal,
            AdvanceSearchMetalSupplier,
            AdvanceSearchMaterial
        },
        computed: {
            ...mapGetters([
                'isActiveTab',
                'isAdvanceSearchActive',
                'getActiveSearchTab',
                'isAdvanceSearchAllowed'
            ])
        }
    }
</script>
