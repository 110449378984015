<template>
    <div class="form-row section advanced-materials" style="">
            
            <!--Chemical analysis-->
            <div class="form-group materialsProperties  materials-col materialsAnalysisSearch">
                <FilterMultiSelect :multiple="true" name="material_chemical_analysis[]" :placeholder="$t('frm_chemical_analysis')" field="materials.chemicalAnalysis" :searchable="false"/>
                <input v-for="(chemicalAnalysis, index) in getMaterialsFilter('chemicalAnalysis')" :key="index" type="hidden" name="material_chemical_analysis[]" :value="chemicalAnalysis.id"  :disabled="isAdvanceFilterDisabled('materials')"/>
            </div>
            <!--Formulations-->
            <div class="form-group materialsProperties  materials-col materialsFormulationSearch">
                <FilterMultiSelect :multiple="true" name="material_formulation[]" :placeholder="$t('frm_formulations')" field="materials.formulation" :searchable="false" />
                <input v-for="(formulation, index) in getMaterialsFilter('formulation')" :key="index" type="hidden" name="material_formulation[]" :value="formulation.id"  :disabled="isAdvanceFilterDisabled('materials')"/>
            </div>

            <!--Special characteristics-->
            <div class="form-group materialsProperties materials-col materialsCharsSearch">
                <FilterMultiSelect :multiple="true" name="material_special_char[]" :placeholder="$t('frm_special_chars')" field="materials.specialCharacteristics" :searchable="false" />
                <input v-for="(specialCharacteristics, index) in getMaterialsFilter('specialCharacteristics')" :key="index" type="hidden" name="material_special_char[]" :value="specialCharacteristics.id"  :disabled="isAdvanceFilterDisabled('materials')"/>
            </div>

            <!--Chemical resistance-->
            <div class="form-group materialsProperties materials-col materialsResistanceSearch">
                <FilterMultiSelect :multiple="true" name="material_chemical_resistance[]" :placeholder="$t('frm_chemical_resistance')" field="materials.chemicalResistance" :searchable="false" />
                <input v-for="(chemicalResistance, index) in getMaterialsFilter('chemicalResistance')" :key="index" type="hidden" name="material_chemical_resistance[]" :value="chemicalResistance.id"  :disabled="isAdvanceFilterDisabled('materials')"/>
            </div>

            <div class="form-group materialsProperties materials-col materialsProcessingSearch">
                <FilterMultiSelect :multiple="true" name="material_processing[]" :placeholder="$t('frm_processing')" field="materials.processing" :searchable="false"/>
                <input v-for="(processing, index) in getMaterialsFilter('processing')" :key="index" type="hidden" name="material_processing[]" :value="processing.id"  :disabled="isAdvanceFilterDisabled('materials')"/>     
            </div>
            <FilterProperties type="mechanicalProperty" :placeholder="$t('frm_property')" :disabled="isAdvanceFilterDisabled('materials')"/>
            <FilterProperties type="physicalProperty" :placeholder="$t('frm_property')" :disabled="isAdvanceFilterDisabled('materials')"/>
            <FilterProperties type="electricalProperty" :placeholder="$t('frm_property')" :disabled="isAdvanceFilterDisabled('materials')"/>
            <FilterProperties type="impactProperty" :placeholder="$t('frm_property')" :disabled="isAdvanceFilterDisabled('materials')"/>
            <FilterProperties type="processingProperty" :placeholder="$t('frm_property')" :disabled="isAdvanceFilterDisabled('materials')"/>
            <FilterProperties type="thermalProperty" :placeholder="$t('frm_property')" :disabled="isAdvanceFilterDisabled('materials')"/>
            <FilterProperties type="otherProperty" :placeholder="$t('frm_property')" :disabled="isAdvanceFilterDisabled('materials')"/>
        </div>
</template>
<script>
    import FilterProperties from './FilterProperties';
    import FilterMultiSelect from '@/components/SearchBar/SearchBarDesktop/FilterMultiSelect';
    import { createNamespacedHelpers } from 'vuex';
    const { mapGetters } = createNamespacedHelpers('searchbar');
    export default {
        name: "AdvanceSearchMaterial",
        components: {
            FilterProperties,
            FilterMultiSelect
        }, 
        computed: {
            ...mapGetters([
                'getMaterialsFilter',
                'isAdvanceFilterDisabled'
            ])
        },

    }
</script>
