<template>
    <div class="filter-results-in" v-if="getSearchResultsFilterOptions(field).length">
        <div class="filter-header">
            {{$t('frm_results_in')}}
        </div>
        <div class="filter-container">
            <SaiRadio :name="name"
                      class="small"
                      :prefix="prefix"
                      :value="getSearchResultsFilterSelectedValues(field)"
                      :options="getSearchResultsFilterOptions(field)"
                      @change="handleRadioChange"
                      :labelBy="labelBy"
                      :trackBy="trackBy"/>
        </div>
    </div>
</template>

<script>
    import {createNamespacedHelpers} from 'vuex';
    const {mapGetters, mapActions} = createNamespacedHelpers('searchresults');
    import SaiRadio from '@/components/common/SaiRadio'
    export default {
        name: "FilterResultsIn",
        props: {
            options: {type: Array, default: () => []},
            field: { type: String, default: '', required: true },
            name: {type: String},
            value: {type: String},
            labelBy: {type: String, default: 'key'},
            trackBy: {type: String, default: 'val'},
            prefix: {type: String, default: ''}
        },
        components:{
            SaiRadio
        },
        computed: {
            ...mapGetters([
                'getSearchResultsFilterOptions',
                'getSearchResultsFilterSelectedValues'
            ]),
        },
        methods: {
            ...mapActions([
                'setSearchResultsFilterSelectedValues'
            ]),
            handleRadioChange: function($event) {
                this.setSearchResultsFilterSelectedValues({ field:this.field,fieldType:'radio', payload:$event});
            }
        }
    }
</script>

