<template>
    <div>
        <SaiInput type="text"
                  class="search-box standard-field standardSection assetsSection icsSection form-control shadow-none form-control-text standards-inputs row1-font ui-autocomplete-input"
                  :placeholder="$t('frm_collection_document_number')" name="name" id="name"
                  :value="getCollectionsFilter('documentNumber')"
                  @input="$event => setCollectionsFilter({field: 'documentNumber', payload: $event})"
                  style="width: 100%;"
                  @keyup.enter="performMainSearch"
                  autocomplete="off" :disabled="!isActiveTab('collections')"/>
    </div>
</template>
<script>
import SaiInput from '@/components/common/SaiInput';
import {createNamespacedHelpers} from 'vuex';

const {mapGetters, mapActions} = createNamespacedHelpers('searchbar');
const {mapActions:searchResultsActions } =createNamespacedHelpers('searchresults');
export default {
    name: "QuickSearchCollection",
    components: {
        SaiInput
    },
    computed: {
        ...mapGetters([
            'getCollectionsFilter',
            'isActiveTab'
        ]),
    },
    methods: {
        ...mapActions([
            'setCollectionsFilter'
        ]),
        ...searchResultsActions([
            'performMainSearch'
        ])
    }
}
</script>
