<template>
        <div class="searchBoxList">
            <FilterBoxSelect :title="$t('fld_country')" field="metals.country" :searchable="true"/>
            <input type="hidden" name="metals_country" :value="getMetalsFilter('country').id" :disabled="!isActiveTab('metals')">

            <FilterBoxSelect :title="$t('frm_form')" field="metals.form" :searchable="true"/>
            <input type="hidden" name="metals_form" :value="getMetalsFilter('form').id" :disabled="!isActiveTab('metals')">

            <FilterBoxInput :title="$t('frm_standards')" field="metals.standard"
                            :placeholder="$t('frm_standards')" inputName="metals_standard" :disabled="!isActiveTab('metals')"/>

            <FilterBoxInput :title="$t('idx_keywords')" field="metals.keyword"
                            :placeholder="$t('frm_metals_keywords')" inputName="metals_keyword" :disabled="!isActiveTab('metals')"/>

        </div>

</template>

<script>
    import {createNamespacedHelpers} from 'vuex';
    const searchBarStore = createNamespacedHelpers('searchbar');
    import FilterBoxInput from '@/components/SearchBar/SearchBarMobile/FilterBoxInput'
    import FilterBoxSelect from '@/components/SearchBar/SearchBarMobile/FilterBoxSelect'

      export default {
        name: "AdvanceSearchMetal",
        components: {
            FilterBoxInput,
            FilterBoxSelect,
        },
        computed: {
            ...searchBarStore.mapGetters([
                'getMetalsFilter',
                'isActiveTab'
            ]),
        }
    }
</script>
