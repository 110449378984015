<template>
   <div class="advanced-row" v-show="isAdvanceSearchAllowed(getActiveSearchTab) && isAdvanceSearchActive(getActiveSearchTab)">
        <AdvanceSearchStandard v-show="isActiveTab('standards')"/>
        <AdvanceSearchCollection v-show="isActiveTab('collections')"/>
        <SectionCTA/>
    </div>
</template>
<script>

    import AdvanceSearchCollection from './Collections/AdvanceSearchCollection';
    import SectionCTA from './SectionCTA';
    import AdvanceSearchStandard from "./Standards/AdvanceSearchStandard";
    
    import { createNamespacedHelpers } from 'vuex';
    const { mapGetters } = createNamespacedHelpers('searchbar');
    export default {
        name: "AdvanceSearch",
        components: {
            AdvanceSearchStandard,
            AdvanceSearchCollection,
            SectionCTA
        },
        computed: {
            ...mapGetters([
                'isActiveTab',
                'isAdvanceSearchActive',
                'getActiveSearchTab',
                'isAdvanceSearchAllowed'
            ])
        }
    }
</script>