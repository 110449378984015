export default {
    SET_PWA_STATUS: "SET_PWA_STATUS",
    SET_PWA_NAV_OVERLAY_VARS: "SET_PWA_NAV_OVERLAY_VARS",
    TOGGLE_PWA_NAV_OVERLAY_LEFT: "TOGGLE_PWA_NAV_OVERLAY_LEFT",
    TOGGLE_PWA_NAV_OVERLAY_RIGHT: "TOGGLE_PWA_NAV_OVERLAY_RIGHT",
    SET_USER_PROFILE: "SET_USER_PROFILE",
    SET_DISPLAY_SEARCH: "SET_DISPLAY_SEARCH",
    TOGGLE_SEARCH_BAR: "TOGGLE_SEARCH_BAR",
    SET_INITIALIZE_SEARCH_BAR_FILTERS: "SET_INITIALIZE_SEARCH_BAR_FILTERS",
    SET_ACTIVE_SEARCH_TAB: "SET_ACTIVE_SEARCH_TAB",
    SET_SEARCH_TABS: "SET_SEARCH_TABS",
    SET_SUB_ID: "SET_SUB_ID",
    SET_RECENTLY_VIEWED_DOCS: "SET_RECENTLY_VIEWED_DOCS",
    SET_ADVANCE_SEARCH_DISPLAY: "SET_ADVANCE_SEARCH_DISPLAY",
    ALLOW_ADVANCE_SEARCH: "ALLOW_ADVANCE_SEARCH",
    SET_LOOKUP_OPTIONS: "SET_LOOKUP_OPTIONS",
    
    SET_SEARCH_BAR_FIELD: "SET_SEARCH_BAR_FIELD",

    //standards mutation types
    SET_STANDARDS_FILTER: "SET_STANDARDS_FILTER",

    //collections mutation types
    SET_COLLECTIONS_FILTER: "SET_COLLECTIONS_FILTER",

    //categories mutation types
    SET_CATEGORIES_FILTER: "SET_CATEGORIES_FILTER",

    //latest mutation types
    SET_LATEST_FILTER: "SET_LATEST_FILTER",

    //metals mutation types
    SET_METALS_COMPOSITION_FILTERS: "SET_METALS_COMPOSITION_FILTERS",
    SHOW_METALS_COMPOSITION_FILTERS: "SHOW_METALS_COMPOSITION_FILTERS",
    SET_METALS_COMPOSITION_FILTERS_ERR_MSG: "SET_METALS_COMPOSITION_FILTERS_ERR_MSG",
    SET_METALS_FILTER: "SET_METALS_FILTER",

    //metals supplier types
    SET_METALS_SUPPLIER_FILTER: "SET_METALS_SUPPLIER_FILTER",
    
    //materials mutation types
    SET_MATERIALS_PROPERTY_FILTERS: "SET_MATERIALS_PROPERTY_FILTERS",
    SHOW_MATERIALS_PROPERTY_FILTERS: "SHOW_MATERIALS_PROPERTY_FILTERS",
    SET_MATERIALS_PROPERTY_FILTERS_ERR_MSG: "SET_MATERIALS_PROPERTY_FILTERS_ERR_MSG",
    SET_MATERIALS_FILTER: "SET_MATERIALS_FILTER",

    //materials supplier types
    SET_MATERIALS_SUPPLIER_FILTER: "SET_MATERIALS_SUPPLIER_FILTER"
    
};
