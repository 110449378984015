import Vue from 'vue'
import Vuex from 'vuex'
import modules from './modules';
import plugins from './plugins';

//Load Vuex
Vue.use(Vuex)

//create store
const store = new Vuex.Store({
  strict:  process.env.NODE_ENV !== 'production',
  modules,
  plugins
})

export default store;