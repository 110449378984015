export const metalsInitialState  = {
    allowAdvanceSearch: true,
    displayAdvanceSearch: true,
    mobileAdvanceSearch: true,
    designation: "",
    description: "",
    form: "",
    country: "",
    standard: "",
    keyword: "",
    chemicalComposition: { errMsg: "", showFilters: false, filters: [] },
    mechanicalComposition: { errMsg: "", showFilters: false, filters: [] },
    formOptions: [],
    countryOptions: [],
};

export const metalsSupplierInitialState = {
    allowAdvanceSearch: true,
    displayAdvanceSearch: true,
    mobileAdvanceSearch: true,
    name: "",
    country: "",
    form: [],
    establishmentType: "",
    descriptions: "",
    descriptionsList: [],
    keyword: "",
};

export const materialsInitialState = {
    allowAdvanceSearch: true,
    displayAdvanceSearch: true,
    mobileAdvanceSearch: true,
    mechanicalProperty: { errMsg: "", showFilters: false, filters: [] },
    physicalProperty: { errMsg: "", showFilters: false, filters: [] },
    electricalProperty: { errMsg: "", showFilters: false, filters: [] },
    impactProperty: { errMsg: "", showFilters: false, filters: [] },
    processingProperty: { errMsg: "", showFilters: false, filters: [] },
    thermalProperty: { errMsg: "", showFilters: false, filters: [] },
    otherProperty: { errMsg: "", showFilters: false, filters: [] },
    tradeName: "",
    description: "",
    class: "",
    form: [],
    productType: "",
    additives: [],
    chemicalAnalysis: [],
    formulation: [],
    specialCharacteristics: [],
    chemicalResistance: [],
    processing: [],
    keyword: "",
};

export const materialsSupplierInitialState = {
    allowAdvanceSearch: true,
    displayAdvanceSearch: true,
    mobileAdvanceSearch: false,
    name: "",
    country: ""
};

export default () => ({
    PWAStatus: false,
    pwaNavOverlayVars: [],
    pwaNavOverlayLeftVisibility :false,
    pwaNavOverlayRightVisibility :false,
    nonce: "",
    displaySearch: true,
    activeSearchTab: "standards",
    subId: "",
    isLiteSubscription: false,
    searchTabs: [],
    recentlyViewedDocs: [],
    standards: {
        allowAdvanceSearch: true,
        displayAdvanceSearch: true,
        mobileAdvanceSearch: true,
        documentNumber: "",
        documentTitle: "",
        publisher: "",
        status: [],
        country: "",
        dataset: "",
        crossReference: "",
    },
    collections: {
        allowAdvanceSearch: true,
        displayAdvanceSearch: true,
        mobileAdvanceSearch: true,
        documentNumber: "",
        collections: [],
        companyCollection: "",
        documentType: "",
        status: [],
        tags: [],
        notes: "",
    },
    categories: {
        allowAdvanceSearch: true,
        displayAdvanceSearch: true,
        mobileAdvanceSearch: false,
        isDescriptorFilter: true,
        icsCode: "",
        descriptorField: "",
        descriptorGroup: "",
        descriptorSubGroup: "",
        publisher: "",
        country: "",
        status: [],
    },
    latest: {
        allowAdvanceSearch: true,
        displayAdvanceSearch: true,
        mobileAdvanceSearch: false,
        what: "",
        publisher: "",
        when: "",
        dataset: "",
    },
    metals: JSON.parse(JSON.stringify(metalsInitialState)),
    metalsSupplier: JSON.parse(JSON.stringify(metalsSupplierInitialState)),
    materials: JSON.parse(JSON.stringify(materialsInitialState)),
    materialsSupplier: JSON.parse(JSON.stringify(materialsSupplierInitialState)),
    lookUpOptions: {
        "standards.status": [],
        "standards.dataset": [],
        "collections.documentType": [],
        "latest.what": [],
        "latest.publisher": [],
        "latest.when": [],
        "latest.dataset": [],
        "categories.descriptorField": [],
        "categories.descriptorGroup": [],
        "categories.descriptorSubGroup": []
    },
});