export default () => ({
    loading: false,
    homeAccordions: [],
    announcements: {
        "title": "global_notes",
        "icon": "si si-volume",
        "content": []
    },
    orders: {
        "title": "home_orders",
        "icon": "si si-receipt-purple",
        "content": [{
            "id": 1,
            "note": "The administrator has approved your request for Standard XYZ.",
            "linkURL": "/orders",
            "linkName": "Click here to view"
        }]
    },
    unlock: {
        "title": "home_unlock",
        "icon": "si si-document-reverse",
        "content": [{
            "id": 1,
            "note": "A new document XYZ has been added to your subscription.",
            "linkURL": "/unlock",
            "linkName": "Click here to view"
        }]
    },
    myStandards: {
        "title": "home_myStandards",
        "icon": "si si-folder",
        "headers": ["frm_collection_name", "frm_description", "frm_no_of_standards"],
        "footer": {
            "text": "",
            "URL": "",
        },
        "content": [],
    },
    myC2VStandards: {
        "title": "My C2V Standards",
        "icon": "si si-folder",
        "headers": ["frm_collection_name", "frm_description", "frm_no_of_standards"],
        "footer": {
            "text": "View all my C2V standards",
            "URL": "/management/assets",
        },
        "content": [],
    },

    myAlerts: {
        "title": "home_myAlerts",
        "icon": "si si-bell-s",
        "footer": {
            "text": "home_viewAllAlerts",
            "URL": "/management/alerts/index",
        },
        "headers": ["frm_name", "idx_title", "frm_when_last", "frm_reason"],
        "content": []
    },

    myWatchedDocuments: {
        "title": "home_myWatchedDocuments",
        "icon": "si si-unwatch",
        "headers": ["frm_name","idx_title"],
        "footer":{
            "text":"",
            "URL": "",
        },
        "content": []
    },
    myActivities: {
        "title": "home_myActivities",
        "icon": "si si-graphStats",
        "content": [{
           "section": "My saved searches",
            "title": "My saved searches",
            "icon": "si si-star-o",
            "subContent": [{
                "headers": ["frm_name","idx_title"],
                "content": [
                    {
                        "name": {"linkName": "I.S EN 61921", "linkUrl":"/saved"},
                        "title":"POWER CAPACITORS - LOW VOLTAGE POWER FACTOR CORRECTION BANKS ",
                    },
                    {
                        "name": {"linkName": "POWER CAPACITORS - LOW VOLTAGE POWER FACTOR CORRECTION BANKS", "linkUrl":"/standards"},
                        "title":"SOIL QUALITY - DETERMINATION OF SOIL MICROBIAL DIVERSITY - PART 2:... ",
                    },
                    {
                        "name": {"linkName": "POWER CAPACITORS - LOW VOLTAGE POWER FACTOR CORRECTION BANKS", "linkUrl":"/standards"},
                        "title":"SOIL QUALITY - DETERMINATION OF SOIL MICROBIAL DIVERSITY - PART 2:... ",
                    }
                ]
            },
            ]
        },
        {
            "section": "Recently viewed Standards",
            "title": "Recently viewed Standards",
            "icon": "si si-unpublished",
            "subContent": [{
                "id":1,
                "headers": ["frm_name","idx_title"],
                "content": [
                    {
                        "name": {"linkName": "I.S EN 61921", "linkUrl":"/saved"},
                        "title":"POWER CAPACITORS - LOW VOLTAGE POWER FACTOR CORRECTION BANKS ",
                    },
                    {
                        "name": {"linkName": "POWER CAPACITORS - LOW VOLTAGE POWER FACTOR CORRECTION BANKS", "linkUrl":"/standards"},
                        "title":"SOIL QUALITY - DETERMINATION OF SOIL MICROBIAL DIVERSITY - PART 2:... ",
                    }
                ]
            },
            ]
        },
            {
                "section": "Recently viewed Standards",
                "title": "Recently viewed Standards",
                "icon": "si si-unpublished",
                "subContent": [{
                    "id":1,
                    "headers": ["frm_name","idx_title"],
                    "content": [
                        {
                            "name": {"linkName": "I.S EN 61921", "linkUrl":"/saved"},
                            "title":"POWER CAPACITORS - LOW VOLTAGE POWER FACTOR CORRECTION BANKS ",
                        },
                        {
                            "name": {"linkName": "POWER CAPACITORS - LOW VOLTAGE POWER FACTOR CORRECTION BANKS", "linkUrl":"/standards"},
                            "title":"SOIL QUALITY - DETERMINATION OF SOIL MICROBIAL DIVERSITY - PART 2:... ",
                        }
                    ]
                },
                ]
            }]
    },
});

