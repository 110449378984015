<template>
    <div>
        <div class="icsSection d-sm-none d-md-block just-content-center ics-code-link-dsk">
            <span class="icsSection ics-code-option" style="display: inline-block;" v-if="getCategoriesFilter('isDescriptorFilter')" @click="$event=>setCategoriesFilter({field: 'isDescriptorFilter', payload: false})">{{ $t('enter_ics_code') }}</span>
            <span class="icsSection ics-code-option" style="display: inline-block;" v-if="!getCategoriesFilter('isDescriptorFilter')"  @click="$event=>setCategoriesFilter({field: 'isDescriptorFilter', payload: true})">Select descriptors</span>
            <input v-if="getCategoriesFilter('isDescriptorFilter')" type="hidden" name="ics_code_descriptor_filter" value="1"/>
            <input v-if="!getCategoriesFilter('isDescriptorFilter')" type="hidden" name="ics_code_descriptor_filter" value="0"/>
        </div>
        <div v-show="!getCategoriesFilter('isDescriptorFilter')">
            <SaiInput type="text" class="search-box standard-field standardSection assetsSection icsSection form-control shadow-none form-control-text standards-inputs row1-font ui-autocomplete-input"
            :placeholder="$t('frm_enter_ics_code')" name="query" id="query"
            :value="getCategoriesFilter('icsCode')"
            @input="$event => setCategoriesFilter({field: 'icsCode', payload: $event})"
            style="width: 100%;"
            autocomplete="off"  :disabled="!isActiveTab('categories')"/>
        </div>
        <div v-show="getCategoriesFilter('isDescriptorFilter')" class="isc-code-levels">
            
            <FilterMultiSelect :placeholder="$t('frm_ics_code_level1')" name="ics_level1" field="categories.descriptorField" :searchable="false" :hasClear="false" class="noHeader"/>
            <input type="hidden" name="ics_level1" :value="getCategoriesFilter('descriptorField').id" :disabled="!isActiveTab('categories')"/>
            <SaiMultiSelect
                :disabled="disableDropDown('categories.descriptorGroup')"
                :placeholder="$t('frm_ics_code_level2')"
                class='purple-theme noHeader'
                :value="getCategoriesFilter('descriptorGroup')"
                @change="$event => setCategoriesFilter({  field: 'descriptorGroup', payload: $event })"
                :options="lookUpForGroup()"
                labelBy="text"
                trackBy="id"
                name="ics_level2"
                :searchable="false"
                :hasClear="false"/>
            <input type="hidden" name="ics_level2" :value="getCategoriesFilter('descriptorGroup').id"  :disabled="!isActiveTab('categories')"/>
            <SaiMultiSelect
                :disabled="disableDropDown('categories.descriptorSubGroup')"
                :placeholder="$t('frm_ics_code_level3')" 
                class='purple-theme ics_level3_select'
                :value ="getCategoriesFilter('descriptorSubGroup')"
                @change ="$event => setCategoriesFilter({  field: 'descriptorSubGroup', payload: $event })"
                :options="lookUpForSubGroup()"
                labelBy ="text"
                trackBy="id"
                name="ics_level3"
                :hasClear="false"
                :searchable="false"/>
            <input type="hidden" name="ics_level3" :value="getCategoriesFilter('descriptorSubGroup').id"  :disabled="!isActiveTab('categories')"/>
        </div>
    </div>
</template>
<script>
    import FilterMultiSelect from '@/components/SearchBar/SearchBarDesktop/FilterMultiSelect';
    import SaiMultiSelect from '@/components/common/SaiMultiSelect';
    import SaiInput from '@/components/common/SaiInput';
    import { createNamespacedHelpers } from 'vuex';
    const { mapGetters, mapActions } = createNamespacedHelpers('searchbar');
    export default {
        name: "QuickSearchCategories",
        components: {
            SaiInput,
            FilterMultiSelect,
            SaiMultiSelect
        },
        computed: {
            ...mapGetters([
                'getCategoriesFilter',
                'getLookUpOptions',
                'isActiveTab'
            ])
        },
        methods: {
            ...mapActions([
                'setCategoriesFilter'
            ]),
            disableDropDown(field)  {
                const options = this.getLookUpOptions(field);
                return !options.length;
            },
            lookUpForGroup() {
                const loadedOptions = this.getLookUpOptions('categories.descriptorGroup')
                return [
                    { id: '', text: 'Group' },
                    ...loadedOptions
                ];
            },
            lookUpForSubGroup() {
                const loadedOptions = this.getLookUpOptions('categories.descriptorSubGroup')
                return [
                    { id: '', text: 'Sub Group' },
                    ...loadedOptions
                ];
            }
        }
    }
</script>
<style lang="scss">
    .isc-code-levels {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .multiselect {
            width: 33%;
            margin-bottom: .6em;
        }
        .ics_level3_select {
            .multiselect__option {
                white-space: normal;
            }
        }
    }
</style>
