<template>
    <div class="form-group col-sm-4 col-md-8 col-lg-8">
        <span id="descriptionSelector" v-if="descriptionList.length" @click="showModal=true"> {{descriptionList.length}} Description(s) </span>
        <input type="hidden" name="metals_supplier_description" :value="JSON.stringify(descriptionList)">
        <SaiAutoSuggest
            labelBy="label"
            trackBy="value"
            :className="'metals-supplier-description search-box title-field metalsSupplierSection form-control shadow-none form-control-text metals-supplier-inputs row1-font ui-autocomplete-input'+(descriptionList.length ? ' hasIndent ' : '')"
            api="/management/search/field/metals_supplier_description_autocomplete"
            :apiExtraParams="{type: 'metals_supplier_description'}"
            apiHeaderContentType="form-urlencoded"
            :placeholder="$t('frm_description')"
            name="metals_supplier_description_autocomplete"
            id="metals_supplier_description_autocomplete"
            :value="getMetalsSupplierFilter('descriptions')"
            @input="$event => setMetalsSupplierFilter({field: 'descriptions', payload: $event})"
            @addSuggestValue="addToDescriptionsList" :disabled="isAdvanceFilterDisabled('metalsSupplier')"/>

        <SaiModal :display="showModal" @closeModal="showModal=false" className="suppliers-description-form">
            <span slot="title">suppliers descriptions</span>
            <div slot="body" v-if="descriptionList.length" class="descriptions-list">
                <span v-for="description in descriptionList" :key="description" class="description">
                    <span class="description-value">{{description}}</span>
                    <span class="description-remove" @click="removeDescription(description)"><i class="si si-cross"></i></span>
                </span>
            </div>
        </SaiModal>

    </div>
</template>

<script>
import SaiAutoSuggest from '@/components/common/SaiAutoSuggest';
import SaiModal from "@/components/common/SaiModal";
import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapActions } = createNamespacedHelpers('searchbar');
export default {
    name: "Description",
    data() {
      return {
          showModal: false
      }
    },
    components: {
        SaiAutoSuggest,
        SaiModal
    },
    computed: {
        ...mapGetters([
            'getMetalsSupplierFilter',
            'isAdvanceFilterDisabled'
        ]),
        descriptionList() {
            const rawList = this.getMetalsSupplierFilter('descriptionsList') ?? [];
            let filteredList = [];
            if (rawList.length) {
                rawList.forEach(list=> {
                    filteredList.push(list);
                })
            }
            return filteredList;
        }
    },
    methods: {
        ...mapActions([
            'setMetalsSupplierFilter'
        ]),
        addToDescriptionsList($event) {
            let descriptionsList = this.descriptionList ?? [];
            if (descriptionsList.indexOf($event) < 0) {
                descriptionsList.push($event);
                this.setMetalsSupplierFilter({field: 'descriptionsList', payload: descriptionsList});
            }
            this.setMetalsSupplierFilter({field: 'descriptions', payload: ''})
        },
        removeDescription(description) {
            const updatedList = this.descriptionList.filter(desc => {
                return desc !== description;
            })
            this.setMetalsSupplierFilter({field: 'descriptionsList', payload: updatedList});
            if (this.descriptionList.length ===0) {
                this.showModal = false
            }
        }
    }
}
</script>

<style lang="scss">
    .metals-supplier-description.hasIndent {
        text-indent: 110px;
    }
</style>