<template>
    <div>
        <SaiMultiSelect labelBy="val" trackBy="key"
                        :searchable="false"
                        @change="triggerBulkAction"
                        class="bulkAction"
                        :resetAfter="true"
                        :placeholder="$t('frm_actions')"
                        :options="actions"/>
    </div>
</template>

<script>
    import SaiMultiSelect from '@/components/common/SaiMultiSelect';
    import {createNamespacedHelpers} from 'vuex';
    const {mapGetters, mapActions} = createNamespacedHelpers('searchresults');
    export default {
        name: "SearchResultsBulkActions",
        data() {
            return {
                actionName: ''
            }
        },
        props: {
            actions: {type: Array},
        },
        components: {
            SaiMultiSelect
        },
        computed: {
            ...mapGetters([
                'getBulkSelectedRecords',
                'getSearchPageUrl',
                'getSearchResultsField'
            ]),
        },
        methods: {
            ...mapActions([
                'performDocAction'
            ]),
            triggerBulkAction: function ($event) {
                if(!$event) {
                    return;
                }
                
                if(!this.getBulkSelectedRecords.length){
                    alert('No document selected');
                    return;
                }

                let message = '';
                let action = '';
                switch ($event.key) {
                    case 'add_selected':
                        message = 'Are you going to add the document(s) to collection?';
                        action  = 'addItem';
                        break;
                    case 'rem_selected':
                        message = 'Are you going to remove the document(s) to collection?';
                        action  = 'remItem';
                        break;
                    case 'watch_selected':
                        message = 'Are you going to watch the document(s)?';
                        action  = 'watchItem';
                        break;
                    case 'unwatch_selected':
                        message = 'Are you going to unwatch the document(s)?';
                        action  = 'unWatchItem';
                        break;
                }
                if (confirm(message)) {
                    const collectionId = this.getSearchResultsField('activeCollectionId');
                    const docIds = this.getBulkSelectedRecords;
                    this.performDocAction({action, collectionId, docIds, isBulkAction: 1});
                }
            }
        }
    }
</script>

