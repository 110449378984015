<template>
    <div class="collections-inputs">
        <SaiInput type="text" class="search-box standard-field standardSection assetsSection icsSection form-control shadow-none form-control-text standards-inputs row1-font ui-autocomplete-input"
            :placeholder="$t('frm_collection_document_number')" name="name" id="name"
            :value="getCollectionsFilter('documentNumber')"
            @input="$event => setCollectionsFilter({field: 'documentNumber', payload: $event})"
            style="width: 100%;"
            @keyup.enter="performMainSearch"
            autocomplete="off" :disabled="!isActiveTab('collections')"/>
        <ResetInput v-if="getCollectionsFilter('documentNumber')" @clickHandler="setCollectionsFilter({field: 'documentNumber', payload: ''})" />
    </div>
</template>
<script>
    import ResetInput from "@/components/common/ResetInput";
    import SaiInput from '@/components/common/SaiInput';
    import { createNamespacedHelpers } from 'vuex';
    const { mapGetters, mapActions } = createNamespacedHelpers('searchbar');
    const { mapActions: searchResultActions } = createNamespacedHelpers('searchresults');
    export default {
        name: "QuickSearchCollection",
        components: {
            SaiInput,
            ResetInput
        },
        computed: {
            ...mapGetters([
                'getCollectionsFilter',
                'isActiveTab'
            ]),
        },
        methods: {
            ...mapActions([
                'setCollectionsFilter'
            ]),
            ...searchResultActions([
                'performMainSearch'
            ])
        }
    }
</script>

<style lang="scss">
@import "./collections";
</style>