<template>
    <div class="quickViews-container">
        <div v-for="accordion in sortedHomeAccordions" :key="accordion.id">
            <template v-if="accordion.fixed">
                <Announcements :accordion="accordion" v-show="getAccordionContent(accordion.id)['content'].length" />
            </template>

            <template v-else>
                <draggable handle=".si-drag"  :component-data="getComponentData()" v-bind="dragOptions"  group="accordions" @start="drag=true" @end="drag=false">
                    <DraggableAccordions  :order="accordion.order" :accordion="accordion" v-show="getAccordionContent(accordion.id)['content'].length"/>
                </draggable>
            </template>
        </div>
    </div>
</template>

<script>
import draggable from 'vuedraggable'
import {createNamespacedHelpers} from "vuex";
const {mapGetters, mapActions} = createNamespacedHelpers('quickviews');
import Announcements from "@/components/QuickViews/Announcements";
import DraggableAccordions from "@/components/QuickViews/DraggableAccordions";

export default {
    name: "QuickViews",
    components: {
        Announcements,
        DraggableAccordions,
        draggable,
    },
    created() {
        this.fetchAccordions();
    },
    computed: {
        dragOptions() {
            return {
                animation: 200,
                group: "accordions",
                disabled: false,
                ghostClass: "ghost"
            };
        },
        ...mapGetters([
                'getHomeAccordions',
                'getAccordionContent',
            ]
        ),
        sortedHomeAccordions() {
            return [...this.getHomeAccordions].sort((a,b) =>  a.order - b.order)
        }
    },
    methods: {
        ...mapActions([
            'setAccordionOptions',
            'callUpdateSettingsApi',
            'fetchAccordions'
        ]),
         async handleChange() {
            //grab current list of accordions in ui after dragging
            //push accordions name and their index in array
            const elements = document.getElementsByClassName('sai-accordion-primary');
            let currentList = [];
            elements.forEach((el,i) => {
                let orderId = el.getAttribute('order');
                const accordionName = this.findAccordionByOrderId(orderId, i);
                currentList.push([accordionName, i]);
            });

            //looping in array and set accordions based on theirs current index
             await currentList.forEach(list => {
                this.setAccordionOptions( {accordion:list[0], payload:{"order": list[1]}, bulkChange: true } )
            });
            this.callUpdateSettingsApi();
        },
        findAccordionByOrderId(orderId) {
            const accordionElement =  this.sortedHomeAccordions.filter(accordion => {
                return accordion.order === Number(orderId)
            });
            return accordionElement[0].id;
        },
        getComponentData() {
            return {
                on: {
                    change: this.handleChange
                },
            };
        }
    },
}
</script>
