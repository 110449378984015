<template>
    <SaiModal :display="display" @closeModal="closeFilterPoup">
        <span slot="title">{{$t("frm_" + type.replace("Composition", "") + "_composition")}}</span>
        <div slot="body">
            <ValidationObserver slim ref="validationObserver">
            <div class="modalBasic composition-form ui-dialog-content ui-widget-content" style="width: auto; min-height: 48.833px; max-height: none; height: auto;">
                <div :class="'composition-container ' + type + '-composition-container'">
                    <div class="composition-box-container">
                        <div v-for="(composition, index) in compositions" :key="index" :class="'element-box ' + type + '-element-box'">
                                <div :class="'composition element sai-field ' + type + 'ElementSearch'">
                                    <ValidationProvider :bails="false" :vid="'elementValue' + index" slim :rules="{ elementRequired: true, uniqueElements: { elements: compositions, ownIndex: index } }">
                                        <FilterDropDown
                                            :value ="{ id: composition.elementValue, text: composition.element }"
                                            @change = "selectElement($event, index)"
                                            :name="'metals_' + type + '_' + index" 
                                            labelBy="text"
                                            trackBy="id"
                                            :field="'metals.' + type"
                                            :placeholder="placeholder" />
                                    </ValidationProvider>
                                </div>
                                
                                <div class="min-value sai-field">
                                    <ValidationProvider :bails="false" :vid="'minValue' + index" slim :rules="{ between: { min: 0, max: max }, lessThanMaxValue: { maxValue: '@maxValue' + index} }">
                                        <SaiInput type="number" :placeholder="$t('min')" class="sai-input minValue" name="minValue" v-model="composition.minValue"/>
                                    </ValidationProvider>
                                </div>
                                
                                
                                <div class="max-value sai-field">
                                    <ValidationProvider :bails="false" :vid="'maxValue' + index" slim :rules="'between:0,'+max">
                                        <SaiInput type="number" :placeholder="$t('max')" class="sai-input maxValue" name="maxValue" v-model="composition.maxValue"/>
                                    </ValidationProvider>
                                </div>
                                
                                
                                <div class="single-value sai-field">
                                    <ValidationProvider :bails="false" :vid="'singleValue' + index" slim :rules="'between:0,'+max">
                                        <SaiInput type="number" :placeholder="$t('single_value')" class="sai-input singleValue" name="singleValue" v-model="composition.singleValue"/>
                                    </ValidationProvider>
                                </div>            
                                
                                
                                <div class="deviation sai-field">
                                    <ValidationProvider :bails="false" :vid="'deviation' + index" slim :rules="'between:0,'+max">
                                        <SaiInput type="number" :placeholder="$t('per_deviation')" class="sai-input deviation" name="deviation" v-model="composition.deviation"/>
                                    </ValidationProvider>
                                </div>
                                
                                <div class="delete-element"><i class="si si-bin" v-if="index > 0" @click="deleteFilter($event, index)"></i></div>
                        </div>
                    </div>

                    <div class="add-more-element">
                        <span @click="addFilter"> {{ $t("add_more") }} </span>
                    </div>
                    <div class="btn-groups">
                        <span :class="'sai-btn-secondary save save' + type + 'Composition'" @click="saveFilters">{{ $t("save") }}</span>
                        <span :class="'sai-btn-action discard' + type" @click="discardFilters">{{ $t("discard") }}</span>
                    </div>
                    <div class="error-message">
                        <span>{{ getMetalsCompositionFiltersErrMsg(type) }}</span>
                    </div>
                </div>

            </div>
            </ValidationObserver>
        </div>
    </SaiModal>
</template>
<script>
    import SaiModal from '@/components/common/SaiModal';
    import SaiInput from '@/components/common/SaiInput';
    import { createNamespacedHelpers } from 'vuex';
    const { mapGetters, mapActions } = createNamespacedHelpers('searchbar');
    const newChemComp = { element:'', elementValue:'', minValue:'', maxValue:'', singleValue:'', deviation:'', isLoading: false };
    import {ValidationProvider, ValidationObserver } from 'vee-validate'
    import '../../validation';
    import { apiGetMetalsFieldOptions } from '@/components/SearchBar/api';
     import FilterDropDown from '../../FilterDropDown';
    export default {
        name: 'FilterPopupComposition',
        props: {
            max: { type: Number,  default: 100, required: true },
            type: { type: String,  default: '', required: true },
            placeholder: { type: String, required: true },
            display: { type: Boolean, default: false, required: true }
        },
        components: {
            SaiModal,
            SaiInput,
            ValidationProvider,
            ValidationObserver,
            FilterDropDown
        },
        data: function () {
            return {
                compositions: [],
                errorMsgs: '',
                elementOptions: [],
                initilizeElementOptions: true
            }
        },
        mounted() {
           this.initCompositionFiltersFromState();
        },
        computed: {
            ...mapGetters([
                'getMetalsCompositionFilters',
                'getMetalsCompositionFiltersErrMsg'
            ])
        },
        methods: {
            ...mapActions([
                'setMetalsCompositionFilters',
                'showMetalsCompositionFilters',
                'setMetalsCompositionFiltersErrMsg'
            ]),
            closeFilterPoup() {
                this.initCompositionFiltersFromState();
                this.showMetalsCompositionFilters({type: this.type, show: false});
            },
            addFilter() {
                this.compositions.push({ ...newChemComp });
            },
            discardFilters() {
                this.compositions = [{ ...newChemComp }];
                this.setMetalsCompositionFilters({type: this.type, compositions: []});
                this.showMetalsCompositionFilters({type: this.type, show: false});
            },
            async saveFilters() {
                const validationInfo = await this.$refs.validationObserver.validateWithInfo();
                if(!validationInfo.isValid) {
                    for (const arrFields of Object.entries(validationInfo.fields)) {
                        const fieldInfo = arrFields[1];
                        if(!fieldInfo.isValid) {
                            if (fieldInfo.failedRules.between) {
                                this.setMetalsCompositionFiltersErrMsg({type: this.type, errMsg: this.$t("err_num_between", [0, this.max])});
                                break;   
                            }
                            if (fieldInfo.failedRules.elementRequired) {
                                this.setMetalsCompositionFiltersErrMsg({type: this.type, errMsg: this.$t("err_ele_required")});
                                break;
                            }
                            if (fieldInfo.failedRules.lessThanMaxValue) {
                                this.setMetalsCompositionFiltersErrMsg({type: this.type, errMsg: this.$t("err_min_max")});
                                break;
                            }
                            if (fieldInfo.failedRules.uniqueElements) {
                                this.setMetalsCompositionFiltersErrMsg({type: this.type, errMsg: this.$t("err_unique_ele")});
                                break;
                            }
                        }
                    }
                } else {
                    let compositions = JSON.stringify(this.compositions);
                    compositions = JSON.parse(compositions);
                    this.setMetalsCompositionFilters({type: this.type, compositions});
                    this.showMetalsCompositionFilters({type: this.type, show: false});
                }
            },
            deleteFilter(event, index) {
                this.compositions.splice(index, 1);
            },
            selectElement(item, index) {
                this.compositions[index].element = item.text;
                this.compositions[index].elementValue = item.id;
            },
            async loadElements(event, index) {
                if(this.initilizeElementOptions) {
                    this.compositions[index].isLoading = true;
                    this.elementOptions = await apiGetMetalsFieldOptions(this.type);
                    this.compositions[index].isLoading = false;
                    this.initilizeElementOptions = false;
                }
            },
            initCompositionFiltersFromState() {
                const metalsCompositions = this.getMetalsCompositionFilters(this.type);
                if(metalsCompositions.length) {
                    //need to create newly refrenced array, as we shouldnot modify vuex store data without vuex mutations
                    let preselectdCompositions = JSON.stringify(metalsCompositions);
                    this.compositions = JSON.parse(preselectdCompositions);
                } else {
                    this.compositions = [{ ...newChemComp }];
                }
            }
        },
        watch: {
            display: function(newValue) {
                //when popup is displayed load the initial compositions from state
                if(newValue) {
                    this.initCompositionFiltersFromState();
                }
            }
        }
    }
</script>