<template>
    <div>
        <div class="sai-options">
            <div class="options" id="radio-select">
                <template v-for="(tab, index) in getFilteredSearchTabs">
                    <input :key="'input-' + tab.id" type="radio" name="searchCriteria" :id="index" :value="tab.value" :checked="isActiveTab(tab.id)"  :class="[ { active: isActiveTab(tab.id) }, tab.id]">
                    <label :key="'label-' + tab.id" :for="index" :class="[ { active: isActiveTab(tab.id) }, tab.id]"  @click="setActiveSearchTab(tab.id)">{{ tab.name }}</label>
                </template>
            </div>
        </div>
    </div>
</template>
<script>
    import { createNamespacedHelpers } from 'vuex';
    const { mapGetters, mapActions } = createNamespacedHelpers('searchbar')
    export default {
        name: "SearchTabs",
        computed: {
            ...mapGetters([
                'getActiveSearchTab',
                'getSearchTabs',
                'isActiveTab',
                'isActivePWA'
            ]),
            getFilteredSearchTabs() {
                if (this.isActivePWA) {
                    return this.updatedSearchTabs();
                } else {
                    return this.getSearchTabs;
                }
            }
        },
        methods: {
            ...mapActions([
                'setActiveSearchTab'
            ]),
            updatedSearchTabs() {
                return this.getSearchTabs.filter(tab => tab.name === "Standards" || tab.name === "Collections" )

            }
        }
    }
</script>
