<template>
    <div class="quickSearchBox">
        <SaiInput type="text" class="mobile-search-input"
                  name="material_supplier_name"
                  :value="getMaterialsSupplierFilter('name')"
                  @input="$event => setMaterialsSupplierFilter({field: 'name', payload: $event})"
                  :placeholder="$t('frm_supplier')" :disabled="!isActiveTab('materialsSupplier')"/>

        <FilterMultiSelect :searchable="true"
                           :hasClear="true"
                           :placeholder="$t('fld_country')"
                           name="material_supplier_country"
                           field="materialsSupplier.country"/>
        <input type="hidden" name="material_supplier_country" :value="getMaterialsSupplierFilter('country').id" :disabled="!isActiveTab('materialsSupplier')">

    </div>
</template>

<script>
    import FilterMultiSelect from '@/components/SearchBar/SearchBarDesktop/FilterMultiSelect';
    import SaiInput from '@/components/common/SaiInput';
    import { createNamespacedHelpers } from 'vuex';
    const { mapGetters, mapActions } = createNamespacedHelpers('searchbar');
    export default {
        name: "QuickSearchMaterialSupplier",
        components: {
            SaiInput,
            FilterMultiSelect
        },
        computed: {
            ...mapGetters([
                'getMaterialsSupplierFilter',
                'isActiveTab'
            ]),
        },
        methods: {
            ...mapActions([
                'setMaterialsSupplierFilter'
            ])
        }
    }
</script>

<style scoped>

</style>