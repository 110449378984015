<template>
    <div class="searchBoxList">

        <FilterBoxSelect :title="$t('fld_publisher')" field="standards.publisher" :searchable="true"/>
        <input type="hidden" name="publisher" :value="getStandardsFilter('publisher').id" :disabled="!isActiveTab('standards')">

        <FilterBoxOptions :title="$t('frm_current_label')" field="standards.status" />
        <input name="include[]" type="hidden" v-for="element in getStandardsFilter('status')"
               :key="element.id" :value="element.id" :disabled="!isActiveTab('standards')">

        <FilterBoxSelect :title="$t('fld_country')" field="standards.country" :searchable="true"/>
        <input type="hidden" name="country" :value="getStandardsFilter('country').id" :disabled="!isActiveTab('standards')">

        <FilterBoxSelect :title="$t('fld_dataset')" field="standards.dataset"/>
        <input type="hidden" name="dataset" :value="getStandardsFilter('dataset').id" :disabled="!isActiveTab('standards')">

        <FilterBoxInput :title="$t('idx_xref')" field="standards.crossReference"
                        :placeholder="$t('frm_search_within')" inputName="query_5" :disabled="!isActiveTab('standards')"/>

    </div>

</template>

<script>
    import {createNamespacedHelpers} from 'vuex';
    const searchBarStore = createNamespacedHelpers('searchbar');
    import FilterBoxInput from '@/components/SearchBar/SearchBarMobile/FilterBoxInput'
    import FilterBoxSelect from '@/components/SearchBar/SearchBarMobile/FilterBoxSelect'
    import FilterBoxOptions from '@/components/SearchBar/SearchBarMobile/FilterBoxOptions'

    export default {
        name: "AdvanceSearchStandard",
        components: {
            FilterBoxOptions,
            FilterBoxInput,
            FilterBoxSelect
        },
        computed: {
            ...searchBarStore.mapGetters([
                'getStandardsFilter',
                'isActiveTab'
            ]),
        }
    }
</script>
