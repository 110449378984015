<template>
    <div class="quickSearchBox">
        <SaiInput type="text" class="mobile-search-input"
                  name="metals_supplier_name"
                  :value="getMetalsSupplierFilter('name')"
                  @input="$event => setMetalsSupplierFilter({field: 'name', payload: $event})"
                  :placeholder="$t('frm_name')" :disabled="!isActiveTab('metalsSupplier')"/>
    </div>
</template>

<script>
    import SaiInput from '@/components/common/SaiInput';
    import { createNamespacedHelpers } from 'vuex';
    const { mapGetters, mapActions } = createNamespacedHelpers('searchbar');
    export default {
        name: "QuickSearchMetalSupplier",
        components: {
            SaiInput
        },
        computed: {
            ...mapGetters([
                'getMetalsSupplierFilter',
                'isActiveTab'
            ]),
        },
        methods: {
            ...mapActions([
                'setMetalsSupplierFilter'
            ])
        }
    }
</script>

<style scoped>

</style>