<template>
    <div class="metals_quick_search">
        <SaiAutoSuggest 
            labelBy="label" 
            trackBy="value"
            className="search-box
                title-field
                metalsSection
                form-control
                shadow-none
                form-control-text
                metals-inputs
                row1-font
                ui-autocomplete-input"
            api="/management/search/field/metals_designation"
            :apiExtraParams="{type: 'metals_designation'}"
            apiHeaderContentType="form-urlencoded"
            :placeholder="$t('frm_designation')"
            name="metals_designation" id="metals_designation"
            :value="getMetalsFilter('designation')"
            @input="$event => setMetalsFilter({field: 'designation', payload: $event})"
            @addSuggestValue="$event => setMetalsFilter({field: 'designation', payload: $event})" :disabled="!isActiveTab('metals')"/>
        <SaiAutoSuggest 
            labelBy="label" 
            trackBy="value"
            className="search-box
                title-field
                metalsSection
                form-control
                shadow-none
                form-control-text
                metals-inputs
                row1-font
                ui-autocomplete-input"
            api="/management/search/field/metals_description"
            :apiExtraParams="{type: 'metals_description'}"
            apiHeaderContentType="form-urlencoded"
            :placeholder="$t('frm_description')"
            name="metals_description" id="metals_description"
            :value="getMetalsFilter('description')"
            @input="$event => setMetalsFilter({field: 'description', payload: $event})"
            @addSuggestValue="$event => setMetalsFilter({field: 'description', payload: $event})" :disabled="!isActiveTab('metals')"/>
    </div>
</template>
<script>
    import SaiAutoSuggest from '@/components/common/SaiAutoSuggest';
    import { createNamespacedHelpers } from 'vuex';
    const { mapGetters, mapActions } = createNamespacedHelpers('searchbar');

    export default {
        name: "QuickSearchMetal",
        components: {
            SaiAutoSuggest
        },
        computed: {
            ...mapGetters([
                'getMetalsFilter',
                'isActiveTab'
            ]),
        },
        methods: {
            ...mapActions([
                'setMetalsFilter'
            ])
        }
    }
</script>

<style lang="scss">
.metals_quick_search {
    display: flex;
    .sai-auto-suggest {
        input {
            width: 100% !important;
            margin-right: 0% !important;
        }
        &:nth-child(1) {
            width: 33%;
        }
        &:nth-child(2) {
            width: 66.3%;
            margin-left: 1%;
        }
    }
}
</style>