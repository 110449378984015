<template>
  <div class="sai-pagination">
    <paginate
        v-model="page"
        :no-li-surround="true"
        :page-count="pageNumbers.length"
        :page-range="5"
        :margin-pages="2"
        container-class="inner-wrapper"
        break-view-link-class="test"
        page-link-class="item"
        prev-text="<a class='icon item'><i class='si si-chevron-left' title='Previous page'></i></a>"
        next-text="<a class='icon item' ><i class='si si-chevron-right' title='Next page'></i></a>"
        :click-handler="clickCallback"
    >
            <span slot="breakViewContent">
                <svg width="16" height="4" viewBox="0 0 16 4">
                  <circle fill="#999999" cx="2" cy="2" r="2"/>
                  <circle fill="#999999" cx="8" cy="2" r="2"/>
                  <circle fill="#999999" cx="14" cy="2" r="2"/>
                </svg>
              </span>
    </paginate>
  </div>
</template>

<script>
import Paginate from 'vuejs-paginate'

export default {
  components: {'paginate': Paginate},
  props: {
    totalItems: {
      type: Number,
      required: false,
      default: 0,
    },
    currentPage: {
      type: Number,
      required: true,
      default: 1,
    },
    pageSize: {
      type: Number,
      required: true,
      default: 25
    }
  },
  data() {
    return {
      page: this.currentPage
    }
  },
  methods: {
    clickCallback(pageNo) {
      if (this.currentPage !== pageNo) {
        this.$emit('gotoPage', event, pageNo);
      }
    },
    range(start, end) {
      return Array.from(
          Array(Math.abs(end - start) + 1),
          (_, i) => start + i
      );
    }
  },
  computed: {
    pageNumbers() {
      const totalPageNos = Math.ceil(this.totalItems / this.pageSize);
      return this.range(1, totalPageNos);
    }
  },
  watch: {
    currentPage: function (currentPage) {
      this.page = currentPage;
    },
  },
}
</script>
