import Mutation from './mutationTypes';

export default {
    [Mutation.SET_TOUR_GUIDES]: (state, tourGuides) => {
        state.tourGuides = tourGuides
    },
    [Mutation.SET_LOADING_STATUS]: (state, status) => {
        state.loading = status
    },
    [Mutation.UPDATE_TOUR_GUIDE_SECTION]: (state, payload) => {
        state.tourGuides = payload;
    },
}