<template>
     <span class="input-field-reset"  @click="$emit('clickHandler')">
            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.00208 16L15.9987 1" stroke="#5D4266" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M16 16L1 1" stroke="#5D4266" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </span>
</template>

<script>
export default {
    name: "ResetInput",
}
</script>
