<template>
    <sai-accordion-primary
        :fixed="accordion.fixed"
        :open="accordion.open"
        :id="accordion.id"
        :order="accordion.order"
        :settings="accordion.settings"
        :headerIcon="getAccordionContent(accordion.id).icon"
        @accordionOpenHandler="accordionOpenHandler"
        :headerTitle="$t(getAccordionContent(accordion.id).title)">
        <div slot="accordion-content" class="home-announcement-container">
            <div class="home-announcement-body">
                <div v-for="announcement in announcements" :key="announcement.id">
                    <div :class="['announcement' ,(announcement.type === 'global') ? 'saig-announcement' : '' ]">
                        <div class="announcement-title-bar">
                            <div class="announcement-company-name">
                                {{announcement.subName}}
                            </div>
                            <div class="announcement-date">
                                <strong> DATE: </strong>
                                {{announcement.timestamp}}
                            </div>
                        </div>
                        <div class="announcement-content">
                            <div class="notes-content" v-html="announcement.note">
                            </div>
                            <div v-if="announcement.linkURL && announcement.linkName">
                                <a target="_blank" class="announcement-link" :href="announcement.linkURL">
                                    {{announcement.linkName}}
                                </a>
                                &rarr;
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    </sai-accordion-primary>
</template>

<script>
import SaiAccordionPrimary from "@/components/common/SaiAccordionPrimary";
import {createNamespacedHelpers} from "vuex";
const {mapGetters, mapActions} = createNamespacedHelpers('quickviews');
export default {
    name: "Announcements",

    props:{
      accordion:{ type:Object, default:()=>{} }
    },
    components: {
        SaiAccordionPrimary,
    },
    computed: {
        announcements() {
          return this.getAccordionContent(this.accordion.id).content
        },
        ...mapGetters([
                'getAccordionContent'
            ]
        )
    },
    methods: {
        ...mapActions([
            'setAccordionOptions'
        ]),
        accordionOpenHandler(values) {
            this.setAccordionOptions( {accordion:values.accordion, payload:{"open": values.value} } )
        }
    }
}
</script>
