import axios from "@/plugins/axios";

let tourGuides = [];

export const apiGetTourGuides = async () => {
    try {
        const apiEndPoint = 'tourGuideSections';
        const response = await axios.get({endpoint: '/management/services.v1.homeajax/' + apiEndPoint});
        if (response.data) {
            if (response.data.status === 'ok') {
                tourGuides = response.data;
                return tourGuides.data;
            } else {
                console.log('There has been a problem with your fetch operation.');
            }
        }
    } catch (e) {
        console.log('There has been a problem with your fetch operation: ' + e.message);
    }
    return [];
}

export const apiUpdateTourGuideSettings = async (settings, id) => {
    try {
        let options = {};
        let params = {};
        options = {headers: {'Content-Type': 'application/x-www-form-urlencoded'}};
        params = new URLSearchParams();
        params.append('key', 'tourguide');
        params.append('value', JSON.stringify(settings));
        params.append('guideId', id);

        const apiEndPoint = 'tourGuideSettings';
        const response = await axios.post({
            endpoint: '/management/services.v1.homeajax/' + apiEndPoint,
            params: params,
            options: options

        });
        if (response.data) {
            return response.data;
        }
    } catch (e) {
        console.log('There has been a problem with your fetch operation:' + e.message);
    }
    return [];
}
