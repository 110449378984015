<template>
    <div>
        <SaiAutoSuggest 
            labelBy="label" 
            trackBy="value"
            className="search-box supplier-name title-field metalsSupplierSection form-control shadow-none form-control-text metals-supplier-inputs row1-font ui-autocomplete-input"
            api="/management/search/field/metals_supplier_name"
            :apiExtraParams="{type: 'metals_supplier_name'}"
            apiHeaderContentType="form-urlencoded"
            :placeholder="$t('frm_name')"
            name="metals_supplier_name" id="metals_supplier_name"
            :value="getMetalsSupplierFilter('name')"
            @input="$event => setMetalsSupplierFilter({field: 'name', payload: $event})"
            @addSuggestValue="$event => setMetalsSupplierFilter({field: 'name', payload: $event})" :disabled="!isActiveTab('metalsSupplier')"/>
    </div>
</template>
<script>
    import SaiAutoSuggest from '@/components/common/SaiAutoSuggest';
    import { createNamespacedHelpers } from 'vuex';
    const { mapGetters, mapActions } = createNamespacedHelpers('searchbar');
    export default {
        name: "QuickSearchMetalSupplier",
         components: {
            SaiAutoSuggest
        },
        computed: {
            ...mapGetters([
                'getMetalsSupplierFilter',
                'isActiveTab'
            ])
        },
        methods: {
            ...mapActions([
                'setMetalsSupplierFilter'
            ])
        }
    }
</script>
<style lang="scss">
     .supplier-name.title-field {
         width: 100%;
     }
</style>
