<template>
    <ul class="mobile-search-tabs" >
        <li v-for="tab in getFilteredSearchTabs" class="search-tab" :key="tab.id" @click="setActiveSearchTab(tab.id)">
            <span :class=" isActiveTab(tab.id) ? 'search-tab-link active' : 'search-tab-link' ">{{ tab.name }}</span>
        </li>
        <input type="hidden" name="searchCriteria" :value="getTabValueById(getActiveSearchTab)"/>
    </ul>
    
</template>

<script>
    import { createNamespacedHelpers } from 'vuex';
    const { mapGetters, mapActions } = createNamespacedHelpers('searchbar');
    export default {
        name: "SearchBar",
        computed: {
            ...mapGetters([
                'getSearchTabs',
                'isActiveTab',
                'getActiveSearchTab',
                'getTabValueById',
                'isActivePWA'
            ]),
            getFilteredSearchTabs() {
                if (this.isActivePWA) {
                    return this.updatedSearchTabs();
                } else {
                    return this.getSearchTabs;
                }
            }
        },
        methods: {
            ...mapActions([
                'setActiveSearchTab'
            ]),
            updatedSearchTabs() {
                return this.getSearchTabs.filter(tab => tab.name === "Standards" || tab.name === "Collections" )
            }
        }
    }
</script>
