<template>
    <div :class="['multiColumnar-container' , isActiveDetailedView ? 'column' : '']">
        <div v-for="content in bodyContent" :key="content.title" class="card-container">
            <SaiCard>
                <div slot="card-header">
                    <span>
                        <i :class="content.icon"></i>
                    </span>
                    <span>{{$t(content.title)}}</span>
                </div>
                <div slot="card-body" >
                    <div v-for="subContent in content.subContent" :key="subContent.id">

                        <template  v-if="isActiveDetailedView">
                            <ColumnarContent :headerContent="subContent.headers"
                                             :bodyContent="subContent.content"
                                             class="accordion-content"/>
                        </template>

                        <template v-else>
                           <div v-for="element in subContent.content" :key="element" class="compact-content-container">
                            <div v-for="name in element" :key="name">
                                <a :href="name.linkURL">
                                    {{name.linkName}}
                                </a>

                                <template v-if="name.actions">
                                    <EditSavedSearch :id="name.actions.id" :name="name.linkName"/>
                                </template>

                            </div>
                           </div>
                        </template>

                    </div>
                </div>

            </SaiCard>
        </div>
    </div>
</template>

<script>
import SaiCard from "@/components/common/SaiCard";
import ColumnarContent from "@/components/QuickViews/AccordionContent/ColumnarContent";
import EditSavedSearch from "@/components/QuickViews/EditSavedSearch";
export default {
    name: "MultiColumnarContent",
    components: {
        SaiCard,
        ColumnarContent,
        EditSavedSearch
    },
    props: {
        bodyContent: { type: Array, default: () => [] },
        settings: { type: Object, default: () => [] },
    },
    computed: {
        isActiveDetailedView() {
            return this.settings && this.settings['viewMode']
        }
    }
}
</script>


