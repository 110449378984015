<template>
    <SaiModal :display="display"  @closeModal="handleCloseExport" className="search-result-export">
        <span slot="title">
            <b v-if="getExportStatus == 'complete' || getExportStatus == 'completewitherror'">Export</b>
            <b v-else>Export Options</b>
        </span>
        <template slot="body">
            <form method="post" target="_blank" :action="getSearchPageUrl" class="searchExport sai-form" title="Export Options" v-if="getExportStatus == '' || getExportStatus == 'inprogress'">
                <input type="hidden" name="exportSearch" value="1">
                <div class="sai-options export-type">
                    <label>Type</label>
                    <div class="options">
                        <SaiRadio name="file[]" :options="exportTypeOptions" :value="getExportCsvSeparator" labelBy="key" trackBy="val" @change="$event => setExportCsvSeparator($event)"/>
                    </div>
                </div>
                <div class="sai-options fields">
                    <label>Options</label>
                    <div class="options">
                        <SaiCheckbox name="filetype[]" :options="fieldOptions" :value="getSelectedFieldsToExport" prefix="export" labelBy="key" trackBy="val" @change="$event => setSelectedFieldsToExport($event)"/>
                    </div>
                </div>
                <div class="form-hint">
                    {{ $t("export_limit") }}
                </div>
                <div class="sai-form-buttons">
                    <button class="sai-btn-primary" @click.prevent="handleExport">Export</button>
                    <a class="closeDialog" @click="handleCloseExport">Cancel</a>
                </div>
                 <Loading :active="getExportStatus == 'inprogress'" 
                    :is-full-page="false" loader="dots" color='#202020' :width="20" :height="20"
                    :can-cancel="false"
                    backgroundColor="#ffffff"
                    :opacity=0.8
                    :z-index=999
                >
                    <template v-slot:default>
                    <div class="loadingoverlay_element" style="order: 1; box-sizing: border-box; overflow: visible; flex: 0 0 auto; display: flex; justify-content: center; align-items: center; animation-name: loadingoverlay_animation__rotate_right; animation-duration: 2000ms; animation-timing-function: linear; animation-iteration-count: infinite; width: 20.5px; height: 20.5px;"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000" style="width: 100%; height: 100%; fill: rgb(32, 32, 32);"><circle r="80" cx="500" cy="90" style="fill: rgb(32, 32, 32);"></circle><circle r="80" cx="500" cy="910" style="fill: rgb(32, 32, 32);"></circle><circle r="80" cx="90" cy="500" style="fill: rgb(32, 32, 32);"></circle><circle r="80" cx="910" cy="500" style="fill: rgb(32, 32, 32);"></circle><circle r="80" cx="212" cy="212" style="fill: rgb(32, 32, 32);"></circle><circle r="80" cx="788" cy="212" style="fill: rgb(32, 32, 32);"></circle><circle r="80" cx="212" cy="788" style="fill: rgb(32, 32, 32);"></circle><circle r="80" cx="788" cy="788" style="fill: rgb(32, 32, 32);"></circle></svg></div>
                    </template>
                </Loading>
            </form>
            <span v-if="getExportStatus == 'complete'">
                Export job has been created, once documents are exported, it will be emailed to you.
            </span>
            <span v-if="getExportStatus == 'complete' && getExportResult['total_found']>10000"><br><br>Note: Search resulted in {{ getExportResult['total_found'] }} documents.<br>but export limit is 10000 documents only.</span>
            <span v-if="getExportStatus == 'completewitherror'">Some error occurred while setting up export job...</span>
        </template>
    </SaiModal>
</template>

<script>

    import Loading from 'vue-loading-overlay';
    import SaiRadio from '@/components/common/SaiRadio';
    import SaiCheckbox from '@/components/common/SaiCheckbox';
    import SaiModal from '@/components/common/SaiModal';
    import {createNamespacedHelpers} from 'vuex';
    const { mapActions: searchResutlsMapActions, mapGetters: searchResutlsMapGetters } = createNamespacedHelpers('searchresults')
    
    export default {
        name: "SearchResultExport",
        props: {
            display: { type: Boolean, default: false, required: true },
            fieldOptions: { type: Array, default: () =>  [], required: true }
        },
        components: {
            SaiCheckbox,
            SaiRadio,
            SaiModal,
            Loading
        },
        data() {
            return {
                exportTypeOptions: [
                    { key: 'csv', val: 'Comma' },
                    { key: 'tsv', val: 'Tab' }
                ]
            }
        },
        computed: {
            ...searchResutlsMapGetters([
                'getSearchPageUrl',
                'getExportStatus',
                'getSelectedFieldsToExport',
                'getExportResult',
                'getExportCsvSeparator'
            ])
        },
        methods: {
            ...searchResutlsMapActions([
                'performExport',
                'setSelectedFieldsToExport',
                'setExportStatus',
                'setExportCsvSeparator'
            ]),
            handleCloseExport(event) {
                if(this.getExportStatus != 'inprogress') {
                    this.$emit('close', event);
                    let selectedFieldsToExport = [];
                    this.fieldOptions.forEach(function (item) {
                        selectedFieldsToExport.push(item.key);
                    });
                    this.setExportStatus('');
                    this.setSelectedFieldsToExport(selectedFieldsToExport);
                }
            },
            handleExport() {
               this.performExport();
            }
        }
    }
</script>
<style lang="scss">
    .search-result-export {
        .modal-content {
             max-width: 450px;
            .modal-body {
                margin: 0;
            }
            .sai-options {
                &.export-type {
                    .sai-radio-yellow {
                        width: 100%;
                        input[type=radio]+label {
                            width: 30%;
                        }
                        input[type=radio]:checked+label {
                            border: 2px solid #ffbd35;
                        }
                        label {
                            padding: 10px;
                            &:before,
                            &:after {
                                width: 0;
                                height: 0;
                                display: none;
                            }
                        }
                    }
                }
                &.fields {
                    .checkbox-box {
                        width: 30%;
                    }
                }
            }
        }
    }
</style>