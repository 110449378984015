<template>
    <div class="quickSearchBox">
        <SaiInput type="text" class="mobile-search-input"
                  name="metals_designation"
                  :value="getMetalsFilter('designation')"
                  @input="$event => setMetalsFilter({field: 'designation', payload: $event})"
                  :placeholder="$t('frm_designation')" :disabled="!isActiveTab('metals')"/>

        <SaiInput type="text" class="mobile-search-input"
                  name="metals_description"
                  :value="getMetalsFilter('description')"
                  @input="$event => setMetalsFilter({field: 'description', payload: $event})"
                  :placeholder="$t('frm_description')" :disabled="!isActiveTab('metals')"/>
    </div>
</template>

<script>
    import SaiInput from '@/components/common/SaiInput';
    import { createNamespacedHelpers } from 'vuex';
    const { mapGetters, mapActions } = createNamespacedHelpers('searchbar');
    export default {
        name: "QuickSearchMetal",
        components: {
            SaiInput
        },
        computed: {
            ...mapGetters([
                'getMetalsFilter',
                'isActiveTab'
            ]),
        },
        methods: {
            ...mapActions([
                'setMetalsFilter'
            ])
        }
    }
</script>

<style scoped>

</style>