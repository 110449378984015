<template>
    <SaiModal :display="display" @closeModal="closeModal">
        <span slot="title">{{$t('frm_save_search')}}</span>
        <form slot="body" method="post" @submit.prevent="saveSearch">
            <div class="sai-field">
                <label>{{$t('idx_title')}}</label>
                <input type="text" :placeholder="$t('frm_placeHolder_title')"
                       name="save_search_name" maxlength="50" v-model="saveName" required>
                <div :class="['sai-error', errors ? 'show' : '' ]" v-if="errors" v-html="errors"></div>
            </div>
            <div class="sai-form-buttons">
                <button class="sai-btn-primary">{{$t('save')}}</button>
                <a class="closeDialog" @click="closeModal">{{$t('frm_cancel')}}</a>
            </div>
        </form>
    </SaiModal>
</template>

<script>
    import axios from '@/plugins/axios';
    import {createNamespacedHelpers} from 'vuex';
    const {mapGetters, mapActions} = createNamespacedHelpers('searchresults');
    const {mapGetters: searchBarGetters } = createNamespacedHelpers('searchbar');
    import SaiModal from '@/components/common/SaiModal'

    export default {
        name: "SaveSearchModal",
        data() {
          return {
              saveName: '',
              errors: ''
          }
        },
        props: {
            display:{type:Boolean,default:false}
        },
        components: {
            SaiModal
        },
        computed: {
            ...mapGetters([
                
                'getSearchPageUrl'
            ]),
            ...searchBarGetters([
                'getNonce'
            ])
        },
        methods:{
            ...mapActions([
                'setIsSavedSearch'
            ]),
            closeModal:function() {
                this.$emit('closeModal');
            },
            saveSearch: async function(){
                let loader = this.$loading.show();
                try {
                    let options = {headers: {'Content-Type': 'application/x-www-form-urlencoded'}};
                    let params = new URLSearchParams();
                    let apiUrl = this.getSearchPageUrl;
                    params.append('save_search_name' , this.saveName);
                    params.append('save_search_sel' , '0');
                    params.append('onclk_saveSearch' , 'save');
                    params.append('nonce' , this.getNonce);
                    const response = await axios.post({ endpoint: apiUrl, params: params, options: options });

                    if(response.data) {
                        this.$toastr.s("Success", response.data);
                        this.$emit('closeModal');
                        this.setIsSavedSearch(true);
                        loader.hide();
                        return response.data;
                    } else  {
                        return [];
                    }
                } catch(e) {
                    this.errors =  e.response.data;
                    loader.hide();
                }
            }
        }
    }
</script>
