import { metalsInitialState, metalsSupplierInitialState, materialsInitialState } from './initialState';
import { apiGetICSCodeOptions } from '@/components/SearchBar/api';
import Mutation from './mutationTypes';

export default {
    setPWAStatus: ( {commit}, status) => {
      commit(Mutation.SET_PWA_STATUS, status)
    },
    setPwaNavOverlayVars: ( {commit}, vars) => {
        commit(Mutation.SET_PWA_NAV_OVERLAY_VARS, vars)
    },
    togglePwaNavOverlayLeft: ( {commit} , status) => {
        commit(Mutation.TOGGLE_PWA_NAV_OVERLAY_LEFT, status)
    },
    togglePwaNavOverlayRight: ( {commit} , status) => {
        commit(Mutation.TOGGLE_PWA_NAV_OVERLAY_RIGHT, status)
    },
    initializeSearchBarFilters: ({ commit }, searchBarFilterValues) => {
        commit(Mutation.SET_INITIALIZE_SEARCH_BAR_FILTERS, searchBarFilterValues);
    },
    setUserProfile: ({ commit }, userProfile) => {
        commit(Mutation.SET_USER_PROFILE, userProfile);
    },
    setDisplaySearch: ({ commit }, blnDisplay) => {
        commit(Mutation.SET_DISPLAY_SEARCH, blnDisplay);
    },
    toggleSearchBar: ({ commit }) => {
        commit(Mutation.TOGGLE_SEARCH_BAR);
    },
    setActiveSearchTab: ({ commit }, activeSearchTab) => {
        commit(Mutation.SET_ACTIVE_SEARCH_TAB, activeSearchTab);
    },
    setSearchTabs: ({ commit }, searchTabs) => {
        commit(Mutation.SET_SEARCH_TABS, searchTabs);
    },
    setSubId: ({ commit }, subId) => {
        commit(Mutation.SET_SUB_ID, subId);
    },
    setRecentlyViewedDocs: ({ commit }, recentlyViewedDocs) => {
        commit(Mutation.SET_RECENTLY_VIEWED_DOCS, recentlyViewedDocs);
    },    
    setAdvanceSearchDisplay: ({ commit }, { tab, blnDisplay }) => {
        commit(Mutation.SET_ADVANCE_SEARCH_DISPLAY, { tab, blnDisplay });
    },    
    allowAdvanceSearch: ({ commit }, { tab, blnAllow }) => {
        commit(Mutation.ALLOW_ADVANCE_SEARCH, { tab, blnAllow });
    },    
    setLookUpOptions:  ({ commit }, {type, options}) => {
        commit(Mutation.SET_LOOKUP_OPTIONS, {type, options});
    },

    //standards actions
    setStandardsFilter: ({ commit }, {field, payload}) => {
        commit(Mutation.SET_STANDARDS_FILTER, {field, payload});
    },
    //collections actions
    setCollectionsFilter: ({ commit }, {field, payload}) => {
        commit(Mutation.SET_COLLECTIONS_FILTER, {field, payload});
    },
    //categories actions
    setCategoriesFilter: ({ commit }, {field, payload}) => {
        commit(Mutation.SET_CATEGORIES_FILTER, {field, payload});
    },
    loadICSCodeOptions: async ({ commit }, {field, icsCode}) => {
        try {
            //remove options before loading new options
            commit(Mutation.SET_LOOKUP_OPTIONS, { type: field, options: [] });
            const options =  await apiGetICSCodeOptions(icsCode.id);
            //load new options before loading new options
            commit(Mutation.SET_LOOKUP_OPTIONS, { type: field, options });
        } catch(error) {
            //remove options in case of error
            commit(Mutation.SET_LOOKUP_OPTIONS, { type: field, options: [] });
            console.log('api call failed for ISC Code ' + icsCode.id);
            console.log(error);
        }
    },
    //latest actions
    setLatestFilter: ({ commit }, {field, payload}) => {
        commit(Mutation.SET_LATEST_FILTER, {field, payload});
    },

    //metals actions
    setMetalsCompositionFilters: ({ commit }, {type, compositions}) => {

        commit(Mutation.SET_METALS_COMPOSITION_FILTERS, {type, compositions});
    },
    showMetalsCompositionFilters: ({ commit }, {type, show}) => {
        commit(Mutation.SHOW_METALS_COMPOSITION_FILTERS, {type, show});
    },
    setMetalsCompositionFiltersErrMsg: ({ commit }, {type, errMsg}) => {
        commit(Mutation.SET_METALS_COMPOSITION_FILTERS_ERR_MSG, {type, errMsg});
    },
    setMetalsFilter: ({ commit }, {field, payload}) => {
        commit(Mutation.SET_METALS_FILTER, {field, payload});
    },

    //metals supplier actions
    setMetalsSupplierFilter: ({ commit }, {field, payload}) => {
        commit(Mutation.SET_METALS_SUPPLIER_FILTER, {field, payload});
    },
    
    //material supplier actions
    setMaterialsSupplierFilter: ({ commit }, {field, payload}) => {
        commit(Mutation.SET_MATERIALS_SUPPLIER_FILTER, {field, payload});
    },


    //materials actions
    setMaterialsPropertyFilters: ({ commit }, {type, properties}) => {
        commit(Mutation.SET_MATERIALS_PROPERTY_FILTERS, {type, properties});
    },
    showMaterialsPropertyFilters: ({ commit }, {type, show}) => {
        commit(Mutation.SHOW_MATERIALS_PROPERTY_FILTERS, {type, show});
    },
    setMaterialsPropertyFiltersErrMsg: ({ commit }, {type, errMsg}) => {
        commit(Mutation.SET_MATERIALS_PROPERTY_FILTERS_ERR_MSG, {type, errMsg});
    },
    setMaterialsFilter: ({ commit }, {field, payload}) => {
        commit(Mutation.SET_MATERIALS_FILTER, {field, payload});
    },

    clearSearchFields: ({ dispatch },field) => {
        const standardsFields = ['documentNumber','documentTitle','publisher','country','dataset','crossReference'];
        const collectionsFields = ['documentNumber','companyCollection','documentType','notes'];
        const collectionsMultiFields = ['collections','status','tags'];

        switch (field) {
            case 'collections':
                collectionsFields.forEach(collectionsField => {
                    dispatch('setItem', {  field: 'collections.' + collectionsField, payload: '' });
                });
                collectionsMultiFields.forEach(collectionsMultiField => {
                    dispatch('setItem', {  field: 'collections.' + collectionsMultiField, payload: [] });
                })
                break;
            case 'standards':
                standardsFields.forEach(standardsField => {
                    dispatch('setItem', {  field: 'standards.' + standardsField, payload: '' });
                });
                dispatch('setItem', { field: 'standards.status', payload: '' });
                break;
        }
    },

    setItem: ({ dispatch }, { field, payload }) => {
        const arrFilterField =  field.split(".");
        const dispatchPayload = { field: arrFilterField[1], payload };
        switch(arrFilterField[0]) {
            case 'materials':
                return dispatch('setMaterialsFilter', dispatchPayload);
            case 'metals':
                return dispatch('setMetalsFilter', dispatchPayload);
            case 'materialsSupplier':
                return dispatch('setMaterialsSupplierFilter', dispatchPayload);
            case 'metalsSupplier':
                return dispatch('setMetalsSupplierFilter', dispatchPayload);
            case 'standards':
                return dispatch('setStandardsFilter', dispatchPayload);
            case 'collections':
                return dispatch('setCollectionsFilter', dispatchPayload);
            case 'categories':
                return dispatch('setCategoriesFilter', dispatchPayload);
            case 'latest':
                return dispatch('setLatestFilter', dispatchPayload);
        }
    },

    setSearchBarField: ({ commit }, {field, payload}) => {
        commit(Mutation.SET_SEARCH_BAR_FIELD, {field, payload});
    },

    setSearchBarForComparableMetals: ({ dispatch }, { type, compositions }) => {
        dispatch('setSearchBarField', { field: 'metals', payload:  JSON.parse(JSON.stringify(metalsInitialState)) });
        dispatch('setMetalsCompositionFilters', { type, compositions: [ ...compositions ] });
        dispatch('setActiveSearchTab', 'metals');
        dispatch('setDisplaySearch', true);
        dispatch('showMetalsCompositionFilters', { type, show: true});
    },

    setSearchBarForRelatedMetalsSupplier: ({ dispatch }, supplierFields) => {
        dispatch('setSearchBarField', { field: 'metalsSupplier', payload: Object.assign({}, { ...metalsSupplierInitialState }, { ...supplierFields }) });
        dispatch('setActiveSearchTab', 'metalsSupplier');
        dispatch('setDisplaySearch', true);
    },
    setSearchBarForComparableMaterials: ({ dispatch }, { type, properties }) => {
        dispatch('setSearchBarField', { field: 'materials', payload:  JSON.parse(JSON.stringify(materialsInitialState)) });
        dispatch('setMaterialsPropertyFilters', { type, properties: [ ...properties ] });
        dispatch('setActiveSearchTab', 'materials');
        dispatch('setDisplaySearch', true);
        dispatch('showMaterialsPropertyFilters', { type, show: true});
    },

}