<template>
    <span :class="['document-status', className]">
        <template v-if="className==='sai-product-status-current'">
            <i class="si si-check-circle"></i>
        </template>

        {{$t(title)}}
    </span>
</template>

<script>
export default {
    name: "DocumentStatus",
    data() {
        return {
            title: '',
            className: ''
        }
    },
    props: {
        status: {type: String, default:''}
    },
    mounted() {
        switch (this.status) {
            case 'NA':
                this.title = 'doc_status_na';
                this.className = 'sai-product-status-historical'
                break;
            case 'C':
                this.title = 'doc_status_c';
                this.className = 'sai-product-status-cancelled'
                break;
            case 'S':
                this.title = 'doc_status_s';
                this.className = 'sai-product-status-superceded'
                break;
            case 'AP':
                this.title = 'doc_status_ap';
                this.className = 'sai-product-status-historical'
                break;
            case 'AVS':
                this.title = 'doc_status_avs';
                this.className = 'sai-product-status-historical'
                break;
            case 'P':
                this.title = 'doc_status_p';
                this.className = 'sai-product-status-partiallySuperseded'
                break;
            case 'O':
                this.title = 'doc_status_o';
                this.className = 'sai-product-status-historical'
                break;
            case 'T':
                this.title = 'doc_status_t';
                this.className = 'sai-product-status-cancelled'
                break;
            case 'W':
                this.title = 'doc_status_w';
                this.className = 'sai-product-status-withdrawn'
                break;
            case 'RE':
                this.title = 'doc_status_re';
                this.className = 'sai-product-status-historical'
                break;
            case 'CUR':
                default:
                this.title = 'doc_status_cur';
                this.className = 'sai-product-status-current'
                break;

        }
    },
}
</script>
