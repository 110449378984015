<template>
    <div class="quickSearch-row">
        <SearchTabs />
        <QuickSearchStandard v-show="isActiveTab('standards')" />
        <QuickSearchCollection v-show="isActiveTab('collections')" />
        <QuickSearchCategories v-show="isActiveTab('categories')" />
        <QuickSearchLatest v-show="isActiveTab('latest')"/>
        <QuickSearchMetal v-show="isActiveTab('metals')"/>
        <QuickSearchMetalSupplier  v-show="isActiveTab('metalsSupplier')"/>
        <QuickSearchMaterial  v-show="isActiveTab('materials')"/>
        <QuickSearchMaterialSupplier  v-show="isActiveTab('materialsSupplier')"/>
    </div>
</template>

<script>
    import SearchTabs from './SearchTabs';
    import QuickSearchStandard from './Standards/QuickSearchStandard';
    import QuickSearchCollection from './Collections/QuickSearchCollection';
    import QuickSearchCategories from './Categories/QuickSearchCategories';
    import QuickSearchLatest from './Latest/QuickSearchLatest';
    import QuickSearchMetal from './Metals/QuickSearchMetal';
    import QuickSearchMetalSupplier from './MetalsSupplier/QuickSearchMetalSupplier'
    import QuickSearchMaterial from './Materials/QuickSearchMaterial'
    import QuickSearchMaterialSupplier from './MaterialSuppliers/QuickSearchMaterialSupplier'

    import { createNamespacedHelpers } from 'vuex';
    const { mapGetters } = createNamespacedHelpers('searchbar');
    export default {
        name: "QuickSearch",
        components: {
            SearchTabs,
            QuickSearchStandard,
            QuickSearchCollection,
            QuickSearchCategories,
            QuickSearchLatest,
            QuickSearchMetal,
            QuickSearchMetalSupplier,
            QuickSearchMaterial,
            QuickSearchMaterialSupplier
        },
        computed: {
            ...mapGetters([
                'isActiveTab'
            ])
        }
    }
</script>
