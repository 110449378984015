<template>
   <div class="advanced-row" v-show="isAdvanceSearchAllowed(getActiveSearchTab) && isAdvanceSearchActive(getActiveSearchTab)">
        <AdvanceSearchStandard v-show="isActiveTab('standards')"/>
        <AdvanceSearchCollection v-show="isActiveTab('collections')"/>
        <AdvanceSearchCategories v-show="isActiveTab('categories')"/>
        <AdvanceSearchMetal v-show="isActiveTab('metals')"/>
        <AdvanceSearchMetalSupplier v-show="isActiveTab('metalsSupplier')"/>
        <AdvanceSearchMaterial v-show="isActiveTab('materials')"/>
        <SectionCTA/>
    </div>
</template>
<script>
    import AdvanceSearchCategories from './Categories/AdvanceSearchCategories';
    import AdvanceSearchCollection from './Collections/AdvanceSearchCollection';
    import SectionCTA from './SectionCTA';
    import AdvanceSearchMaterial from './Materials/AdvanceSearchMaterial';
    import AdvanceSearchMetal from './Metals/AdvanceSearchMetal';
    import AdvanceSearchMetalSupplier from './MetalSuppliers/AdvanceSearchMetalSupplier';
    import AdvanceSearchStandard from "./Standards/AdvanceSearchStandard";
    
    import { createNamespacedHelpers } from 'vuex';
    const { mapGetters } = createNamespacedHelpers('searchbar');
    export default {
        name: "AdvanceSearch",
        components: {
            AdvanceSearchStandard,
            AdvanceSearchCollection,
            AdvanceSearchCategories,
            AdvanceSearchMetal,
            AdvanceSearchMetalSupplier,
            AdvanceSearchMaterial,
            SectionCTA
        },
        computed: {
            ...mapGetters([
                'isActiveTab',
                'isAdvanceSearchActive',
                'getActiveSearchTab',
                'isAdvanceSearchAllowed'
            ])
        }
    }
</script>