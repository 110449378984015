export default {
    isActivePWA: state => state.PWAStatus,
    getPwaNavOverlayVars: state => state.pwaNavOverlayVars,
    getPwaNavBurgerMenuLinks: state => state.pwaNavOverlayVars['burgerMenuLinks'],
    getPwaNavProfileMenuLinks: state =>state.pwaNavOverlayVars['profileMenuLinks'],
    isPwaNavOverlayLeftVisible: state =>state.pwaNavOverlayLeftVisibility,
    isPwaNavOverlayRightVisible: state =>state.pwaNavOverlayRightVisibility,
    getNonce: state => state.nonce,
    getActiveSearchTab:  state => state.activeSearchTab,
    getTabIdByValue: state => tabValue => {
       const tab =  state.searchTabs.find(tab => tab.value == tabValue)
       return tab.id;
    },
    getTabValueById: state => tabId => {
        const tab =  state.searchTabs.find(tab => tab.id == tabId)
        return tab.value;
     },
    getSearchTabs: state => state.searchTabs,
    getSubId: state => state.subId,
    getUserProfile: state => state.userProfile,
    isPowerUser: state => state.userProfile.perms.isPowerUser,
    isSearchOpen: state => state.displaySearch,
    isActiveTab: state => tabToCheck => (state.activeSearchTab == tabToCheck),
    isAdvanceSearchActive: state => tab => state[tab].displayAdvanceSearch,
    isAdvanceSearchAllowed: state => tab => state[tab].allowAdvanceSearch,
    isAdvanceFilterDisabled:  (state, getters)  => tab => {
        return !getters.isActiveTab(tab) || !getters.isAdvanceSearchAllowed(tab) || !getters.isAdvanceSearchActive(tab);
    },
    isLiteSubscription: state => state.isLiteSubscription,
    hasMobileAdvanceSearch: state => tab => state[tab].mobileAdvanceSearch,
    getRecentlyViewedDocs: state => state.recentlyViewedDocs,
    getLookUpOptions: state => type => state.lookUpOptions[type],

    //standards getters
    getStandardsFilter: state => field => state.standards[field],

    //collections getters
    getCollectionsFilter: state => field => state.collections[field],

    //categories getters
    getCategoriesFilter: state => field => state.categories[field],

    //latest getters
    getLatestFilter: state => field => state.latest[field],

    //metals getters
    getMetalsCompositionFilters: state => type => state.metals[type].filters,
    getMetalsCompositionFiltersCount: state => type => state.metals[type].filters.length,
    displayMetalsCompositionFilters: state => type => Boolean(state.metals[type].showFilters),
    getMetalsCompositionFiltersErrMsg: state => type => state.metals[type].errMsg,
    getMetalsFilter: state => field => state.metals[field],

    //metals supplier getters
    getMetalsSupplierFilter: state => field => state.metalsSupplier[field],

    //materials getters
    getMaterialsPropertyFilters: state => type => state.materials[type].filters,
    getMaterialsPropertyFiltersCount: state => type => state.materials[type].filters.length,
    displayMaterialsPropertyFilters: state => type => Boolean(state.materials[type].showFilters),
    getMaterialsPropertyFiltersErrMsg: state => type => state.materials[type].errMsg,
    getMaterialsFilter: state => field => state.materials[field],

    //materials suppler getters
    getMaterialsSupplierFilter: state => field => state.materialsSupplier[field],
    
    getItem: (state, getters) => field => {
        const arrFilterField =  field.split(".");
        switch(arrFilterField[0]) {
            case 'materials':
                return getters.getMaterialsFilter(arrFilterField[1]);
            case 'metals':
                return getters.getMetalsFilter(arrFilterField[1]);
            case 'materialsSupplier':
                return getters.getMaterialsSupplierFilter(arrFilterField[1]);
            case 'metalsSupplier':
                return getters.getMetalsSupplierFilter(arrFilterField[1]);
            case 'standards':
                return getters.getStandardsFilter(arrFilterField[1]);
            case 'collections':
                return getters.getCollectionsFilter(arrFilterField[1]);
            case 'categories':
                return getters.getCategoriesFilter(arrFilterField[1]);
            case 'latest':
                return getters.getLatestFilter(arrFilterField[1]);
        }
    },
    
}