<template>
    <div class="materialsSupplier-quick-search" style="">
        <div class="materials-supplier-supplier">
            <SaiAutoSuggest 
            labelBy="label" 
            trackBy="value"
            className="search-box supplier materials-supplier-inputs materialsSupplierSection form-control shadow-none form-control-text row1-font title-field ui-autocomplete-input"
            api="/management/search/field/material_supplier_name"
            :apiExtraParams="{type: 'metals_supplier_name'}"
            apiHeaderContentType="form-urlencoded"
            :placeholder="$t('frm_supplier')"
            name="material_supplier_name" id="material_supplier_name"
            :value="getMaterialsSupplierFilter('name')"
            @input="$event => setMaterialsSupplierFilter({field: 'name', payload: $event})"
            @addSuggestValue="$event => setMaterialsSupplierFilter({field: 'name', payload: $event})"  :disabled="!isActiveTab('materialsSupplier')"/>
        </div>
        <div class="materials-supplier-country materialsSupplierCountrySearch">
            <FilterMultiSelect name="material_supplier_country" :placeholder="$t('fld_country')" field="materialsSupplier.country"/>
            <input type="hidden" name="material_supplier_country" :value="getMaterialsSupplierFilter('country').id"  :disabled="!isActiveTab('materialsSupplier')"/>
        </div>
    </div>
</template>
<script>
    import FilterMultiSelect from '@/components/SearchBar/SearchBarDesktop/FilterMultiSelect';
    import SaiAutoSuggest from '@/components/common/SaiAutoSuggest';
    import { createNamespacedHelpers } from 'vuex';
    const { mapGetters, mapActions } = createNamespacedHelpers('searchbar');
    export default {
        name: "QuickSearchMaterialSupplier",
          components: {
            FilterMultiSelect,
            SaiAutoSuggest
        },
        computed: {
            ...mapGetters([
                'getMaterialsSupplierFilter',
                'isActiveTab'
            ])
        },
        methods: {
            ...mapActions([
                'setMaterialsSupplierFilter'
            ])
        }
    }
</script>
<style lang="scss">

.supplier.search-box {
    width: 100%;
}
</style>