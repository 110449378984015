<template>
    <div>
        <div class="form-row search-bar-spacer">
            <div class="col col-sm-8 col-md-8 col-lg-9">
                    <div class="recent-searches" v-if="getRecentlyViewedDocs">{{ $t('recently_viewed') }}:
                        <ul id="searchtaglist">
                            <li v-for="(docs, index) in getRecentlyViewedDocs" :key="index">
                                <a :href="docs.link" :title="docs.name" class="linkname">{{ docs.name }}</a>
                            </li>
                        </ul>
                    </div>
                <div  v-if="!isAdvanceSearchAllowed(getActiveSearchTab)"  class="search-btn-container">
                    <a href="/management/search/index" class="clear-search" @click="showLoader">{{ $t('frm_clear_search') }}</a>
                </div>
                <template v-if="!isAdvanceSearchActive(getActiveSearchTab) || !isAdvanceSearchAllowed(getActiveSearchTab)">
                    <div v-if="isActiveTab('standards') || isActiveTab('collections')" class="btn-search-ajax shadow-none btn-qsearch" @click.prevent="handleSearch">
                        {{ $t('frm_generic') }}
                        <Loading :active="getLoadingStatus" 
                            :is-full-page="false" loader="dots" color='#202020' :width="20" :height="20"
                            :can-cancel="false"
                            backgroundColor="#ffffff"
                            :opacity="0.8"
                            :z-index="999"
                        >
                            <template v-slot:default>
                                <div class="loadingoverlay_element" style="order: 1; box-sizing: border-box; overflow: visible; flex: 0 0 auto; display: flex; justify-content: center; align-items: center; animation-name: loadingoverlay_animation__rotate_right; animation-duration: 2000ms; animation-timing-function: linear; animation-iteration-count: infinite; width: 20.5px; height: 20.5px;"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000" style="width: 100%; height: 100%; fill: rgb(32, 32, 32);"><circle r="80" cx="500" cy="90" style="fill: rgb(32, 32, 32);"></circle><circle r="80" cx="500" cy="910" style="fill: rgb(32, 32, 32);"></circle><circle r="80" cx="90" cy="500" style="fill: rgb(32, 32, 32);"></circle><circle r="80" cx="910" cy="500" style="fill: rgb(32, 32, 32);"></circle><circle r="80" cx="212" cy="212" style="fill: rgb(32, 32, 32);"></circle><circle r="80" cx="788" cy="212" style="fill: rgb(32, 32, 32);"></circle><circle r="80" cx="212" cy="788" style="fill: rgb(32, 32, 32);"></circle><circle r="80" cx="788" cy="788" style="fill: rgb(32, 32, 32);"></circle></svg></div>
                            </template>
                        </Loading>
                    </div>
                    <button v-else type="submit" class="btn-primary shadow-none btn-qsearch"
                        name="onclk_search" id="btn-qsearch" ondblclick="return false;"
                        onclick="this.onclick=function () {return false;};return true"
                        @click="setLoadingStatus(true)">
                        {{ $t('frm_generic') }}
                    </button>
                </template>
            </div>
            <div class="col col-sm-4 col-md-4 col-lg-3 advanced-hide-box" v-show="!isActiveTab('latest') && !isActiveTab('materialsSupplier')">
                <div class="advanced-hide advanced-hide-btn" style="">
                    <button v-if="isAdvanceSearchAllowed(getActiveSearchTab)" type="button" data-type="desktop" 
                    class="btn-default btn-arrow-bottom btn-outline btn-sm small-dev-advanced active" id="advnc-btn"
                    @click="toggleAdvanceSearch">
                        <span class="btn-txt" id="btn-txt" v-if="isAdvanceSearchActive(getActiveSearchTab)">{{ $t('frm_hide_filters') }}</span>
                        <span class="btn-txt" id="btn-txt" v-if="!isAdvanceSearchActive(getActiveSearchTab)">{{ $t('frm_show_filters') }}</span>
                        <i class="si-minus si"  v-if="isAdvanceSearchActive(getActiveSearchTab)"></i>
                        <i class="si-plus si"  v-if="!isAdvanceSearchActive(getActiveSearchTab)"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Loading from 'vue-loading-overlay';
    import { createNamespacedHelpers } from 'vuex';
    const { mapGetters, mapActions } = createNamespacedHelpers('searchbar');
    const { mapActions: resultsMapAction, mapGetters: resultsMapGetters }  = createNamespacedHelpers('searchresults');
    
    export default {
        name: "RecentlyViewed",
        components: {
            Loading
        },
        computed: {
            ...mapGetters([
                'getRecentlyViewedDocs',
                'isAdvanceSearchActive',
                'getActiveSearchTab',
                'isAdvanceSearchAllowed',
                'isActiveTab'
            ]),
            ...resultsMapGetters([
                'getLoadingStatus'
            ])
        },
        methods: {
            ...mapActions([
                'setAdvanceSearchDisplay',
            ]),
            ... resultsMapAction([
                'setLoadingStatus',
                'performMainSearch'
            ]),
            toggleAdvanceSearch() {
                this.setAdvanceSearchDisplay({ tab: this.getActiveSearchTab, blnDisplay: !this.isAdvanceSearchActive(this.getActiveSearchTab) });
            },
            handleSearch() {
               this.performMainSearch();
            },
            showLoader() {
                this.$loading.show();
            }
        }
    }
</script>
<style lang="scss">
.search-btn-container .clear-search {
    display: block !important;
    color: #fff;
    min-width: 90px;
    text-decoration: underline;
}

.btn-search-ajax {
    padding: 10px;
    position: relative;
}
</style>