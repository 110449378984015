<template>
    <div>
        <SearchBoxMobile
                :title="title"
                :selectedContent="getItem(field)"
                @clickHandler="overlay=true"/>

        <OverlayInputField :display="overlay"
                           :saveTitle="$t('frm_done')"
                           @closeOverlay="overlay=false"
                           @saveChanges="saveItem"
                           :headerTitle="title"
                           :inputName="inputName"
                           :inputValue="getItem(field)"
                           :inputPlaceholder="placeholder"
                           :disabled="disabled"/>
    </div>
</template>

<script>
    import {createNamespacedHelpers} from 'vuex';
    const {mapGetters, mapActions} = createNamespacedHelpers('searchbar');
    import OverlayInputField from '@/components/SearchBar/SearchBarMobile/OverlayInputField';
    import SearchBoxMobile from '@/components/common/SearchBoxMobile';

    export default {
        name: "FilterBoxInput",
        props: {
            title: {type: String, default: ''},
            field: {type: String, default: '', required: true},
            inputName: {type: String, default: ''},
            placeholder: {type: String, default: ''},
            overlay: {type: Boolean, default: false},
            disabled: {type: Boolean, default: false}
        },
        components: {
            SearchBoxMobile,
            OverlayInputField
        },
        computed: {
            ...mapGetters([
                'getItem',
            ]),
        },
        methods: {
            ...mapActions([
                'setItem',
            ]),
            saveItem: function(value) {
                this.overlay = false;
                this.setItem({  field: this.field, payload: value })
            }
        }
    }
</script>

<style scoped>

</style>