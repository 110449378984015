<template>
    <transition name="toggleSearchBar">
        <div v-show="isSearchOpen" class="searchBar panel-collapse collapse" v-bind:class="{ show: isSearchOpen }" id="searchBar">
            <form method="post" action="/management/search/index" name="quickSearchForm" id="desktopsearch" class="form" ref="desktopsearch">
                <div class="d-none d-md-inline d-lg-inline d-xl-inline">
                    <div class="container-fluid search-container shadow-loading-search">
                        <div class="container card card-body card-i2i ">
                            <!--simple search sectoin-->
                            <QuickSearch/>
                            <!--recently viewed -->
                            <RecentlyViewed v-if="!isLiteSubscription"/>
                        </div>
                    </div>
                    <div class="advanced-row-container">
                        <!-- advance row -->
                        <AdvanceSearch/>
                        <SearchBarProgress  v-if="isActiveTab('standards') || isActiveTab('collections')" :state="getSearchProgressStatus"/>
                    </div>
                </div>
            </form>
        </div>
    </transition>
</template>

<script>
    import { createNamespacedHelpers } from 'vuex';
    const { mapGetters } = createNamespacedHelpers('searchbar');
    const { mapGetters: searchResultsGetters } = createNamespacedHelpers('searchresults');

    import AdvanceSearch from './AdvanceSearch.vue';
    import RecentlyViewed from './RecentlyViewed.vue';
    import QuickSearch from './QuickSearch.vue';
    import SearchBarProgress from "./SearchBarProgress.vue";

    export default {
        name: 'SearchBarDesktop',
        components: {
            QuickSearch,
            AdvanceSearch,
            RecentlyViewed,
            SearchBarProgress
        },
        computed: {
          ...mapGetters([
            'isSearchOpen',
            'isActiveTab',
            'isLiteSubscription'
          ]),
          ...searchResultsGetters([
              'getSearchProgressStatus'
          ])
        }
    }
</script>
<style lang="scss">
    .toggleSearchBar-enter-active, .toggleSearchBar-leave-active {
        transition: transform .2s ease;
    }
    .toggleSearchBar-enter, .toggleSearchBar-leave-to {
        transform: translateY(-100%);
    }
</style>