<template>
  <div id="resPane">
    <div class="search-results-topBar">
      <div class="searchDocument">
        <div class="sai-options">
          <div class="options">
            <input type="checkbox" id="searchCheckbox" @change="selectAllRecords">
            <label for="searchCheckbox">{{ $t('frm_document')}}</label>
          </div>
        </div>
      </div>
      <div class="searchAction">{{ $t('frm_status')}}</div>
      <div class="searchAction">{{ $t('frm_version')}}</div>
      <div class="searchAction">{{ $t('frm_action')}}</div>
    </div>
    <search-results-record v-for="(record, index) in getSearchResultsField('records')" 
                           :key="record.doc_id"
                           :record="record"
                           :index="index"
                           />
    <div v-if="getSearchResultsField('hits') > getSearchResultsField('recordsPerPage')"
         class="sai-data-list-pagination">
      <sai-pagination :current-page="getSearchResultsField('page')"
                      :total-items="getSearchResultsField('hits')"
                      :page-size="getSearchResultsField('recordsPerPage')"
                      @gotoPage="gotoPage"></sai-pagination>
    </div>
    <div v-else-if="getSearchResultsField('nohits')">
      <p>{{ $t('no_results_reset_filters_search')}}</p>
    </div>
  </div>
</template>

<script>
import {createNamespacedHelpers} from 'vuex';
import SearchResultsRecord from "@/components/SearchResults/Collections/SearchResultsRecord";
import SaiPagination from "@/components/common/SaiPagination";

const {mapGetters, mapActions} = createNamespacedHelpers('searchresults');
const { mapGetters: searechBarGetters } = createNamespacedHelpers('searchbar');

export default {
  name: 'SearchResultsBody',
  components: {
    SearchResultsRecord,
    SaiPagination
  },
  computed: {
    ...mapGetters([
      'getSearchResultsField'
    ]),
    ...searechBarGetters([
      'getActiveSearchTab'
    ])
  },
  methods: {
    ...mapActions([
      'setCurrentPage',
      'fetchItems',
      'setBulkSelectedRecords'
    ]),
    gotoPage(event, pageNo) {
      window.scrollTo(0, 0);
      this.setCurrentPage(pageNo);
      this.fetchItems('collections');
    },
    selectAllRecords($event) {
       let selectedRecords = [];
       if ($event.target.checked) {
           this.getSearchResultsField('records').forEach(records=> {
               selectedRecords.push(String(records.doc_id));
           })
       }
       this.setBulkSelectedRecords(selectedRecords);
    }
  }
}
</script>

