<template>
        <SaiMultiSelect
        :disabled = "disabled"
        :multiple="multiple ? 'multiple' : ''"
        :class="multiple ? 'hasCheckbox purple-theme' : 'purple-theme'"
        :placeholder="placeholder"
        :value ="getItem(field)"
        @change = "$event => setItem({  field: field, payload: $event })"
        :hasAjax="!isOptionsLoaded"
        :options="options"
        @ajaxCall="loadOptions"
        :isLoading="isOptionsLoading"
        :searchable="searchable"
        labelBy ="text"
        trackBy="id"
        :hasClear="hasClear"
        :tagging="tagging"
        :name="'multiselect_' + name"/>
</template>
<script>
    import SaiMultiSelect from '@/components/common/SaiMultiSelect';
    import { createNamespacedHelpers } from 'vuex';
    const { mapGetters, mapActions } = createNamespacedHelpers('searchbar');
    import { apiGetFieldOptions } from '@/components/SearchBar/api';
    export default {
        name: "FilterMultiSelect",
        props: {
            multiple: { type: Boolean,  default: false, required: false }, 
            name: { type: String,  default: '', required: true },
            placeholder: { type: String,  default: '', required: true },
            field: { type: String, default: '', required: true },
            lookupField: { type: String, default: '', required: false },
            disabled: { type: Boolean, default: false },
            searchable: { type: Boolean, default: true },
            hasClear: { type: Boolean, default: true },
            tagging: { type: Boolean, default: false },
            initialOptions: { type: Array, default: () => [], required: false },
        },
        components: {
            SaiMultiSelect
        },
         data: function () {
            return {
                isOptionsLoading: false,
                isOptionsLoaded: false,
                options: [],
                lookupFieldName: '',
            }
        },
        mounted() {
            //load options from store, if its already laoded
            this.lookupFieldName = this.lookupField ? this.lookupField  : this.field;
            const options = this.getLookUpOptions(this.lookupFieldName);
            if(options && options.length) { 
                this.options = this.getUniqueOptions(options);
                this.isOptionsLoaded = true;
            }
        },
        computed: {
            ...mapGetters([
                'getItem',
                'getLookUpOptions'
            ])
        },
        methods: {
            ...mapActions([
                'setItem',
                'setLookUpOptions'
            ]),
            async loadOptions() {
                if(!this.isOptionsLoaded) {
                    this.isOptionsLoading = true;
                    const options = await apiGetFieldOptions(this.field);
                    this.options = this.getUniqueOptions(options);
                    this.setLookUpOptions({type: this.lookupFieldName, options});
                    this.isOptionsLoaded = true;
                    this.isOptionsLoading = false;
                }
            },
            getUniqueOptions(options) {
                let vm = this;
                let uniqueOptions = [];
                if(this.initialOptions.length>0) {
                    let filteredOptions = options.filter(function(option) {
                        for (const initOption of vm.initialOptions) {
                            if (initOption.id == option.id) {
                                return false;
                            }
                        }
                        return true;
                    });
                    uniqueOptions = [ ...this.initialOptions, ...filteredOptions]
                } else {
                    uniqueOptions = options;
                }
                return uniqueOptions;
            }
        }
    }
</script>