<template>
    <div class="form-group materialsProperties callModalBtn">
        <span v-if="getMaterialsPropertyFiltersCount(type) > 0" style="display: flex" class="sai-btn compositeSelected" @click="showFilterPopup"><span>{{getMaterialsPropertyFiltersCount(type)}} {{$t("criteria_selected")}}</span></span>
        <span v-else class="sai-btn compositeLabel" style="display: flex" data-value="" @click="showFilterPopup">{{$t('frm_' + type.replace('Property', '') + '_properties')}}</span>
        <input :name="'material_' + type.replace('Property', '')  + '_property'" type="hidden" :value="JSON.stringify(getMaterialsPropertyFilters(type))" :disabled="disabled">
       <FilterPopupProperties :type="type" :placeholder="placeholder" :display="displayMaterialsPropertyFilters(type)"/>
    </div>
</template>
<script>
    import { createNamespacedHelpers } from 'vuex';
    const searchBarStore = createNamespacedHelpers('searchbar');
    import FilterPopupProperties from './FilterPopupProperties';
    export default {
        name: "FilterProperties",
        props: {
            type: { type: String, default: '', required: true},
            placeholder: { type: String, required: true },
            disabled: { type: Boolean, default: false}
        },
        components: {
            FilterPopupProperties
        },
        computed: {
            ...searchBarStore.mapGetters([
                'getMaterialsPropertyFilters',
                'getMaterialsPropertyFiltersCount',
                'displayMaterialsPropertyFilters'
            ])
        },
        methods: {
            ...searchBarStore.mapActions([
                'showMaterialsPropertyFilters',
                'setMaterialsPropertyFiltersErrMsg'
            ]),
            showFilterPopup() {
                this.setMaterialsPropertyFiltersErrMsg({type: this.type, errMsg: ''});
                this.showMaterialsPropertyFilters({type: this.type, show: true});
            }
        }
    }
</script>
