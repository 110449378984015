import Mutation from './mutationTypes';
import {
    apiGetAccordions,
    apiGetAccordionContent,
    apiGetAccordionFooter,
    apiUpdateAccordionSettings,
    deleteSavedSearch,
    updateSavedSearch
} from "@/components/QuickViews/api"

export default {
    callUpdateSettingsApi: async ({getters}) => {
        await apiUpdateAccordionSettings(getters.getHomeAccordions);
    },
    setAccordionOptions: async ({commit, getters}, {accordion, payload, bulkChange} ) => {
        const hasBulkChange = bulkChange || false;
        getters.getHomeAccordions.forEach(el => {
            if (el.id === accordion) {
                accordion = el
            }
        })
        Object.assign(accordion, payload );
        commit(Mutation.SET_ACCORDION_OPTIONS, {accordion, payload});

        if (!hasBulkChange) {
            await apiUpdateAccordionSettings(getters.getHomeAccordions);
        }

    },
    fetchAccordions: async ({commit}) => {
        try {
            commit(Mutation.SET_LOADING_STATUS, true);
            let accordions = await apiGetAccordions();
            commit(Mutation.SET_HOME_ACCORDIONS, accordions);

              for (const accordion of accordions){
                let accordionName = accordion.id;
                let accordionContent = await apiGetAccordionContent(accordionName);
                if (accordion['footer']) {
                    const accordionFooter = await apiGetAccordionFooter(accordionName);
                    commit(Mutation.SET_ACCORDION_FOOTER,{accordionName,accordionFooter})
                }
                commit(Mutation.SET_ACCORDION_CONTENT,{accordionName,accordionContent})
            }

             commit(Mutation.SET_LOADING_STATUS, false);

        } catch(error) {
            console.log('apiGetAccordions failed.'+ error);
            commit(Mutation.SET_LOADING_STATUS, false);
        }
    },
    updateSavedSearch: async ({ commit }, {action, elementId, elementName }) => {
        const name = elementName || '';
        let response = false
        try {
            switch (action) {
                case 'delete':
                    const results = await deleteSavedSearch(elementId);
                    if (results) {
                        await apiGetAccordions();
                        let accordionName = "myActivities";
                        let accordionContent = await apiGetAccordionContent(accordionName);
                        commit(Mutation.SET_ACCORDION_CONTENT,{accordionName,accordionContent})
                        response = true;
                    }
                    break;
                case 'update':
                    const resultsUpdate = await updateSavedSearch(elementId, name);
                    if (resultsUpdate) {
                        await apiGetAccordions();
                        let accordionName = "myActivities";
                        let accordionContent = await apiGetAccordionContent(accordionName);
                        commit(Mutation.SET_ACCORDION_CONTENT,{accordionName,accordionContent})
                        response = true;
                    }
                    break;
            }
        } catch (e) {
            console.log(e);
        }
        return response;
    }
}