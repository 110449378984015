<template>
    <div :class="[themeClass ? themeClass : 'sai-options']">
        <div class="options">
            <span class="checkbox-box" v-for="option in options" :key="option[labelBy]">
                <input type="checkbox"
                       v-bind="$attrs"
                       v-on="inputListeners"
                       :id="prefix + option[labelBy]"
                       :name="name"
                       :checked="filterdValue.indexOf(String(option[labelBy])) >= 0 "
                       :value="option[labelBy]">
                <label :for="prefix + option[labelBy]">{{option[trackBy]}}</label>
            </span>
        </div>
    </div>
</template>

<script>
    export default {
        name: "SaiCheckbox",
        inheritAttrs: false,
        model: {
            prop : 'value',
            event: 'change'
        },
        props: {
            themeClass: {type: String, default: ''},
            options: {type: Array, default: () => [] },
            name: {type: String },
            value: {type: Array, default: function (arr) {
                    return arr.map(i=>String(i))
                }},
            labelBy: {type:String, default: 'key' },
            trackBy: {type:String, default: 'val' },
            prefix: {type:String, default: '' },
        },
        computed: {
            filterdValue: function() {
                const newVal = [];
                this.value.forEach(val => newVal.push(String(val)) );
                return newVal
            },
            inputListeners: function () {
                const vm = this;
                let checked = [...vm.value];
                return Object.assign({},
                    this.$listeners,
                    {
                        change: function (event) {
                            if (event.target.checked) {
                                checked.push(String(event.target.value))
                            }else{
                                checked = checked.filter(ch => String(ch) !== String(event.target.value))
                            }
                            vm.$emit('change', checked)
                        }
                    }
                )
            }
        }
    }
</script>
