<template>
    <div class="clear-fields-container">
        <input type="hidden" v-if="addClearHiddenField" name="onclk_clear" value="clear"/>
        <a href="#" class="clear-fields" @click.prevent="handleClearSearch">
            <i class="si si-clear-all"></i>
            {{ $t('frm_clear_filters') }}
        </a>
    </div>
</template>

<script>
    export default {
        name: "ClearFilters",
        data() {
            return {
                addClearHiddenField: false,
            }
        },
        methods: {
            handleClearSearch() {
                this.addClearHiddenField = true;
                this.$nextTick(() => {
                    this.$parent.$refs['mobilesearch'].submit();
                });
            },
        }
    }
</script>
