<template>
    <div class="searchBox" @click="$emit('clickHandler')">
        <div>
            <span>{{title}}</span>
            <i class="si si-chevron-right search-overlay-arrow"></i>
        </div>
        <div  class="searchBoxContent" >
            {{ filteredContent }}
        </div>
    </div>
</template>

<script>
    export default {
        name: "SearchBoxMobile",
        props: {
            title: {type:String, default:''},
            selectedContent: {type:[String, Array, Object], default:''},
            datatype: {type:String, default:'String'},
        },
        computed: {
          filteredContent() {
              const texts = [];
              if (this.datatype === "array" ) {
                  this.selectedContent.forEach(content => texts.push(content.text));
                  return texts.join();
              } else if(this.datatype === "object") {
                  return this.selectedContent.text;
              } else {
                  return this.selectedContent;
              }
          }
        }
    }
</script>
