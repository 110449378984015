import Mutation from "@/components/SAWR/store/mutationTypes";

export default {
    setUsername: ({ commit }, username) => {
        commit(Mutation.SET_USERNAME, username)
    },
    setActiveToolbarTool: ({ commit }, tool) => {
        commit(Mutation.SET_ACTIVE_TOOLBAR_TOOL, tool)
    },
    setActiveFontSize: ({ commit }, fontSize) => {
        commit(Mutation.SET_ACTIVE_FONT_SIZE, fontSize)
    },
    setActiveMenuItem: ({ commit }, menuItem) => {
        commit(Mutation.SET_ACTIVE_MENU_ITEM, menuItem)
    },
    setActiveMarker: ({ commit }, marker) => {
        commit(Mutation.SET_ACTIVE_MARKER, marker)
    },
    addHighlight: ({ commit }, highlight) => {
        commit(Mutation.ADD_HIGHLIGHT, highlight)
    },
    removeHighlight: ({ commit }, highlight) => {
        commit(Mutation.REMOVE_COMMENT, { parentId: highlight.highlightId, type:'highlight' })
        commit(Mutation.REMOVE_HIGHLIGHT, highlight)
    },
    addBookmark: ({ commit }, bookmark) => {
        commit(Mutation.ADD_BOOKMARK, bookmark)
    },
    removeBookmark: ({ commit }, bookmark) => {
        commit(Mutation.REMOVE_BOOKMARK, bookmark)
    },
    addComment: ({ commit }, comment) => {
        commit(Mutation.ADD_COMMENT, comment)
        commit(Mutation.SET_SEARCH_COMMENT)
    },
    removeComment: ({ commit }, comment) => {
        commit(Mutation.REMOVE_COMMENT, comment)
    },
    setSearchComments: ({ commit }) => {
        commit(Mutation.SET_SEARCH_COMMENT)
    },
    setSearchCommentText: ({ commit }, searchText) => {
        commit(Mutation.SET_SEARCH_COMMENT_TEXT, searchText)
    },
    setSortCommentBy: ({ commit }, key) => {
        commit(Mutation.SET_SORT_COMMENT_KEY, key)
    },
    setMobileLeftPane: ({ commit }, payload) => {
        commit(Mutation.SET_MOBILE_LEFT_PANE, payload)
    },
    setHighlightableStatus: ({ commit }, payload) => {
        commit(Mutation.SET_HIGHLIGHTABLE_STATUS, payload)
    },
    setCommentToolsStatus: ({ commit }, payload) => {
        commit(Mutation.SET_COMMENT_TOOLS_STATUS, payload)
    },
    setBookmarkToolsStatus: ({ commit }, payload) => {
        commit(Mutation.SET_BOOKMARK_TOOLS_STATUS, payload)
    },
    setTableOfContents: ({ commit }, tableOfContents) => {
        commit(Mutation.SET_TABLE_OF_CONTENTS, tableOfContents)
    },
    setTOCSchema: ({ commit }, schema) => {
        commit(Mutation.SET_TOC_SCHEMA, schema)
    },
    setUrlParams: ({ commit }, params) => {
        commit(Mutation.SET_URL_PARAMS, params)
    },
    setSectionsContent: ({ commit }, content) => {
        commit(Mutation.SET_SECTIONS_CONTENT, content)
    },
    setSearchItems: ({ commit }, content) => {
        commit(Mutation.SET_SEARCH_ITEMS, content)
    },
    setSectionsContentItem: ({ commit, getters }, { sectionId, content }) => {
        let sectionsContent = getters.getAllSectionContent
        sectionsContent = JSON.parse(JSON.stringify(sectionsContent))
        const contentIndex = sectionsContent.findIndex(section => section.id === sectionId)
        sectionsContent[contentIndex].partialHtmlBody = content
        commit(Mutation.SET_SECTIONS_CONTENT, sectionsContent)
    },
    setSectionsContentAsText: ({ commit }, content) => {
        commit(Mutation.SET_SECTIONS_CONTENT_TEXT, content)
    },
    setSearchText: ({ commit }, content) => {
        commit(Mutation.SET_SEARCH_TEXT, content)
    }
}