import axios from '@/plugins/axios';

export const apiSearchResults = async (endPoint, params) => {
    try {
        let bodyFormData = new FormData();
        for (const [key, value] of Object.entries(params)) {
            bodyFormData.append(key, value);
        }
        const response = await axios.post({
            endpoint: '/management/services.v1.searchajax/'+ endPoint,
            params: bodyFormData,
            options: {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            }
        });
        if (response.data) {
            return response.data;
        } else  {
            return [];
        }
    } catch(e) {
        console.log('There has been a problem with your fetch operation: ' + e.message);
    }
};