<template>
    <div class="sai-radio-yellow">
        <span class="radio-box" v-for="option in options" :key="option[labelBy]">
            <input v-bind="$attrs"
                   type="radio"
                   :id="prefix +option[labelBy]"
                   v-on="inputListeners"
                   :name="name"
                   :checked="String(option[labelBy]) === String(value)"
                   :value="option[labelBy]">
            <label :for="prefix +option[labelBy]">{{option[trackBy]}}</label>
        </span>
    </div>
</template>

<script>
    export default {
        name: "SaiRadio",
        inheritAttrs: false,
        model: {
            prop : 'value',
            event: 'change'
        },
        props: {
            options : { type: Array,  default: () => [] },
            name : { type: String },
            value : { type: String },
            labelBy: {type:String, default: 'key' },
            trackBy: {type:String, default: 'val' },
            prefix: {type:String, default: '' },
        },
        computed: {
            inputListeners: function () {
                var vm = this;
                return Object.assign({},
                    // We add all the listeners from the parent
                    this.$listeners,
                    {
                        // This ensures that the component works with v-model
                        change: function (event) {
                            vm.$emit('change', String(event.target.value))
                        }
                    }
                )
            }
        }
    }
</script>
