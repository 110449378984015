import Vue from 'vue';
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

Vue.use(VueLoading, {
    canCancel: false,
    onCancel: () => {},
    color: '#FFBD35',
    loader: ['spinner', 'dots', 'bars'][1],
    width: 64,
    height: 64,
    backgroundColor: '#333333',
    opacity: 0.5,
    zIndex: 10003,
});



export default VueLoading;