<template>
    <SaiDropDown
        :value ="value"
        @change = "$emit('change', $event)"
        :name="name" 
        :labelBy="labelBy"
        :trackBy="trackBy"
        :options="options"
        :placeholder="placeholder" 
        :isLoading="isOptionsLoading"
        @open="loadOptions"/>
</template>
<script>
    import SaiDropDown from '@/components/common/SaiDropDown';
    import { apiGetFieldOptions } from '@/components/SearchBar/api';
    import { createNamespacedHelpers } from 'vuex';
    const { mapGetters, mapActions } = createNamespacedHelpers('searchbar');
    export default {
        name: "FilterDropDown",
        props: {
            name: { type: String,  default: '', required: true },
            value: { type: Object, required: true },
            placeholder: { type: String,  default: '', required: true },
            field: { type: String, default: '', required: true },
            labelBy: { type: String, default: '', required: true },
            trackBy: { type: String, default: '', required: true }
        },
        components: {
            SaiDropDown
        },
        data: function () {
            return {
                isOptionsLoading: false,
                isOptionsLoaded: false,
                options: [],
                lookupFieldName: '',
            }
        },
        mounted() {
            // Load options from store, if its already loaded.
            const options = this.getLookUpOptions(this.field);
            if(options && options.length) { 
                this.options = options;
                this.isOptionsLoaded = true;
            }
        },
        computed: {
            ...mapGetters([
                'getLookUpOptions'
            ])
        },
        methods: {
            ...mapActions([
                'setLookUpOptions'
            ]),
            async loadOptions() {
                if(!this.isOptionsLoaded) {
                    this.isOptionsLoading = true;
                    this.options = await apiGetFieldOptions(this.field);
                    this.setLookUpOptions({type: this.field, options: this.options});
                    this.isOptionsLoaded = true;
                    this.isOptionsLoading = false;
                }
            }
        }
    }
</script>
