import Mutation from '@/components/SearchBar/store/mutationTypes';
const handleCategoriesDependentDropDowns = store => {
    store.subscribe((mutation) => {
        if (mutation.type === ("searchbar/" + Mutation.SET_CATEGORIES_FILTER)) {
            const field = mutation.payload.field;
            //load the ics code level2 dropdown option on level 1 change
            if(field == 'descriptorField') {
                // dispatch with an object
                store.dispatch('searchbar/loadICSCodeOptions', { field: 'categories.descriptorGroup', icsCode: mutation.payload.payload });
                store.dispatch('searchbar/setItem', { field: 'categories.descriptorGroup', payload: { id: '', text: 'Group'} });
            }
            
            //load the ics code level3 dropdown option on level 2 change
            if(field == 'descriptorGroup') {
                // dispatch with an object
                store.dispatch('searchbar/loadICSCodeOptions', { field: 'categories.descriptorSubGroup', icsCode: mutation.payload.payload });
                store.dispatch('searchbar/setItem', { field: 'categories.descriptorSubGroup', payload: { id: '', text: 'Sub Group'}  });
            }

            //set the icscode text field value
            if(field == 'descriptorField' || field == 'descriptorGroup' || field == 'descriptorSubGroup') {
                const getItem = store.getters['searchbar/getItem'];
                const selectedDescriptorField = getItem('categories.descriptorField');
                const selectedDescriptorGroup = getItem('categories.descriptorGroup');
                const selectedDescriptorSubGroup = getItem('categories.descriptorSubGroup');
                if(selectedDescriptorSubGroup && selectedDescriptorSubGroup.id) {
                    store.dispatch('searchbar/setItem', { field: 'categories.icsCode', payload:  selectedDescriptorSubGroup.id });
                }else if(selectedDescriptorGroup && selectedDescriptorGroup.id) {
                    store.dispatch('searchbar/setItem', { field: 'categories.icsCode', payload:  selectedDescriptorGroup.id });
                } else if(selectedDescriptorField && selectedDescriptorField.id) {
                    store.dispatch('searchbar/setItem', { field: 'categories.icsCode', payload:  selectedDescriptorField.id });
                } else {
                    store.dispatch('searchbar/setItem', { field: 'categories.icsCode', payload: '' });
                }
            }
        }

       
    });
}

const handleInitialLoadingOfCategoriesOptions =store => {
    store.subscribe((mutation, state) => {
        if (mutation.type === ("searchbar/" + Mutation.SET_INITIALIZE_SEARCH_BAR_FILTERS)) {
            const payload = mutation.payload;
            if(payload.categories && payload.categories.descriptorField) {
                store.dispatch('searchbar/loadICSCodeOptions', { field: 'categories.descriptorGroup', icsCode: payload.categories.descriptorField });
            }
            if(payload.categories.descriptorGroup && payload.categories.descriptorGroup.id) {
                store.dispatch('searchbar/loadICSCodeOptions', { field: 'categories.descriptorSubGroup', icsCode: payload.categories.descriptorGroup });
            }
        }
    });
}


export default [
    handleCategoriesDependentDropDowns,
    handleInitialLoadingOfCategoriesOptions
];