<template>
    <span>
        {{arrayToString}}
    </span>
</template>

<script>
export default {
    name: "Tagging",
    props: {
        values: {type:Object,default:()=>[]},
        limit: {type:Number}
    },
    computed: {
        arrayToString() {
            const ValuesArray = this.values;
            if (ValuesArray.length > 0) {
                let content = [];
                ValuesArray.forEach(value => {
                    content.push(value.text);
                });
                if (content.length < this.limit) {
                    return content.join(',')
                } else {
                    return 'All selected';
                }
            } else {
                return ''
            }
        }
    }
}
</script>

<style scoped>

</style>