import Mutation from "@/components/SAWR/store/mutationTypes";

export default {
    [Mutation.SET_USERNAME]: (state, username) => {
        state['username'] = username;
    },
    [Mutation.SET_ACTIVE_TOOLBAR_TOOL]: (state, tool) => {
        state['toolbar'].activeTool = tool;
    },
    [Mutation.SET_ACTIVE_FONT_SIZE]: (state, fontSize) => {
        state['toolbar'].activeFontSize = fontSize;
    },
    [Mutation.SET_ACTIVE_MENU_ITEM]: (state, menuItem) => {
        state['leftPane'].activeMenuItem = menuItem;
    },
    [Mutation.SET_ACTIVE_MARKER]: (state, marker) => {
        state['leftPane'].activeMarker = marker;
    },
    [Mutation.ADD_BOOKMARK]: (state, bookmark) => {
        const index = state.bookmarks.findIndex(item => item.sectionId == bookmark.sectionId);
        if (index == -1) {
            state.bookmarks.push(bookmark);
        } else {
            state.bookmarks.splice(index, 1, bookmark);
        }
    },
    [Mutation.REMOVE_BOOKMARK]: (state, bookmark) => {
        const index = state.bookmarks.map(item => item.sectionId).indexOf(bookmark.sectionId);
        if (index != -1) {
            state.bookmarks.splice(index, 1);
        }
    },
    [Mutation.ADD_COMMENT]: (state, comment) => {
        const index = state.comments.findIndex(item => item.commentId == comment.commentId);
        // const searchCommentIndex = state.searchComments.findIndex(item => item.commentId == comment.commentId);
        if (index == -1) {
            state.comments.push(comment);
        } else {
            state.comments.splice(index, 1, comment);
        }
    },
    [Mutation.SET_SEARCH_COMMENT]: (state) => {
        let filteredComments = [];
        let sortedComments = [];
        state.comments.forEach(comment => {
            if (comment.content.includes(state.searchCommentText)) {
                filteredComments.push(comment);
            }
        });

        switch (state.sortCommentBy.key) {
            case 'OtoN':
                sortedComments = filteredComments.sort((a,b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime());
                break;
            case 'Auth':
                sortedComments = filteredComments.sort((a, b) => a.username.localeCompare(b.username));
                break;
            case 'NtoO':
            default:
                sortedComments = filteredComments.sort((a,b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime());
                break;
        }
        state.searchComments = sortedComments;
    },
    [Mutation.SET_SORT_COMMENT_KEY]: (state, key) => {
        state.sortCommentBy = key;
    },
    [Mutation.SET_SEARCH_COMMENT_TEXT]: (state, searchText) => {
        state.searchCommentText = searchText;
    },
    [Mutation.REMOVE_COMMENT]: (state, comment) => {
        let index, searchCommentIndex;

        if(comment.parentId && comment.type == 'highlight') {
            index = state.comments.map(item => item.parentId).indexOf(comment.parentId);
            searchCommentIndex = state.searchComments.map(item => item.parentId).indexOf(comment.parentId);
        } else {
            index = state.comments.map(item => item.commentId).indexOf(comment.commentId);
            searchCommentIndex = state.searchComments.map(item => item.commentId).indexOf(comment.commentId);
        }
        if (index >= 0) {
            state.comments.splice(index, 1);
        }
        if (searchCommentIndex >= 0) {
            state.searchComments.splice(searchCommentIndex, 1);
        }
    },
    [Mutation.ADD_HIGHLIGHT]: (state, highlight) => {
        const index = state.highlights.findIndex(item => item.highlightId == highlight.highlightId);
        if (index == -1) {
            state.highlights.push(highlight);
        } else {
            state.highlights.splice(index, 1, highlight);
        }
    },
    [Mutation.REMOVE_HIGHLIGHT]: (state, highlight) => {
        const index = state.highlights.map(item => item.highlightId).indexOf(highlight.highlightId);
        if (index != -1) {
            state.highlights.splice(index, 1);
        }
    },
    [Mutation.SET_MOBILE_LEFT_PANE]: (state, payload) => {
        state['leftPane'].mobileLeftPane = payload;
    },
    [Mutation.SET_HIGHLIGHTABLE_STATUS]: (state, payload) => {
        state.isHighlightableActive = payload;
    },
    [Mutation.SET_COMMENT_TOOLS_STATUS]: (state, payload) => {
        state.isCommentToolsActive = payload;
    },
    [Mutation.SET_BOOKMARK_TOOLS_STATUS]: (state, payload) => {
        state.isBookmarkToolsActive = payload;
    },
    [Mutation.SET_TABLE_OF_CONTENTS]: (state, tableOfContents) => {
        state.tableOfContents = tableOfContents;
    },
    [Mutation.SET_TOC_SCHEMA]: (state, schema) => {
        state.TOCSchema = schema
    },
    [Mutation.SET_URL_PARAMS]: (state, params) => {
        state.urlParams = params;
    },
    [Mutation.SET_SECTIONS_CONTENT]: (state, content) => {
        state.sectionsContent = content;
    },
    [Mutation.SET_SECTIONS_CONTENT_TEXT]: (state, content) => {
        state.sectionsContentText = content;
    },
    [Mutation.SET_SEARCH_ITEMS]: (state, content) => {
        state.searchItems = content;
    },
    [Mutation.SET_SEARCH_TEXT]: (state, content) => {
        state.searchText = content;
    }
}