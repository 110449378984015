<template>
        <div class="searchBoxList">
            <FilterBoxOptions :title="$t('frm_my_collections')" field="collections.collections"/>
            <input name="colls[]" type="hidden" v-for="element in getCollectionsFilter('collections')"
                   :key="element.id" :value="element.id" :disabled="!isActiveTab('collections')">

            <FilterBoxSelect :title="$t('frm_company_collections')" field="collections.companyCollection" :searchable="true"/>
            <input type="hidden" name="dcoll" :value="getCollectionsFilter('companyCollection').id" :disabled="!isActiveTab('collections')">

            <FilterBoxSelect :title="$t('frm_document_type')" field="collections.documentType"/>
            <input type="hidden" name="type" :value="getCollectionsFilter('documentType').id" :disabled="!isActiveTab('collections')">

            <FilterBoxOptions :title="$t('frm_current_label')" field="collections.status"
                              lookupField="standards.status" />
            <input name="include[]" type="hidden" v-for="element in getCollectionsFilter('status')"
                   :key="element.id" :value="element.id" :disabled="!isActiveTab('collections')">


            <FilterBoxOptions :title="$t('frm_tag')" field="collections.tags" :initialOptions="collectionTagInitOptions"/>
            <input name="tags[]" type="hidden" v-for="element in getCollectionsFilter('tags')"
                   :key="element.id" :value="element.id" :disabled="!isActiveTab('collections')">

            <FilterBoxInput :title="$t('frm_search_notes')" field="collections.notes"
                            :placeholder="$t('frm_search_notes')" inputName="notes" :disabled="!isActiveTab('collections')"/>

        </div>

</template>

<script>
    import { createNamespacedHelpers } from 'vuex';
    const searchBarStore = createNamespacedHelpers('searchbar');
    import FilterBoxInput from '@/components/SearchBar/SearchBarMobile/FilterBoxInput'
    import FilterBoxSelect from '@/components/SearchBar/SearchBarMobile/FilterBoxSelect'
    import FilterBoxOptions from '@/components/SearchBar/SearchBarMobile/FilterBoxOptions'

    export default {
        name: "AdvanceSearchCollection",
        components: {
            FilterBoxOptions,
            FilterBoxInput,
            FilterBoxSelect,
        },
        data() {
            return {
                collectionTagInitOptions: []
            }
        },
        mounted() {
            this.collectionTagInitOptions = this.getCollectionsFilter('tags');
        },
        computed: {
            ...searchBarStore.mapGetters([
                'getCollectionsFilter',
                'isActiveTab'
            ]),
        }
    }
</script>
