<template>
    <div class="recent-searches-container">{{ $t('recently_viewed') }}:
        <ul class="searchList">
            <li v-for="(docs, index) in getRecentlyViewedDocs" :key="index">
                <a :href="docs.link" :title="docs.name" class="linkname">{{ docs.name }}</a>
            </li>
        </ul>
    </div>
</template>

<script>
    import {createNamespacedHelpers} from 'vuex';
    const {mapGetters} = createNamespacedHelpers('searchbar');
    export default {
        name: "RecentlyViewed",
        computed: {
            ...mapGetters([
                'getRecentlyViewedDocs',
            ])
        }
    }
</script>
