<template>
    <div>
        <div class="search-results-summary">
            <div class="search-results-res">{{ getSummary }} {{ " " + $t('results') + " " }} {{ this.getSearchResultsField('summary') ? $t('for') + getSummaryText : '' }}</div>
        </div>
        <SearchResultsHeader/>
        <SearchResultsBody/>
    </div>
</template>

<script>
import {createNamespacedHelpers} from 'vuex';
import SearchResultsHeader from "./SearchResultsHeader";
import SearchResultsBody from "./SearchResultsBody";

const {mapGetters} = createNamespacedHelpers('searchresults');

export default {
    name: 'SearchResultsContainer',
    components: {
        SearchResultsHeader,
        SearchResultsBody,
    },
    computed: {
        ...mapGetters([
            'getSearchResultsField'
        ]),
        getSummary() {
            let summary = this.getSearchResultsField('total').toLocaleString();
            return summary;
        },
        getSummaryText() {
          let summary = '';
          if (this.getSearchResultsField('summary')) {
            summary = ' "' + this.getSearchResultsField('summary') + '"';
          }
          return summary;
        }
    }
}
</script>

