import axios from '@/plugins/axios';

export const apiGetContents = async (docId, resId, pdfId) => {
    try {
        const response = await axios.get({ endpoint: '/management/webreader/getContent/' + docId + '/' + resId + '/' + pdfId });
        if (response.data) {
            return response.data;
        } else {
            return [];
        }
    } catch (e) {
        console.log('There has been a problem with your fetch operation: ' + e.message);
    }
}

export const apiGetUser = async () => {
    return axios.post({ endpoint: '/management/webreader/user', params: {}, options: { headers: { 'X-Requested-With': 'XMLHttpRequest' } } })
        .then((response) => {
            if (response.status >= 200 && response.status <= 299) {
                return response.data;
            } else {
                throw Error(response.statusText);
            }
        }).catch(() => {
            window.location.href = '/management';
        });
}

export const apiGetBookmarks = async (docId, resId, pdfId) => {
    return axios.get({ endpoint: '/management/webreader/bookmarks/' + docId + '/' + pdfId + '/' + resId, params: {}, options: { headers: { 'X-Requested-With': 'XMLHttpRequest' } } })
        .then((response) => {
            if (response.status >= 200 && response.status <= 299) {
                return response.data;
            } else {
                throw Error(response.statusText);
            }
        }).catch(() => {
            console.log("Get bookmarks failed!");
        });
}

export const apiSaveBookmark = async (docId, resId, pdfId, sectionDetails) => {
    return axios.post({ endpoint: '/management/webreader/bookmarks/' + docId + '/' + pdfId + '/' + resId, params: sectionDetails, options: { headers: { 'X-Requested-With': 'XMLHttpRequest' } } })
        .then((response) => {
            if (response.status >= 200 && response.status <= 299) {
                return response.data;
            } else {
                throw Error(response.statusText);
            }
        }).catch(() => {
            console.log("Save bookmark failed!");
        });
}

export const apiDeleteBookmark = async (docId, resId, pdfId, page) => {
    return axios.delete({ endpoint: '/management/webreader/bookmarks/' + docId + '/' + pdfId + '/' + resId + '/' + page, options: { headers: { 'X-Requested-With': 'XMLHttpRequest' } } })
        .then((response) => {
            if (response.status >= 200 && response.status <= 299) {
                return response.data;
            } else {
                throw Error(response.statusText);
            }
        }).catch(() => {
            console.log("Delete bookmark failed");
        });
}

export const apiGetComments = async (docId, resId, pdfId) => {
    return axios.get({ endpoint: '/management/webreader/annotations/comment/' + docId + '/' + pdfId + '/' + resId, params: {}, options: { headers: { 'X-Requested-With': 'XMLHttpRequest' } } })
        .then((response) => {
            if (response.status >= 200 && response.status <= 299) {
                return response.data;
            } else {
                throw Error(response.statusText);
            }
        }).catch(() => {
            console.log("Get comments failed!");
        });
}

export const apiGetCommentReplies = async (docId, resId, pdfId, commentId) => {
    return axios.get({ endpoint: '/management/webreader/getCommentReplies/' + docId + '/' + pdfId + '/' + resId + '/' + commentId, params: {}, options: { headers: { 'X-Requested-With': 'XMLHttpRequest' } } })
        .then((response) => {
            if (response.status >= 200 && response.status <= 299) {
                return response.data;
            } else {
                throw Error(response.statusText);
            }
        }).catch(() => {
            console.log("Get comments failed!");
        });
}

export const apiSaveComment = async (docId, resId, pdfId, commentDetails) => {
    return axios.post({ endpoint: '/management/webreader/annotations/comment/' + docId + '/' + pdfId + '/' + resId, params: commentDetails, options: { headers: { 'X-Requested-With': 'XMLHttpRequest' } } })
        .then((response) => {
            if (response.status >= 200 && response.status <= 299) {
                return response.data;
            } else {
                throw Error(response.statusText);
            }
        }).catch(() => {
            console.log("Save comment failed!");
        });
}

export const apiSaveHighlight = async (docId, resId, pdfId, highlightDetails) => {
    return axios.post({ endpoint: '/management/webreader/annotations/highlight/' + docId + '/' + pdfId + '/' + resId, params: highlightDetails, options: { headers: { 'X-Requested-With': 'XMLHttpRequest' } } })
        .then((response) => {
            if (response.status >= 200 && response.status <= 299) {
                return response.data;
            } else {
                throw Error(response.statusText);
            }
        }).catch(() => {
            console.log("Save highlight failed!");
        });
}

export const apiGetHighlights = async (docId, resId, pdfId) => {
    return axios.get({ endpoint: '/management/webreader/annotations/highlight/' + docId + '/' + pdfId + '/' + resId, params: {}, options: { headers: { 'X-Requested-With': 'XMLHttpRequest' } } })
        .then((response) => {
            if (response.status >= 200 && response.status <= 299) {
                return response.data;
            } else {
                throw Error(response.statusText);
            }
        }).catch(() => {
            console.log("Get highlights failed!");
    });
}

export const apiDeleteAnnotation = async (docId, resId, pdfId, commentId) => {
    return axios.delete({ endpoint: '/management/webreader/annotations/comment/' + docId + '/' + pdfId + '/' + resId + '/' + commentId, options: { headers: { 'X-Requested-With': 'XMLHttpRequest' } } })
        .then((response) => {
            if (response.status >= 200 && response.status <= 299) {
                return response.data;
            } else {
                throw Error(response.statusText);
            }
        }).catch(() => {
            console.log("Delete comment failed");
        });
}

export const apiHeartBeat = async () => {
    return axios.get({ endpoint: '/management/webreader/heartbeat' })
        .then((response) => {
            if (response.status >= 200 && response.status <= 299) {
                return response.data;
            } else {
                return false;
            }
        })
}

export const apiSavePageView = async (docId, pdfId, pageNum, initialLoad) => {
    return axios.post({ endpoint: '/management/onlineviewer/savePageView/' + docId + '/' + pdfId + '/' + + pageNum + '/' + initialLoad, params: [], options: { headers: { 'X-Requested-With': 'XMLHttpRequest' } } })
        .then((response) => {
            if (response.status >= 200 && response.status <= 299) {
                return response.data;
            } else {
                console.log(response.statusText);
            }
        }).catch(() => {
        console.error("Save page view failed");
    });
}

