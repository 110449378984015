<template>
  <div class="search-filter-container" v-if="!isSpecialSearch">
    <SearchResultFacetsStandards v-if="isActiveSearchTab('search.standards')"/>
    <SearchResultFacetsCollections v-else-if="isActiveSearchTab('search.assets')"/>
  </div>
</template>

<script>
import SearchResultFacetsStandards from './Standards/SearchResultFacetsStandards';
import SearchResultFacetsCollections from './Collections/SearchResultFacetsCollections';
import { createNamespacedHelpers } from 'vuex';
const { mapGetters } = createNamespacedHelpers('searchresults');

export default {
  name: 'SearchResultFacets',
  components: {
    SearchResultFacetsStandards,
    SearchResultFacetsCollections,
  },
  computed: {
    ...mapGetters([
      'isActiveSearchTab',
      'isSpecialSearch'
    ])
  }
}
</script>

<style lang="scss">
  @import "SearchResultFacets";
</style>