<template>
    <div class="form-clear">
        <div class="clear-fields searchbar-clear-fields">
            <a href="#" id="clear-fields" class="deActive" @click.prevent="handleClearSearch">
                <i class="si si-clear-all-purple"></i>
                <span class="clear-filters-text">{{ $t('frm_clear_search') }}</span>
            </a>
            <div  class="sai-btn-purple" @click.prevent="handleSearch">
              {{ $t('frm_generic') }}
                <Loading :active="getLoadingStatus"
                         :is-full-page="false" loader="dots" color='#202020' :width="20" :height="20"
                         :can-cancel="false"
                         backgroundColor="#ffffff"
                         :opacity="0.8"
                         :z-index="999"
                >
                    <template v-slot:default>
                        <div class="loadingoverlay_element"
                             style="order: 1; box-sizing: border-box; overflow: visible; flex: 0 0 auto; display: flex; justify-content: center; align-items: center; animation-name: loadingoverlay_animation__rotate_right; animation-duration: 2000ms; animation-timing-function: linear; animation-iteration-count: infinite; width: 20.5px; height: 20.5px;">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000"
                                 style="width: 100%; height: 100%; fill: rgb(32, 32, 32);">
                                <circle r="80" cx="500" cy="90" style="fill: rgb(32, 32, 32);"></circle>
                                <circle r="80" cx="500" cy="910" style="fill: rgb(32, 32, 32);"></circle>
                                <circle r="80" cx="90" cy="500" style="fill: rgb(32, 32, 32);"></circle>
                                <circle r="80" cx="910" cy="500" style="fill: rgb(32, 32, 32);"></circle>
                                <circle r="80" cx="212" cy="212" style="fill: rgb(32, 32, 32);"></circle>
                                <circle r="80" cx="788" cy="212" style="fill: rgb(32, 32, 32);"></circle>
                                <circle r="80" cx="212" cy="788" style="fill: rgb(32, 32, 32);"></circle>
                                <circle r="80" cx="788" cy="788" style="fill: rgb(32, 32, 32);"></circle>
                            </svg>
                        </div>
                    </template>
                </Loading>
            </div>

        </div>
    </div>
</template>
<script>
import Loading from 'vue-loading-overlay';
import {createNamespacedHelpers} from 'vuex';

const {mapGetters, mapActions} = createNamespacedHelpers('searchbar')
const {
    mapActions: searchResutlsMapActions,
    mapGetters: searchResutlsMapGetters
} = createNamespacedHelpers('searchresults')

export default {
    name: "SectionCTA",
    data() {
        return {
            addClearHiddenField: false,
            isLoading: false,
        }
    },
    components: {
        Loading
    },
    computed: {
        ...mapGetters([
            'isActiveTab',
            'getActiveSearchTab',
            'getItem'
        ]),
        ...searchResutlsMapGetters([
            'getLoadingStatus'
        ])
    },
    methods: {
        ...mapActions([
            'clearSearchFields'
        ]),
        ...searchResutlsMapActions([
            'performMainSearch'
        ]),
        handleClearSearch() {
            this.clearSearchFields(this.getActiveSearchTab);
        },
        handleSearch() {
            this.performMainSearch();
        }
    }
}
</script>
<style lang="scss" scoped>
.searchbar-clear-fields {
    width: 100%;

    span.clear-filters-text {
        margin-left: 3px;
    }
}
</style>