<template>
    <div :class="['sai-accordion-primary', isOpenAccordion ? 'active' : '' , className]">
        <div class="accordion-header">
            <div class="accordion-header-drag">
                <span v-if="!fixed"><i class="icon si si-drag"></i></span>
            </div>
            <div class="accordion-header-title" @click="headerClickHandler">
                <span class="header-icon"><i :class="headerIcon"></i></span>
                <span class="header-title">{{ headerTitle }}</span>
            </div>
            <div class="accordion-header-actions" v-if="hasViewMode">
                <label class="label">{{  (settings['viewMode']) ? $t('home_detailedView') : $t('home_compactView') }}</label>
                <input type="checkbox" @change="viewModeHandler" id="modeSwitch" name="status" class="sai-switch-purple" :checked="settings['viewMode']">
                <label for="modeSwitch"></label>
            </div>
            <div :class="['accordion-header-arrow', isOpenAccordion ? 'active' : '']" @click="accordionOpenHandler"></div>
        </div>
        <div :class="['accordion-body' , !isOpenAccordion ?  'collapsed' : '']">
            <slot name="accordion-content"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "SaiAccordion",
    props: {
        fixed: {type: Boolean, default: true},
        settings: {type: Object, default: () => {}},
        open: {type: Boolean, default: false},
        headerTitle: {type: String, default: '',},
        className: {type: String, default: ''},
        headerIcon: {type: String, default: ''},
        id: {type: String, default: ''}
    },
    data() {
        return {
            isOpenAccordion: this.open,
            hasViewMode: this.settings
        }
    },
    methods: {
        viewModeHandler($event) {
            this.$emit('viewModeHandler', {value:$event.target.checked, accordion: this.id})
        },
        accordionOpenHandler() {
            this.isOpenAccordion = !this.isOpenAccordion;
            this.$emit('accordionOpenHandler',{value:this.isOpenAccordion, accordion: this.id})
        },
        headerClickHandler() {
            this.$emit('headerClickHandler')
        }
    }
}
</script>