export default {
    SET_USERNAME: 'SET_USERNAME',
    SET_ACTIVE_TOOLBAR_TOOL: 'SET_ACTIVE_TOOLBAR_TOOL',
    SET_ACTIVE_FONT_SIZE: 'SET_ACTIVE_FONT_SIZE',
    SET_ACTIVE_MENU_ITEM: 'SET_ACTIVE_MENU_ITEM',
    SET_ACTIVE_MARKER: 'SET_ACTIVE_MARKER',
    ADD_BOOKMARK: 'ADD_BOOKMARK',
    REMOVE_BOOKMARK: 'REMOVE_BOOKMARK',
    ADD_COMMENT: 'ADD_COMMENT',
    REMOVE_COMMENT: 'REMOVE_COMMENT',
    ADD_HIGHLIGHT: 'ADD_HIGHLIGHT',
    REMOVE_HIGHLIGHT: 'REMOVE_HIGHLIGHT',
    SET_SEARCH_COMMENT: 'SET_SEARCH_COMMENT',
    SET_SEARCH_COMMENT_TEXT: 'SET_SEARCH_COMMENT_TEXT',
    SET_SORT_COMMENT_KEY: 'SET_SORT_COMMENT_KEY',
    SET_MOBILE_LEFT_PANE: 'SET_MOBILE_LEFT_PANE',
    SET_HIGHLIGHTABLE_STATUS: 'SET_HIGHLIGHTABLE_STATUS',
    SET_COMMENT_TOOLS_STATUS: 'SET_COMMENT_TOOLS_STATUS',
    SET_BOOKMARK_TOOLS_STATUS: 'SET_BOOKMARK_TOOLS_STATUS',
    SET_TABLE_OF_CONTENTS: 'SET_TABLE_OF_CONTENTS',
    SET_TOC_SCHEMA: 'SET_TOC_SCHEMA',
    SET_URL_PARAMS: 'SET_URL_PARAMS',
    SET_SECTIONS_CONTENT: 'SET_SECTIONS_CONTENT',
    SET_SECTIONS_CONTENT_TEXT: 'SET_SECTIONS_CONTENT_TEXT',
    SET_SEARCH_ITEMS: 'SET_SEARCH_ITEMS',
    SET_SEARCH_TEXT: 'SET_SEARCH_TEXT'
}