<template>
    <div class="quickSearchBox">
        <div v-show="getCategoriesFilter('isDescriptorFilter')">
            <FilterMultiSelect :placeholder="$t('frm_ics_code_level1')" name="ics_level1" field="categories.descriptorField"  :searchable="false" :hasClear="false" class="noHeader"/>
            <input type="hidden" name="ics_level1" :value="getCategoriesFilter('descriptorField').id" :disabled="!isActiveTab('categories')">
            <SaiMultiSelect
                    :disabled="disableDropDown('categories.descriptorGroup')"
                    :placeholder="$t('frm_ics_code_level2')"
                    class='purple-theme'
                    :value="getCategoriesFilter('descriptorGroup')"
                    @change="$event => setCategoriesFilter({  field: 'descriptorGroup', payload: $event })"
                    :options="lookUpForGroup()"
                    labelBy="text"
                    trackBy="id"
                    name="ics_level2"
                    :searchable="false"/>
            <input type="hidden" name="ics_level2" :value="getCategoriesFilter('descriptorGroup').id" :disabled="!isActiveTab('categories')">
            <SaiMultiSelect
                    :disabled="disableDropDown('categories.descriptorSubGroup')"
                    :placeholder="$t('frm_ics_code_level3')"
                    class='purple-theme'
                    :value ="getCategoriesFilter('descriptorSubGroup')"
                    @change ="$event => setCategoriesFilter({  field: 'descriptorSubGroup', payload: $event })"
                    :options="lookUpForSubGroup()"
                    labelBy ="text"
                    trackBy="id"
                    name="ics_level3"
                    :searchable="false"/>
            <input type="hidden" name="ics_level3" :value="getCategoriesFilter('descriptorSubGroup').id" :disabled="!isActiveTab('categories')">
        </div>


        <div v-show="!getCategoriesFilter('isDescriptorFilter')">
            <SaiInput type="text" class="mobile-search-input"
                      :placeholder="$t('frm_enter_ics_code')" name="query" id="query"
                      :value="getCategoriesFilter('icsCode')"
                      @input="$event => setCategoriesFilter({field: 'icsCode', payload: $event})"
                      autocomplete="off" :disabled="!isActiveTab('categories')"/>
        </div>

        <div class="icsSelection-container">
            <span class="icsSection ics-code-option" style="display: block;" v-if="getCategoriesFilter('isDescriptorFilter')" @click="setCategoriesFilter({field: 'isDescriptorFilter', payload: false})">{{ $t('enter_ics_code') }}</span>
            <span class="icsSection ics-code-option" style="display: block;" v-if="!getCategoriesFilter('isDescriptorFilter')"  @click="setCategoriesFilter({field: 'isDescriptorFilter', payload: true})">Select descriptors</span>
            <input v-if="getCategoriesFilter('isDescriptorFilter')" type="hidden" name="ics_code_descriptor_filter" value="1"/>
            <input v-if="!getCategoriesFilter('isDescriptorFilter')" type="hidden" name="ics_code_descriptor_filter" value="0"/>
        </div>

    </div>
</template>

<script>
    import FilterMultiSelect from '@/components/SearchBar/SearchBarDesktop/FilterMultiSelect';
    import SaiMultiSelect from '@/components/common/SaiMultiSelect';
    import SaiInput from '@/components/common/SaiInput';
    import { createNamespacedHelpers } from 'vuex';
    const { mapGetters, mapActions } = createNamespacedHelpers('searchbar');
    export default {
        name: "QuickSearchCategories",
        components: {
            SaiInput,
            FilterMultiSelect,
            SaiMultiSelect
        },
        computed: {
            ...mapGetters([
                'getCategoriesFilter',
                'getLookUpOptions',
                'isActiveTab'
            ])
        },
        methods: {
            ...mapActions([
                'setCategoriesFilter'
            ]),
            disableDropDown(field)  {
                const options = this.getLookUpOptions(field);
                return !options.length;
            },
            lookUpForGroup() {
                const loadedOpptons = this.getLookUpOptions('categories.descriptorGroup')
                return [
                    { id: '', text: 'Group' },
                    ...loadedOpptons
                ];
            },
            lookUpForSubGroup() {
                const loadedOpptons = this.getLookUpOptions('categories.descriptorSubGroup')
                return [
                    { id: '', text: 'Sub Group' },
                    ...loadedOpptons
                ];
            }
        }
    }
</script>
<style lang="scss" scoped>
    @import "./categories";
</style>