<template>
        <div class="searchBoxList">
            <FilterBoxOptions :title="$t('frm_chemical_analysis')" field="materials.chemicalAnalysis"/>
            <input name="material_chemical_analysis[]" type="hidden" v-for="element in getMaterialsFilter('chemicalAnalysis')"
                   :key="element.id" :value="element.id" :disabled="!isActiveTab('materials')">

            <FilterBoxOptions :title="$t('frm_formulations')" field="materials.formulation"/>
            <input name="material_formulation[]" type="hidden" v-for="element in getMaterialsFilter('formulation')"
                   :key="element.id" :value="element.id" :disabled="!isActiveTab('materials')">

            <FilterBoxOptions :title="$t('frm_special_chars')" field="materials.specialCharacteristics"/>
            <input name="material_special_char[]" type="hidden" v-for="element in getMaterialsFilter('specialCharacteristics')"
                   :key="element.id" :value="element.id" :disabled="!isActiveTab('materials')">

            <FilterBoxOptions :title="$t('frm_chemical_resistance')" field="materials.chemicalResistance"/>
            <input name="material_chemical_resistance[]" type="hidden" v-for="element in getMaterialsFilter('chemicalResistance')"
                   :key="element.id" :value="element.id" :disabled="!isActiveTab('materials')">

            <FilterBoxOptions :title="$t('frm_processing')" field="materials.processing"/>
            <input name="material_processing[]" type="hidden" v-for="element in getMaterialsFilter('processing')"
                   :key="element.id" :value="element.id" :disabled="!isActiveTab('materials')">

        </div>

</template>

<script>
    import {createNamespacedHelpers} from 'vuex';
    const searchBarStore = createNamespacedHelpers('searchbar');
    import FilterBoxOptions from '@/components/SearchBar/SearchBarMobile/FilterBoxOptions'

     export default {
        name: "AdvanceSearchMaterial",
        components: {
            FilterBoxOptions,
        },
        computed: {
            ...searchBarStore.mapGetters([
                'getMaterialsFilter',
                'isActiveTab'
            ]),
        },

    }
</script>
