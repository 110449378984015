<template>
   <div class="form-row section advanced-standards " style="">
        <div class="form-group col-sm-2 col-md-4 col-lg-4 publisherSearch">
            <FilterMultiSelect :placeholder="$t('fld_publisher')" name="publisher" field="standards.publisher"/>
            <input type="hidden" name="publisher" :value="getStandardsFilter('publisher').id"  :disabled="isAdvanceFilterDisabled('standards')"/>
        </div>
        <div class="form-group col-sm-2 col-md-4 col-lg-4">
            <FilterMultiSelect :placeholder="$t('frm_current_label')" name="status" :multiple="true" field="standards.status" :hasClear="false" :searchable="false" :tagging="true"/>
            <input v-for="(status, index) in getStandardsFilter('status')" :key="index" type="hidden" name="include[]" :value="status.id"  :disabled="isAdvanceFilterDisabled('standards')"/>
            
        </div>
        <div class="form-group col-sm-2 col-md-4 col-lg-4 countrySearch">
            <FilterMultiSelect :placeholder="$t('fld_country')" name="country" field="standards.country"/>
            <input type="hidden" name="country" :value="getStandardsFilter('country').id"  :disabled="isAdvanceFilterDisabled('standards')"/>
        </div>
        <div class="form-group col-sm-2 col-md-4 col-lg-4">
            <FilterMultiSelect :placeholder="$t('fld_dataset')" name="dataset" field="standards.dataset" :hasClear="false" :searchable="false"/>
            <input type="hidden" name="dataset" :value="getStandardsFilter('dataset').id" :disabled="isAdvanceFilterDisabled('standards')" />
        </div>
        <div class="form-group col-sm-4 col-md-8 col-lg-8 search-within">
            <SaiAutoSuggest labelBy="value" trackBy="label"
                           className="document-input search-box standardSection form-control form-control-select shadow-none form-control-dropdown form2row form-control-text-second-row standardNotes"
                           api="/management/search/xref_name_suggest"
                           :apiExtraParams="{subId: getSubId, type: 'name'}"
                           apiHeaderContentType="form-urlencoded"
                           :placeholder="$t('frm_search_within')"
                           name="query_5" id="query_5"
                           :value="getStandardsFilter('crossReference')"
                           @input="$event => setStandardsFilter({field: 'crossReference', payload: $event})"
                           @addSuggestValue="handleDocumentNumberClick" :disabled="isAdvanceFilterDisabled('standards')"
                           @keyup.enter="performMainSearch"/>
         </div>
    </div>
</template>
<script>
    import FilterMultiSelect from '@/components/SearchBar/SearchBarDesktop/FilterMultiSelect';
    import SaiAutoSuggest from '@/components/common/SaiAutoSuggest';
    import { createNamespacedHelpers } from 'vuex';
    const { mapGetters, mapActions } = createNamespacedHelpers('searchbar');
    const { mapActions: searchResultActions } = createNamespacedHelpers('searchresults');
    
    export default {
        name: "AdvanceSearchStandard",
        components: {
            FilterMultiSelect,
            SaiAutoSuggest,
        },
        computed: {
            ...mapGetters([
                'getSubId',
                'getStandardsFilter',
                'isAdvanceFilterDisabled'
            ]),
        },
        methods: {
            ...mapActions([
                'setStandardsFilter'
            ]),
            ...searchResultActions([
                'performMainSearch',
                'setLoadingStatus'
            ]),
            handleDocumentNumberClick($event) {
                this.setStandardsFilter({field: 'crossReference', payload: $event});
                //submit search on document number selection from the auto suggested list
                this.performMainSearch();
                this.setLoadingStatus(true);
            },
        }
    }
</script>
