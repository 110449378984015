<template>
    <div class="search-progress" :progress="progressStatus">
        <div :class="[progressStatus == 'complete' ? 'search-progress-hidden' : '',  'search-progress-wrapper']">
            <div :class="[progressStatus == 'complete' ? 'search-progress-bar-zeroPer' : '',  'search-progress-bar']"
              :style="{ transform: 'translateX(' + loadedPercentage + '%)' }"></div>
        </div>
    </div>
</template>
<script>
    export default {
        name: "SearchTabs",
        props: {
            state: { type: String, default: 'hidden', required: true }
        },
        data: function () {
            return {
                loadedPercentage: 0,
                intervalHandler: null,
                progressStatus: ''
            }
        },
        destroyed: function() {
            clearInterval(this.intervalHandler);
        },
        methods: {
            startProgress() {
                const self = this;
                self.loadedPercentage = 12.5;
                self.progressStatus ='start';
                self.intervalHandler = setInterval(function() {  
                    self.loadedPercentage = self.loadedPercentage + 12.5;
                    if( self.loadedPercentage == 100) {
                        clearInterval(self.intervalHandler);
                        setTimeout(function(){
                            self.progressStatus = 'complete';
                            self.loadedPercentage = 0;
                        }, 1000);
                    }
                }, 2000);
            },
            completeProgress() {
                const self = this;
                this.loadedPercentage = 100;
                clearInterval(this.intervalHandler);
                setTimeout(function(){
                    self.progressStatus = 'complete';
                    self.loadedPercentage = 0;
                }, 1000);
            }
        },
        watch: {
            state:function(newVal) {
                if(newVal == 'start') {
                   this.startProgress();
                }
                if(newVal == 'complete') {
                    this.completeProgress();
                }
            },
        },
    }
</script>
<style lang="scss" scoped>
    .search-progress {
        position: relative;
        margin-top: -3px;
        height: 3px;
        .search-progress-wrapper {
            bottom: 0;
            margin-bottom: 0;
            position: absolute;
            left: 0;
            width: 100%;
            opacity: 1;
            visibility: visible;
            transition: opacity .4s ease,visibility .4s ease;
            overflow: hidden;
            height: 3px;
            &.search-progress-hidden {
                opacity: 0;
            }
            .search-progress-bar {
                background: linear-gradient(135deg,#ffbd35,#f1ab1a);
                position: absolute;
                width: 100%;
                top: 0;
                bottom: 0;
                left: -100%;
                transition: transform .4s ease;
                &.search-progress-bar-zeroPer {
                    transition: transform 0s ease;
                }
            }
        }
    }
</style>
