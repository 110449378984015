<template>
    <fragment>
        <SearchResultFacets/>
        <SearchResultsContainer :class="['results-container', isSpecialSearch ? 'results-container-expanded' : '']"/>
    </fragment>
</template>

<script>
import SearchResultFacets from '@/components/SearchResultFacets';
import SearchResultsContainer from '@/components/SearchResults/Collections/SearchResultsContainer'
import { createNamespacedHelpers } from 'vuex';
const { mapGetters } = createNamespacedHelpers('searchresults');
export default {
    name: "index",
    components: {
        SearchResultFacets,
        SearchResultsContainer,
    },
    computed: {
        ...mapGetters([
            'isSpecialSearch'
        ])
    }
}
</script>

<style scoped>

</style>