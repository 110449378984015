import Vue from 'vue'

const SEARCH_HITS_MAX = 5
export default class ContentSearch extends Vue {

    constructor() {
        super();
        this.markTag = "mark";
        this.indexId = 0;
        this.searchTerm = "";
        this.sectionSearchResultsLocal = []
    }

    setSearchTerm(value) {
        this.searchTerm = value;
    }

    addTags(el, $, sectionSearchResults, sectionId) {
        if (el.type === "tag") {
            this.searchParents(el, $, sectionSearchResults, sectionId);
        } else if (el) {
            // Process text node only.
            //get the regular expression match
            const nodeText = el.data;
            const nbsp = /(\u00A0)/g;
            const contentWithoutNbsp = nodeText.replace(nbsp, " ");
            const regExp = this.MatchRegex().exec(contentWithoutNbsp);
            if (regExp === null) return;

            const resultId = "search-id-" + this.indexId;
            this.indexId++;
            const matchingText = regExp[0];
            const index = regExp.index;
            const textBefore = nodeText.substring(0, index) || "";
            const textAfter = nodeText.substring(index + regExp[0].length) || "";
            const maxHits = SEARCH_HITS_MAX;

            const updatedContent =
                textBefore +
                "<" +
                this.markTag +
                ' id="' +
                resultId +
                '">' +
                matchingText +
                "</" +
                this.markTag +
                ">" +
                textAfter;

            // prettier-ignore
            const wordsBefore = textBefore && textBefore.length ? textBefore.split(/\s+/g).filter((e) => e != "") : [];
            const wordsAfter = textAfter && textAfter.length ? textAfter.split(/\s+/g).filter((e) => e != "") : [];
            const beforePrefix = regExp[1] !== "" ? " " + regExp[1] : regExp[1];

            const beforeText = wordsBefore.slice(Math.max(wordsBefore.length - maxHits + 1, 0)).join(" ") + " " + beforePrefix
            const phraseText = regExp[0].slice(regExp[1].length, regExp[0].length - regExp[2].length)
            const afterText = regExp[2] + " " + wordsAfter.slice(0, maxHits + 1).join(" ")
            //add results
            let results = {
                id: resultId,
                sectionId,
                //get the LAST x words from before array and append the 2nd group of the regular expression
                before: beforeText,
                //extract the phrase from the regular expression match
                phrase: phraseText,
                //get the FIRST x words from the after array and prefix the 3rd group of the regular expression
                after: afterText,
                text: beforeText + "<mark>" + phraseText + "</mark>" + afterText
            }
            sectionSearchResults.push(results);
            this.sectionSearchResultsLocal.push(results);
            // insert content after current element, then remove current element. (Won't remove siblings, will only remove current elemenet)
            $(el).replaceWith(updatedContent);
            let nextSibling = el.next?.next;

            //as we create and split the node to insert the mark tag
            //we need to check that there's another sibling AFTER the newly inserted mark i.e. the continued data
            if (nextSibling) {
                this.addTags(nextSibling, $, sectionSearchResults, sectionId);
            }
        }
    }

    /**
     * Recursively mark full content of tag, including all children
     *
     * @param props
     */
    searchParents(parentEl, $, sectionSearchResults, sectionId) {
        if (!this.MatchRegex === null) return;

        const parentElContentsArr = $(parentEl).contents();
        if (!parentElContentsArr || !parentElContentsArr.length) {
            return;
        }

        parentElContentsArr.map((index, el) => {
            this.addTags(el, $, sectionSearchResults, sectionId);
        });
    }

    MatchRegex() {
        return RegExp("(\\S*)" + this.searchTerm + "(\\S*)", "mi");
    }
}