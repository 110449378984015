<template>
    <div class="latest-quicksearch">
        <FilterMultiSelect :placeholder="$t('frm_document_status')" name="what" field="latest.what"  :searchable="false" :hasClear="false"/>
        <input type="hidden" name="what" :value="getLatestFilter('what').id"  :disabled="!isActiveTab('latest')"/>
        <FilterMultiSelect :placeholder="$t('fld_publisher')" name="publisher" field="latest.publisher" lookupField="standards.publisher"/>
        <input type="hidden" name="publisher" :value="getLatestFilter('publisher').id"  :disabled="!isActiveTab('latest')"/>
        <FilterMultiSelect :placeholder="$t('frm_when')" name="when" field="latest.when"  :searchable="false" :hasClear="false"/>
        <input type="hidden" name="when" :value="getLatestFilter('when').id"  :disabled="!isActiveTab('latest')"/>
        <FilterMultiSelect :placeholder="$t('fld_dataset')" name="dataset" field="latest.dataset" lookupField="standards.dataset" :searchable="false" :hasClear="false"/>
        <input type="hidden" name="dataset" :value="getLatestFilter('dataset').id"  :disabled="!isActiveTab('latest')"/>
    </div>
</template>
<script>
    import FilterMultiSelect from '@/components/SearchBar/SearchBarDesktop/FilterMultiSelect';
    import { createNamespacedHelpers } from 'vuex';
    const { mapGetters } = createNamespacedHelpers('searchbar');
    export default {
        name: "QuickSearchLatest",
        components: {
            FilterMultiSelect
        },
        computed: {
            ...mapGetters([
                'getLatestFilter',
                'isActiveTab'
            ])
        }
    }
</script>

<style lang="scss">
    .latest-quicksearch {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .multiselect {
            width: 24.25%;
            margin-bottom: .6em;
        }
    }
</style>