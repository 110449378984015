<template>
    <div class="quickSearchBox">
        <SaiInput type="text" class="mobile-search-input"
                  name="material_trade_name"
                  :value="getMaterialsFilter('tradeName')"
                  @input="$event => setMaterialsFilter({field: 'tradeName', payload: $event})"
                  :placeholder="$t('frm_trade_name')" :disabled="!isActiveTab('materials')"/>

        <FilterMultiSelect :searchable="true"
                           :hasClear="true"
                           :placeholder="$t('frm_description')"
                           name="material_description"
                           field="materials.description"/>
        <input type="hidden" name="material_description" :value="getMaterialsFilter('description').id" :disabled="!isActiveTab('materials')">

        <FilterMultiSelect name="material_class"
                           :hasClear="true"
                           :searchable="true"
                           :placeholder="$t('frm_class')"
                           field="materials.class"/>
        <input type="hidden" name="material_class" :value="getMaterialsFilter('class').id" :disabled="!isActiveTab('materials')">

        <FilterMultiSelect :multiple="true"
                           name="material_form[]"
                           :placeholder="$t('frm_form')"
                           field="materials.form"/>
        <input name="material_form[]" type="hidden" v-for="element in getMaterialsFilter('form')"
               :key="element.id" :value="element.id" :disabled="!isActiveTab('materials')">

        <FilterMultiSelect name="material_product_type"
                           :hasClear="true"
                           :searchable="true"
                           :placeholder="$t('frm_material_type')"
                           field="materials.productType"/>
        <input type="hidden" name="material_product_type" :value="getMaterialsFilter('productType').id" :disabled="!isActiveTab('materials')">

        <FilterMultiSelect :multiple="true"
                           name="material_additive[]"
                           :placeholder="$t('frm_additives')"
                           field="materials.additives"/>
        <input name="material_additive[]" type="hidden" v-for="element in getMaterialsFilter('additives')"
               :key="element.id" :value="element.id" :disabled="!isActiveTab('materials')">


        <SaiInput type="text" class="mobile-search-input"
                  name="material_keyword"
                  :value="getMaterialsFilter('keyword')"
                  @input="$event => setMaterialsFilter({field: 'keyword', payload: $event})"
                  :placeholder="$t('frm_material_keyword')" :disabled="!isActiveTab('materials')"/>
    </div>
</template>

<script>
    import FilterMultiSelect from '@/components/SearchBar/SearchBarDesktop/FilterMultiSelect';
    import SaiInput from '@/components/common/SaiInput';
    import { createNamespacedHelpers } from 'vuex';
    const { mapGetters, mapActions } = createNamespacedHelpers('searchbar');
    export default {
        name: "QuickSearchMaterial",
        components: {
            SaiInput,
            FilterMultiSelect
        },
        computed: {
            ...mapGetters([
                'getMaterialsFilter',
                'isActiveTab'
            ]),
        },
        methods: {
            ...mapActions([
                'setMaterialsFilter'
            ])
        }
    }
</script>
