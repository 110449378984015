<template>
    <input :class="className"
        v-bind="$attrs"
        :value="value"
        v-on="inputListeners"
    />
</template>

<script>
export default {
  name: "sai-input",
  inheritAttrs: false,
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    value    : [String, Array],
    className: String
  },
  computed: {
    inputListeners: function () {
      var vm = this;
      return Object.assign({},
        // We add all the listeners from the parent
        this.$listeners,
        {
          // This ensures that the component works with v-model
          input: function (event) {
            vm.$emit('input', event.target.value)
          }
        }
      )
    }
  }
};
</script>

