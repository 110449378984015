<template>
    <div class="filterInputFacet">
      <SaiAutoSuggest labelBy="value" trackBy="label"
                      className="document-input search-box standardSection form-control form-control-select shadow-none form-control-dropdown form2row form-control-text-second-row standardNotes"
                      :api="api"
                      :apiExtraParams="{subId: getSubId, type: paramType}"
                      apiHeaderContentType="form-urlencoded"
                      :placeholder="placeholder"
                      :name="name" :id="id"
                      :value="getSearchResultsFilterValue(field)"
                      @input="$event => setSearchResultsFilterSelectedValues({field: field, fieldType:'input', payload: $event})"
                      @addSuggestValue="handleDocumentNumberClick"
      />
    </div>
</template>

<script>
    import {createNamespacedHelpers} from 'vuex';
    const { mapGetters, mapActions } = createNamespacedHelpers('searchresults');
    const { mapGetters: mapSearchBarGetters } = createNamespacedHelpers('searchbar');
    import SaiAutoSuggest from '@/components/common/SaiAutoSuggest';
    export default {
        name: "AccordionFilterInputSuggest",
        components: {
            SaiAutoSuggest
        },
        props: {
            placeholder:{type:String, default:''},
            name:{type:String, default:''},
            id:{type:String, default:''},
            field: { type: String, default: '', required: true },
            searchTab: { type: String, default: 'standards', required: true },
            paramType: { type: String, default: 'name', required: true },
            api: { type: String, default: '/api/search/suggest.php' }
        },
        computed: {
            ...mapSearchBarGetters([
                'getSubId'
            ]),
            ...mapGetters([
              'getSearchResultsFilterValue',
            ]),
        }, 
        methods: {
          ...mapActions([
                'setSearchResultsFilterSelectedValues',
                'setCurrentPage',
                'fetchItems'
              ]),
            handleDocumentNumberClick($event) {
              this.setSearchResultsFilterSelectedValues({field: this.field, fieldType:'input', payload: $event});
              window.scrollTo(0, 0);
              this.setCurrentPage(1);
              this.fetchItems(this.searchTab);
            },
        }
    }
</script>
