<template>
    <transition name="toggleSearchBarPwa">
        <div v-show="isSearchOpen"  v-bind:class="{ show: isSearchOpen }" >
            <form method="post" action="/management/search/index" name="quickSearchForm" id="desktopsearch" class="form" ref="desktopsearch">
                <div>
                    <div class="container-fluid search-container ">
                        <div class="container">
                            <div class="close-searchBar-container">
                                <div class="close-search-bar" @click="toggleSearchBar">
                                    <span>
                                        <i class="si si-cross"></i>
                                    </span>
                                </div>
                            </div>
                            <QuickSearch/>
                            <RecentlyViewed/>
                        </div>
                    </div>
                    <div class="advanced-row-container">
                        <AdvanceSearch/>
                        <SearchBarProgress  v-if="isActiveTab('standards') || isActiveTab('collections')" :state="getSearchProgressStatus"/>
                    </div>
                </div>
            </form>
        </div>
    </transition>
</template>

<script>
    import { createNamespacedHelpers } from 'vuex';
    const { mapGetters,mapActions } = createNamespacedHelpers('searchbar');
    const { mapGetters: searchResultsGetters } = createNamespacedHelpers('searchresults');

    import AdvanceSearch from './AdvanceSearch.vue';
    import RecentlyViewed from './RecentlyViewed.vue';
    import QuickSearch from './QuickSearch.vue';
    import SearchBarProgress from "../SearchBarDesktop/SearchBarProgress.vue";

    export default {
        name: 'SearchBarDesktop',
        components: {
            QuickSearch,
            AdvanceSearch,
            RecentlyViewed,
            SearchBarProgress
        },
        computed: {
          ...mapGetters([
            'isSearchOpen',
            'isActiveTab'
          ]),
          ...searchResultsGetters([
              'getSearchProgressStatus'
          ])
        },
        methods:{
            ...mapActions([
                'toggleSearchBar'
            ]),
        }
    }
</script>
<style lang="scss">
    @import "searchBarPwa";
</style>