<template>
    <multiSelect
            :disabled="disabled"
            v-if="multiple"
            :placeholder="placeholder"
            :class=" hasClear ? [className, 'hasClear'] : className "
            :options="options"
            v-on="inputListeners"
            v-model="selectedValue"
            :show-labels="false"
            :multiple="multiple"
            :label="labelBy"
            :track-by="trackBy"
            :hasClear="hasClear"
            @open="hasAjax && $emit('ajaxCall')"
            :loading="isLoading"
            :openDirection="openDirection"
            :close-on-select="false"
            :searchable="searchable">
        <span slot="noResult" class="noResult-item">No results found.</span>
        <template slot="beforeList" v-if=" hasClear">
            <li class="multiselect__clear"
                     @mousedown.prevent.stop="handleClearClick">
               <span>Clear</span>
            </li>
        </template>
        
        <template slot="selection"
                  class="placeholder-clear-box"
                  slot-scope="{ values, search, isOpen }">
            <div class="placeholder-wrapper" >
                <span class="multiselect__single selected-counter"
                      v-if="values.length &amp;&amp; !isOpen" >
                    <template v-if="tagging">
                        <TagOptions :values="values" :limit="options.length"/>
                    </template>
                    <template v-else-if="placeholderIsTitle">
                        {{ placeholder }}
                    </template>
                    <template v-else>
                        {{ values.length }} selected
                    </template>

                </span>
            </div>
        </template>
    </multiSelect>

    <multiSelect v-else
                 :disabled="disabled"
                 :placeholder="placeholder"
                 :class=" hasClear ? [className, 'hasClear'] : className "
                 :options="options"
                 v-on="inputListeners"
                 v-model="selectedValue"
                 :show-labels="false"
                 :hasClear="hasClear"
                 :label="labelBy"
                 :track-by="trackBy"
                 @open="hasAjax && $emit('ajaxCall')"
                 :loading="isLoading"
                 :openDirection="openDirection"
                 :resetAfter="resetAfter"
                 :searchable="searchable">
        <span slot="noResult" class="noResult-item">No results found.</span>

        <template slot="beforeList" v-if=" hasClear">
            <li class="multiselect__clear"
                @mousedown.prevent.stop="handleClearClick">
                <span>Clear</span>
            </li>
        </template>
    </multiSelect>
</template>

<script>
    import multiSelect from '@/plugins/multiSelect';
    import TagOptions from "@/components/common/TagOptions";
    export default {
        name: "SaiMultiSelect",
        model: {
            prop: 'value',
            event: 'change'
        },
        props:{
            options: {},
            multiple: {type: String} ,
            className: {},
            searchable: {type: Boolean, default:true },
            hasClear: {type: Boolean},
            placeholder: {},
            value: {type:null, default: ''},
            labelBy: {type:String, default: ''},
            trackBy:{type:String, default: ''},
            isLoading:{type:Boolean, default:false },
            hasAjax: {type:Boolean, default:false},
            tagging: {type:Boolean, default:false},
            uri: {},
            openDirection: {type:String, default: 'below'},
            disabled: { type: Boolean, default: false},
            placeholderIsTitle: { type: Boolean, default: false},
            resetAfter: {type: Boolean, default:false}
        },
        components: {
             multiSelect,
             TagOptions
        },
        data() {
            let selectedValue = this.value;
            if(this.multiple) {
                selectedValue = this.value || [];  
            }
            return {
                selectedValue
            }
        },
        computed: {
            inputListeners: function () {
                var vm = this;
                return Object.assign({},
                    this.$listeners,
                    {
                        input: function (event) {
                            vm.$emit('change', event)
                        }
                    }
                )
            },
        },
        methods: {
            handleClearClick() {
                const selectedValue = this.multiple ? [] : '';
                this.selectedValue =  selectedValue;
                this.$emit('change', selectedValue);
            }
        },
        watch: {
            value: function(newVal) {
                this.selectedValue = newVal;
            },
        }
    }
</script>
