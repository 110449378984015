import { extend } from 'vee-validate'
import { required, email, digits } from "vee-validate/dist/rules";
import { isValid, compareAsc  } from 'date-fns';

extend('email', email);
extend('required', required);
extend('digits', digits);

extend('domain', value => {
    if(value) {
        //const domainRegx = /^(\s)*(@){0,1}([a-zA-Z0-9]+[a-zA-Z0-9-]*[a-zA-Z0-9])+(\.[a-zA-Z0-9]+){1,10}(\s)*$/g;
        const domainRegx = /^(\s)*([a-zA-Z0-9]+[a-zA-Z0-9-]*[a-zA-Z0-9])+(\.[a-zA-Z0-9]+){1,10}(\s)*$/g;
        const valid = domainRegx.test(value);
        if(valid)
            return true;
        return 'Please enter valid email domain, starting with e.g. saiglobal.com.';
    } else {
        return true;
    }
});

extend('multipleSubDomains', value => {
    if(value) {
        //const multipleDomainRegx = /^((\s)*([a-zA-Z0-9]+[a-zA-Z0-9-]*[a-zA-Z0-9])+(\.[a-zA-Z0-9]+){1,10}(\s)*(\,((\s)*([a-zA-Z0-9]+[a-zA-Z0-9-]*[a-zA-Z0-9])+(\.[a-zA-Z0-9]+){1,10}(\s)*))*)+$/g;
        //const multipleDomainRegx = /^((\s)*(@){0,1}([a-zA-Z0-9]+[a-zA-Z0-9-]*[a-zA-Z0-9])+(\.[a-zA-Z0-9]+){1,10}(\s)*)+$/g;
        const multipleDomainRegx = /^((\s)*([a-zA-Z0-9]+[a-zA-Z0-9-]*[a-zA-Z0-9])+(\.[a-zA-Z0-9]+){1,10}(\s)*)+$/g;
        const valid = multipleDomainRegx.test(value);
        if(valid)
            return true;
        return 'Please enter valid sub domain, one per line.';
    } else {
        return true;
    }
    
});

extend('austrailanPhone', value => {
    if(value) {
        value = value.replace(/ /g, '');
        const austrailanPhoneRegx = /^(0){0,1}(2|3|4|7|8)[0-9]{8}$/g;
        const valid = austrailanPhoneRegx.test(value);
        if(valid)
            return true;
        return 'Please enter valid phone number.';
    } else {
        return true;
    }
});

extend('checkEmailDomain',{
    params: ["domainToCheck"],
    validate: (value, { domainToCheck }) => {
        if(!value) return true;
        const arrEmail = value.split('@');
        if(arrEmail.length ==2) {
            if(arrEmail[1].trim() == domainToCheck.replace(/@/g,'')) {
                return true;
            } else {
                return false;
            }
        }
        return false;
    },
    message: "Email should be of domain '{domainToCheck}'."
});

const prepDate = dateValue => {
    dateValue = dateValue.replace(/ /g, '');
    const dateFormatRegx = /^\d{1,2}-\d{1,2}-\d{4}$/g;
    if(dateFormatRegx.test(dateValue)) {
        const arrDateValue = dateValue.split('-');
        if(arrDateValue.length ==3) {
            let month = arrDateValue[1].length == 1 ? '0' + arrDateValue[1] : arrDateValue[1];
            let day = arrDateValue[0].length == 1 ? '0' + arrDateValue[0] : arrDateValue[0];
            return new Date(arrDateValue[2] + '-' + month + '-' + day);
        }
    }
    return '';
}

extend('validDate', value => {
    if(value) {
        const dateVal = prepDate(value);
        if(isValid(dateVal)) {
            return true;
        }
        return 'Please enter valid date of format DD-MM-YYYY.';
    } else {
        return true;
    }
    
});
    

extend("dateGreaterThan", {
    params: ["compareWith"],
    validate: (value, { compareWith }) => {
        if(!value || !compareWith) {
            return true;
        }
        const compareWithDate = prepDate(compareWith);
        const toCompareDate = prepDate(value);
        if(isValid(compareWithDate) && isValid(toCompareDate)) {
            if(compareAsc(toCompareDate, compareWithDate) == 1) {
                return true;
            }
        } 
        return false;
    },
    message:
      "The End Date should be greater than Start Date."
});