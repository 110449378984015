<template>
    <sai-accordion-primary
        :key="accordion.id"
        :fixed="accordion.fixed"
        :open="accordion.open"
        :id="accordion.id"
        :order="accordion.order"
        :settings="accordion.settings"
        :headerIcon="getAccordionContent(accordion.id).icon"
        @viewModeHandler="viewModeHandler"
        @accordionOpenHandler="accordionOpenHandler"
        :headerTitle="$t(getAccordionContent(accordion.id).title)" >
        <div slot="accordion-content">
           <template v-if="plainContentAccordions.indexOf(accordion.id) >=0">
                <PlainContent :bodyContent="bodyContent" class="accordion-content"/>
           </template>

            <template v-if="columnarContentAccordions.indexOf(accordion.id) >=0">
                <ColumnarContent :headerContent="headerContent"
                                 :bodyContent="bodyContent"
                                 :footerContent="footerContent"
                                 :class="['accordion-content', accordion.id + '-content' ]"/>
            </template>

            <template v-if="multiColumnarContentAccordions.indexOf(accordion.id) >=0">
                <MultiColumnarContent :bodyContent="bodyContent"  class="accordion-content" :settings="accordion.settings"/>
            </template>

        </div>
    </sai-accordion-primary>
</template>

<script>
import {createNamespacedHelpers} from "vuex";
const {mapGetters, mapActions} = createNamespacedHelpers('quickviews');
import SaiAccordionPrimary from "@/components/common/SaiAccordionPrimary";
import PlainContent from "@/components/QuickViews/AccordionContent/PlainContent";
import ColumnarContent from "@/components/QuickViews/AccordionContent/ColumnarContent";
import MultiColumnarContent from "@/components/QuickViews/AccordionContent/MultiColumnarContent";

export default {
    name: "DraggableAccordions",
    data() {
        return {
            plainContentAccordions: ['orders', 'unlock'],
            columnarContentAccordions: ['myStandards', 'myC2VStandards', 'myAlerts', 'myWatchedDocuments'],
            multiColumnarContentAccordions: ['myActivities']
        }
    },
    props: {
        accordion:{type:Object, default:()=>{}}
    },
    components: {
        SaiAccordionPrimary,
        PlainContent,
        ColumnarContent,
        MultiColumnarContent
    },

    computed: {
        ...mapGetters([
                'getAccordionContent'
            ]
        ),
        bodyContent() {
            return this.getAccordionContent(this.accordion.id).content
        },
        headerContent() {
            return this.getAccordionContent(this.accordion.id).headers
        },
        footerContent() {
            return this.getAccordionContent(this.accordion.id).footer ?? []
        },
    },
    methods: {
        ...mapActions([
            'setAccordionOptions'
        ]),
        viewModeHandler(values) {
            this.setAccordionOptions({accordion:values.accordion, payload:{"settings":{'viewMode': values.value} }})
        },
        accordionOpenHandler(values) {
            this.setAccordionOptions( {accordion:values.accordion, payload:{"open": values.value} } )
        },
    }
}

</script>

<style scoped>

</style>