import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { localize } from "vee-validate";

Vue.use(VueI18n);

const i18n = new VueI18n({
    locale: 'english', // set locale
    fallbackLocale: 'english',
    messages: { }
})

const loadedLanguages = [] // our default language that is preloaded


function setI18nLanguage (lang) {
    i18n.locale = lang
    // Vue.$cookies.set('wxguestlanguage', lang);
    // axios.defaults.headers.common['Accept-Language'] = lang
    //document.querySelector('html').setAttribute('lang', lang)
    import(`@/locales/validations/${lang}.json`).then(
        validationLocale => {
            localize(lang, validationLocale)
        }
    );
    return lang
}

export const loadLanguageAsync = function(lang) {
    // If the same language
    // if (i18n.locale === lang) {
    //     return Promise.resolve(setI18nLanguage(lang))
    // }

    // If the language was already loaded
    if (loadedLanguages.includes(lang)) {
        return Promise.resolve(setI18nLanguage(lang))
    }

    // If the language hasn't been loaded yet
    return import(/* webpackChunkName: "lang-[request]" */ `@/locales/${lang}.json`).then(
        messages => {
            i18n.setLocaleMessage(lang, messages.default)
            loadedLanguages.push(lang)
            return setI18nLanguage(lang)
        }
    )
}

export default i18n;

export const initializeLang = () => {
    const availableLanguages = ['english','french','german','italian','spanish','russian','chinese'];
    let initLang = (typeof window.wxlanguage === 'undefined') ? 'english' : atob(window.wxlanguage);
    if(availableLanguages.indexOf(initLang.toLowerCase()) < 0) {
        initLang = 'english';
    }
    loadLanguageAsync(initLang);
}

export const asyncLoadValidationMsgs = path => {
    import(`@/components/${path}.json`).then(validationMessages => {
        localize(i18n.locale, validationMessages);
    });
}