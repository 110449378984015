<template>
    <form v-show="isSearchOpen" method="post" action="/management/search/index" name="quickSearchForm" ref="mobilesearch">
        <div class="searchBar-mobile-container">
            <QuickSearch/>
            <AdvanceSearch/>
            <div class="form-submit-row">
                <SectionCTA/>
                <RecentlyViewed v-if="!isLiteSubscription"/>
                <ClearFilters/>
            </div>
        </div>
    </form>
</template>
<script>
    import { createNamespacedHelpers } from 'vuex';
    const { mapGetters } = createNamespacedHelpers('searchbar');
    import QuickSearch from './QuickSearch';
    import AdvanceSearch from './AdvanceSearch';
    import RecentlyViewed from './RecentlyViewed'
    import SectionCTA from './SectionCTA';
    import ClearFilters from './ClearFilters'

    export default {
        name: 'SearchBarMobile',
        components: {
            QuickSearch,
            AdvanceSearch,
            RecentlyViewed,
            SectionCTA,
            ClearFilters
        },
        computed: {
            ...mapGetters([
                'isSearchOpen',
                'isLiteSubscription'
            ])
        }
    }
</script>
<style lang="scss">
    @import "./SearchBarMobile";
</style>
