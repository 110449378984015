<template>
    <div class="buttons-container">
        <button type="button"
                v-if="hasMobileAdvanceSearch(getActiveSearchTab)"
                class="advance-toggle-button"
                @click="toggleAdvanceSearch">

            <span class="advance-button-text" v-if="isAdvanceSearchActive(getActiveSearchTab)">{{ $t('frm_hide_filters') }}</span>
            <i class="si-minus si" v-if="isAdvanceSearchActive(getActiveSearchTab)"></i>

            <span class="advance-button-text" v-if="!isAdvanceSearchActive(getActiveSearchTab)">{{ $t('frm_show_filters') }}</span>
            <i class="si-plus si" v-if="!isAdvanceSearchActive(getActiveSearchTab)"></i>
        </button>

        <button type="submit" class="search-button" name="onclk_search"
                ondblclick="return false;"
                onclick="this.onclick=function () {return false;};return true">
            <span class="small-dev-go-text">SEARCH</span>
        </button>
    </div>
</template>

<script>
    import {createNamespacedHelpers} from 'vuex';
    const {mapGetters, mapActions} = createNamespacedHelpers('searchbar');
    export default {
        name: "SectionCTA",
        computed: {
            ...mapGetters([
                'isAdvanceSearchActive',
                'getActiveSearchTab',
                'hasMobileAdvanceSearch'
            ])
        },
        methods: {
            ...mapActions([
                'setAdvanceSearchDisplay'
            ]),
            toggleAdvanceSearch() {
                this.setAdvanceSearchDisplay({
                    tab: this.getActiveSearchTab,
                    blnDisplay: !this.isAdvanceSearchActive(this.getActiveSearchTab)
                });
            }
        }
    }
</script>
