<template>
    <div class="quickSearchBox">
        <SaiInput type="text" class="mobile-search-input"
                  name="query_1"
                  :value="getStandardsFilter('documentNumber')"
                  @input="$event => setStandardsFilter({field: 'documentNumber', payload: $event})"
                  :placeholder="$t('frm_placeHolder_document_number')" :disabled="!isActiveTab('standards')"/>
       <SaiInput type="text" class="mobile-search-input"
                 name="query_2"
                 :value="getStandardsFilter('documentTitle')"
                 @input="$event => setStandardsFilter({field: 'documentTitle', payload: $event})"
                 :placeholder="$t(('frm_placeHolder_document'))" :disabled="!isActiveTab('standards')"/>
    </div>
</template>

<script>
    import SaiInput from '@/components/common/SaiInput';
    import {createNamespacedHelpers} from 'vuex';
    const {mapGetters, mapActions} = createNamespacedHelpers('searchbar');
    export default {
        name: "QuickSearchStandard",
        components: {
            SaiInput
        },
        computed: {
            ...mapGetters([
                'getStandardsFilter',
                'isActiveTab'
            ]),
        },
        methods: {
            ...mapActions([
                'setStandardsFilter'
            ])
        }

    }
</script>
