<template>
    <div class="sai-card">
        <div class="card-header">
            <slot name="card-header"></slot>
        </div>
        <div class="card-body">
            <slot name="card-body"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "SaiCard"
}
</script>

<style scoped lang="scss">
@import "~@/css/base/base";
.sai-card {
    margin: $margin 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid $c5;
    border-radius:$radius;
    .card-header {
        background-color:#fff;
        border-bottom: 1px solid $c5;
        padding: $padding*1.5 $padding*3;
        color: $c8;
        font-weight: $medium;
        line-height: 18px;
        span {
            margin-right: $margin;
        }
    }
}
</style>