<template>
    <div>
        <SearchBoxMobile
                :title="title"
                datatype="array"
                :selectedContent="getItem(field)"
                @clickHandler="overlay=true" />

        <OverlayMultiSelect v-if="overlay"
                            :headerTitle="title"
                            :saveTitle="$t('frm_done')"
                            :display="overlay"
                            @closeOverlay="overlay=false"
                            @saveChanges="saveItem"
                            :preSelectedOptions="getItem(field)"
                            :lookupField="lookupField"
                            :field="field"
                            :initialOptions="initialOptions" />
    </div>


</template>

<script>
    import {createNamespacedHelpers} from 'vuex';
    const {mapGetters, mapActions} = createNamespacedHelpers('searchbar');
    import SearchBoxMobile from '@/components/common/SearchBoxMobile';
    import OverlayMultiSelect from '@/components/SearchBar/SearchBarMobile/OverlayMultiSelect';

    export default {
        name: "FilterBoxOptions",
        props: {
            title: {type: String, default: ''},
            field: {type: String, default: '', required: true},
            overlay: {type: Boolean, default: false},
            lookupField: {type: String, default: '', required: false},
            initialOptions: { type: Array, default: () => [], required: false },
        },
        components: {
            SearchBoxMobile,
            OverlayMultiSelect
        },
        computed: {
            ...mapGetters([
                'getItem',
            ]),
        },
        methods: {
            ...mapActions([
                'setItem',
            ]),
            saveItem: function(value) {
                this.overlay = false;
                this.setItem({  field: this.field, payload: value })
            }
        }
    }
</script>
