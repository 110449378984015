import axios from "@/plugins/axios";
let accordionsSettings = [];

export const apiGetAccordions = async () => {
    try {
        const apiEndPoint = 'accordions';
        const response = await axios.get({ endpoint: '/management/services.v1.homeajax/' + apiEndPoint});
        if(response.data) {
            accordionsSettings = response.data;
            return (accordionsSettings.settings);
        }
    } catch(e) {
        console.log('There has been a problem with your fetch operation: ' + e.message);
    }
    return [];
}

export const apiGetAccordionContent = async(accordionName) => {
    try {
       return accordionsSettings[accordionName] ?? [];
    } catch(e) {
        console.log('There has been a problem with your fetch operation:'+ e.message)
    }
    return [];
}

export const apiGetAccordionFooter = async(accordionName) => {
    try {
        const settings = accordionsSettings.settings;
        const accordionId = settings.filter(setting => {
            return setting.id === accordionName
        })
        return accordionId[0]['footer'] ?? [];
    } catch(e) {
        console.log('There has been a problem with your fetch operation:'+ e.message)
    }
    return [];
}

export const apiUpdateAccordionSettings = async (settings) => {
    try {
        let options = {};
        let params = {};
        options = {headers: {'Content-Type': 'application/x-www-form-urlencoded'}};
        params = new URLSearchParams();
        params.append('key' , 'home');
        params.append('value' , JSON.stringify(settings));

        const apiEndPoint = 'settings';
        const response = await axios.post({
            endpoint: '/management/services.v1.homeajax/'+ apiEndPoint,
            params: params,
            options: options

        });
        if (response.data) {
            return response.data;
        }
    } catch (e) {
        console.log('There has been a problem with your fetch operation:'+ e.message);
    }
    return [];
}

export const deleteSavedSearch = async (elementId) => {
    try {
        const response = await axios.post({
            endpoint: '/management/search/deleteSavedSearch/'+ elementId,
        });
        if (response.data) {
            return response.data;
        }
    } catch (e) {
        console.log('There has been a problem with your fetch operation:'+ e.message);
    }
}

export const updateSavedSearch = async (elementId, updatedName) => {
    try {
        let options = {};
        let params = {};
        options = {headers: {'Content-Type': 'application/x-www-form-urlencoded'}};
        params = new URLSearchParams();
        params.append('name' , updatedName);
        const response = await axios.post({
            endpoint: '/management/search/editInquiry/'+ elementId,
            params: params,
            options: options
        });
        if (response.data) {
            return response.data;
        }
    } catch (e) {
        console.log('There has been a problem with your fetch operation:'+ e.message);
    }
}