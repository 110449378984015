<template>
    <span  v-click-outside="clickOutsideConfig"  @click="handleDropdownclick" 
    :ref="'ref-select2-container-' + name" :id="'ref-select2-container-' + name" 
    :class="'ref-select2-container-' + name + 'select2 select2-container select2-container--default select2-container--above select2-container--focus select2-container--open'" 
    style="width: 100%;">
        <span class="selection">
            <span class="select2-selection select2-selection--single" tabindex="0" >
                <span class="select2-selection__rendered" id="select2-country-container" :title="selectedValue[labelBy] ? selectedValue[labelBy] : ''">
                    {{  selectedValue[labelBy] ? selectedValue[labelBy] : placeholder }}
                </span>
                <span v-if="!isLoading"  class="select2-selection__arrow" role="presentation"></span>
                <div v-if="isLoading" class="multiselect__spinner"></div>
            </span>
        </span>
        <span class="dropdown-wrapper"></span>
        <Portal to="sai-dropdown-options" v-if="displayOptions">
            <span :id="'ref-dropdown-container-' + name " :ref="'ref-dropdown-container-' + name" :class="'ref-dropdown-container-' + name + 'select2-container select2-container--default select2-container--open'" style="position:absolute;">
                <span :id="'ref-dropdown-' + name" :ref="'ref-dropdown-' + name" class="select2-dropdown select2-dropdown--below">
                    <span class="select2-search select2-search--dropdown">
                        <div class="clear-btn-box"><a class="clear-btn" @click="handleDropdownClear">clear</a></div>
                        <input class="select2-search__field" type="search" 
                        autocomplete="off" autocorrect="off" autocapitalize="none" 
                        spellcheck="false" 
                        v-model="searchText">
                    </span>
                    <span class="select2-results">
                        <ul class="select2-results__options">
                            <li  class="select2-results__option" v-if="isLoading">Loading...</li>
                            <li  class="select2-results__option" v-if="!isLoading && !options.length">No results found</li>
                            <li  v-for="option in filteredOptions" :key="option[trackBy]" 
                                class="select2-results__option" @click="$event => handleOptionSelect($event, option)">
                                {{ option[labelBy] }}
                             </li>
                        </ul>
                    </span>
                </span>
            </span>
        </Portal>
    </span>
</template>
<script>
    import vClickOutside from 'v-click-outside';
    export default {
        name: "SaiDropDown",
        inheritAttrs: false,
        model: {
            prop: 'value',
            event: 'change'
        },
        props: {
            value: { type: Object, default: ()=>{} },
            labelBy: { type: String, default: 'label' },
            trackBy: { type: String, default: 'value' },
            name: { type: String, default:'', required: true },
            options: { type: Array, default: () => [], required: false },
            autoWidth: { type: Boolean, default: true, required: false },
            placeholder: { type: String, default: '', required: false },
            isLoading: { type: Boolean, default: false, required: false },
        },
        directives: {
            clickOutside: vClickOutside.directive
        },
        data() {
            return {
                clickOutsideConfig: {
                    handler: this.clickOutSideHandler,
                    middleware: this.clickOutSideMiddleware,
                    events: ['click']
                },
                displayOptions: false,
                selectedValue: this.value ? this.value : {},
                searchText: ''
            }
        },
        destroyed() {
            this.removeEventListener();
        },
        computed: {
            filteredOptions() {
                return this.options.filter(option => {
                    return option[this.labelBy].toLowerCase().includes(this.searchText.toLowerCase())
                })
            }
        },
        methods: {
            handleDropdownClear() {
                this.selectedValue = {};
                this.hideOptions();
            },
            handleDropdownclick() {
                if(this.displayOptions) {
                    this.hideOptions(); 
                } else {
                   this.$emit('open');
                   this.showOptions(); 
                }
            },
            hideOptions() {
                this.searchText = '';
                this.displayOptions = false;
                this.removeEventListener();
            },
            async showOptions() {
                this.displayOptions = true;
                this.$nextTick(() => {
                    if(this.displayOptions) {
                        this.positionDropDownOptions();
                        this.resizeDropdownOptions();
                    }
                });
                this.addEventListeners();
            },
            handleOptionSelect($event, value) {
                this.selectedValue = value;
                this.$emit('change', value);
                this.hideOptions(); 
            },
            clickOutSideHandler () {
                 this.hideOptions();
            },
            // Note: The middleware will be executed if the event was fired outside the element.
            //       It should have only sync functionality and it should return a boolean to
            //       define if the handler should be fire or not
            clickOutSideMiddleware (event) {
                const isDropDownClick = event.target.className === 'ref-select2-container-' + this.name;
                const isDropDownOptionsClick  = event.target.className === 'ref-dropdown-container-' + this.name;
                const dropdownContainer = this.$refs['ref-dropdown-container-' + this.name];
                const elementInsideOptions = dropdownContainer && dropdownContainer.contains(event.target);
                if(isDropDownClick || isDropDownOptionsClick || elementInsideOptions) {
                    return false;
                }
                return true;
            },
            addEventListeners() {
                window.addEventListener('scroll', this.positionDropDownOptions)
                window.addEventListener('resize', this.positionDropDownOptions)
                window.addEventListener('scroll', this.resizeDropdownOptions)
                window.addEventListener('resize', this.resizeDropdownOptions)
            },
            removeEventListener() {
                window.removeEventListener('scroll', this.positionDropDownOptions)
                window.removeEventListener('resize', this.positionDropDownOptions)
                window.removeEventListener('scroll', this.resizeDropdownOptions)
                window.removeEventListener('resize', this.resizeDropdownOptions)
            },
            resizeDropdownOptions() {
                const $container = this.$refs['ref-select2-container-' + this.name];
                const $dropdown = this.$refs['ref-dropdown-' + this.name];
                const clentRec = $container.getBoundingClientRect();
                const width = clentRec.width + 'px';
                if (this.autoWidth) {
                    $dropdown.style.minWidth = width;
                    $dropdown.style.position = 'relative';
                    $dropdown.style.width = 'auto';
                } else {
                    $dropdown.style.width = width;
                }
            },
            positionDropDownOptions() {
                const $dropdown = this.$refs['ref-dropdown-' + this.name];
                const isCurrentlyAbove = $dropdown.classList.contains('select2-dropdown--above');
                const isCurrentlyBelow = $dropdown.classList.contains('select2-dropdown--below');
                let newDirection = null;

                const $container = this.$refs['ref-select2-container-' + this.name];
                
                let clientRec = $container.getBoundingClientRect();
                let offset = { };
                let scrollLeft = window.scrollX || window.pageXOffset || document.documentElement.scrollLeft;
                let scrollTop =  window.scrollY || window.pageYOffset || document.documentElement.scrollTop;
                offset.top = clientRec.top + scrollTop;
                offset.left = clientRec.left + scrollLeft;
                offset.bottom = clientRec.bottom;
               
                let container = {
                    height: $container.offsetHeight
                };

                container.top = offset.top;
                container.bottom = offset.top + container.height;

                let dropdown = {
                    height: $dropdown.offsetHeight
                };

                let viewport = {
                    top: scrollLeft,
                    bottom: scrollTop + window.innerHeight
                };
                
                let enoughRoomAbove = viewport.top < (offset.top - dropdown.height);
                let enoughRoomBelow = viewport.bottom > (offset.bottom + dropdown.height);

                let css = {
                    left: offset.left,
                    top: container.bottom
                };

                // Determine what the parent element is to use for calculating the offset
                let $offsetParent = $container.parentElement;

                // For statically positioned elements, we need to get the element
                // that is determining the offset
                if ($offsetParent.style.position === 'static') {
                    $offsetParent = $offsetParent.parentElement;
                }

                let parentOffset = {
                    top: 0,
                    left: 0
                };

                if (!isCurrentlyAbove && !isCurrentlyBelow) {
                    newDirection = 'below';
                }

                if (!enoughRoomBelow && enoughRoomAbove && !isCurrentlyAbove) {
                    newDirection = 'above';
                } else if (!enoughRoomAbove && enoughRoomBelow && isCurrentlyAbove) {
                    newDirection = 'below';
                }

                if (newDirection == 'above' ||
                    (isCurrentlyAbove && newDirection !== 'below')) {
                    css.top = container.top - parentOffset.top - dropdown.height;
                }

                if (newDirection != null) {
                    $dropdown.classList.remove('select2-dropdown--below');
                    $dropdown.classList.remove('select2-dropdown--above');
                    $dropdown.classList.add('select2-dropdown--' + newDirection);

                    $container.classList.remove('select2-container--below');
                    $container.classList.remove('select2-container--above');
                    $container.classList.add('select2-container--' + newDirection);
                }

                const dropdownContainer = this.$refs['ref-dropdown-container-' + this.name];
                dropdownContainer.style.top = css.top + "px";
                dropdownContainer.style.left = css.left  + "px";
            },
        },
        watch: {
            value: function(newVal) {
                this.selectedValue = newVal;
            },
        }
    }
</script>