export default {
    getItem: state => field => {
        return state[field] ? state[field]: '';
    },
    isCurrentStep: state => stepToCheck => {
        if(stepToCheck == state.currentStep) {
            return true;
        } else {
            return false;
        }
    },
    isFirstStep: state => stepToCheck => {
        const stepIds = Object.keys(state['steps']);
        if(stepIds[0] == stepToCheck) {
            return true;
        } else {
            return false;
        }
    },
    isLastStep: state => stepToCheck => {
        const stepIds = Object.keys(state['steps']);
        if(stepIds[stepIds.length-1] == stepToCheck) {
            return true;
        } else {
            return false;
        }
    },
    isCompleted: state => stepToCheck => {
        // const stepOrder = parseInt(state['steps'][stepToCheck]['step']);
        // const currentStepOrder = parseInt(state['steps'][state['currentStep']]['step']);
        // if(stepOrder < currentStepOrder) {
        //     return true;
        // } else {
        //     return false;
        // }
        const stepIds = Object.keys(state.steps);
        const currentStepIndex = stepIds.indexOf(state.currentStep);
        const stepToCheckIndex = stepIds.indexOf(stepToCheck);
        if(stepToCheckIndex < currentStepIndex) {
            return true;
        } else {
            return false;
        }
    },
    getNextStep: state => {
        const steps = state.steps;
        const stepIds = Object.keys(steps);
        const currentStepId = state.currentStep;
        const idxCurrentStep = stepIds.indexOf(currentStepId);
        const idxNextStep = idxCurrentStep +1;
        if(idxNextStep < stepIds.length) {
            return stepIds[idxNextStep]
        } else {
            return '';
        }
    },
    getPreviousStep:  state => {
        const steps = state.steps;
        const stepIds = Object.keys(steps);
        const currentStepId = state.currentStep;
        const idxCurrentStep = stepIds.indexOf(currentStepId);
        const idxPreviousStep = idxCurrentStep - 1;
        if(idxPreviousStep > -1) {
            return stepIds[idxPreviousStep]
        } else {
            return '';
        }
    },
    displayHelp: state => {
        if(state.activeHelp !='')
            return true;
        else 
            return false;
    },
    getFormItem: state => step => field => state['steps'][step]['fields'][field],
    isCustomerCreated: state => state.customerCreated,
    getError: state => step => field => {
        let error = (state['error'][step] && state['error'][step][field]) ? state['error'][step][field] : ''
        if(Array.isArray(error) && error.length == 0) {
            error = '';
        }
        return error;
    },
    getWarning: state => step => field => {
        let warning = (state['warning'][step] && state['warning'][step][field]) ? state['warning'][step][field] : ''
        if(Array.isArray(warning) && warning.length == 0) {
            warning = '';
        }
        return warning;
    },
    hasServerError: state => step => {
        if(state['error'] && state['error'][step]) {
            const objStepErrors = state['error'][step];
            for (const field in objStepErrors) {
                if(objStepErrors[field]) {
                    return true;
                }
            }
        }
        return false;
    },
    getStepIds: state => Object.keys(state.steps),
    getStep: state => step => state['steps'][step]
}