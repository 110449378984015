<template>
    <div class="searchBoxList">
        <FilterBoxSelect :title="$t('fld_country')" field="metalsSupplier.country" :searchable="true"
                         lookupField="metals.country"/>
        <input type="hidden" name="metals_supplier_country" :value="getMetalsSupplierFilter('country').id" :disabled="!isActiveTab('metalsSupplier')">

        <FilterBoxOptions :title="$t('frm_form')" field="metalsSupplier.form" lookupField="metals.form"/>
        <input name="metals_supplier_form[]" type="hidden" v-for="element in getMetalsSupplierFilter('form')"
               :key="element.id" :value="element.id" :disabled="!isActiveTab('metalsSupplier')">

        <FilterBoxSelect :title="$t('frm_establishment_type')" field="metalsSupplier.establishmentType" :searchable="true"/>
        <input type="hidden" name="metals_supplier_establishment_type"
               :value="getMetalsSupplierFilter('establishmentType').id" :disabled="!isActiveTab('metalsSupplier')">

        <FilterBoxInput :title="$t('frm_description')" field="metalsSupplier.descriptions"
                        :placeholder="$t('frm_description')" inputName="metals_supplier_description" :disabled="!isActiveTab('metalsSupplier')"/>

        <FilterBoxInput :title="$t('idx_keywords')" field="metalsSupplier.keyword"
                        :placeholder="$t('frm_supplier_keywords')" inputName="metals_supplier_keyword" :disabled="!isActiveTab('metalsSupplier')"/>

    </div>

</template>

<script>
    import {createNamespacedHelpers} from 'vuex';
    const searchBarStore = createNamespacedHelpers('searchbar');
    import FilterBoxInput from '@/components/SearchBar/SearchBarMobile/FilterBoxInput'
    import FilterBoxSelect from '@/components/SearchBar/SearchBarMobile/FilterBoxSelect'
    import FilterBoxOptions from '@/components/SearchBar/SearchBarMobile/FilterBoxOptions'

    export default {
        name: "AdvanceMetalSupplier",
        components: {
            FilterBoxOptions,
            FilterBoxInput,
            FilterBoxSelect,
        },
        computed: {
            ...searchBarStore.mapGetters([
                'getMetalsSupplierFilter',
                'isActiveTab'
            ]),
        },

    }
</script>
