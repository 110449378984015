export default {
    SET_PAGE_NUMBER: "SET_PAGE_NUMBER",
    SET_SEARCH_RESULTS: "SET_SEARCH_RESULTS",
    SET_SEARCH_RESULTS_PWA: "SET_SEARCH_RESULTS_PWA",
    SET_ACTIVE_TAB: "SET_ACTIVE_TAB",
    SET_LOADING_STATUS: "SET_LOADING_STATUS",
    SET_SEARCH_RESULTS_FILTER_SELECTED_VALUES: "SET_SEARCH_RESULTS_FILTER_SELECTED_VALUES",
    SET_BULK_SELECTED_RECORDS: "SET_BULK_SELECTED_RECORDS",
    SET_CAN_RESET: "SET_CAN_RESET",
    SET_IS_SAVED_SEARCH: "SET_IS_SAVED_SEARCH",
    SET_ORDER_BY: "SET_ORDER_BY",
    SET_DOC_ROW_ACTION_PROGRESS: "SET_DOC_ROW_ACTION_PROGRESS",
    SET_ROW_FIELDS: "SET_ROW_FIELDS",
    SET_IS_ACTIVE_ACCORDION: "SET_IS_ACTIVE_ACCORDION",
    SET_IS_SEARCH_RESULT_ACTIVE: "SET_IS_SEARCH_RESULT_ACTIVE",
    SET_SEARCH_PROGRESS_STATUS: "SET_SEARCH_PROGRESS_STATUS",
    SET_TOASTR_MESSAGE: "SET_TOASTR_MESSAGE",
    SET_SEARCH_RESULT_FIELD: "SET_SEARCH_RESULT_FIELD",
    SET_PWA_STATUS: "SET_PWA_STATUS",
    SET_LOADMORE_LOADING_STATUS: "SET_LOADMORE_LOADING_STATUS",
    SET_EXPORT_STATUS: "SET_EXPORT_STATUS",
    SET_SELECTED_FIELDS_FOR_EXPORT: "SET_SELECTED_FIELDS_FOR_EXPORT",
    SET_EXPORT_RESULT: "SET_EXPORT_RESULT",
    SET_EXPORT_CSV_SEPARATOR: "SET_EXPORT_CSV_SEPARATOR"
};
