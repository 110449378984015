export const toolbarInitialState = {
    fontSizes: [
        { "key": "sm", "val": "Small" },
        { "key": "md", "val": "Medium" },
        { "key": "lg", "val": "Large" },
        { "key": "xl", "val": "Extra large" },
    ],
    activeFontSize: { "key": "md", "val": "Medium" },
    searchPlaceholder: "Search within ",
    tools: [
        { "name": "highlight", "title": "Highlight", "marker": "highlights", "icon": "highlight-icon", "visible": true },
        { "name": "comment", "title": "Comment", "marker": "comments", "icon": "comment-icon", "visible": true },
        { "name": "bookmark", "title": "Bookmark", "marker": "bookmarks", "icon": "bookmark-icon", "visible": true }
    ],
    activeTool: "",
}

export const leftPaneInitialState = {
    menuItems: [
        { "name": "list", "title": "List", "icon": "list-icon", "visible": true },
        { "name": "marker", "title": "Marker", "icon": "marker-icon", "visible": true }
    ],
    activeMarker: "",
    activeMenuItem: "",
    mobileLeftPane: false
}

export default () => ({
    loading: false,
    bookmarks: [],
    comments: [],
    searchComments: [],
    searchCommentText: "",
    sortCommentBy: "",
    highlights: [],
    toolbar: JSON.parse(JSON.stringify(toolbarInitialState)),
    leftPane: JSON.parse(JSON.stringify(leftPaneInitialState)),
    isBookmarkToolsActive: false,
    isCommentToolsActive: false,
    isHighlightableActive: false,
    tableOfContents: [],
    TOCSchema: [],
    sectionsContent: [],
    sectionsContentText: [],
    urlParams: null,
    searchText: '',
    searchItems: [],
    username: ''
})
