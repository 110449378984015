<template>
   <div class="form-row section advanced-assets" style="">
        <div class="form-group col-sm-2 col-md-4 col-lg-4 myCollectionMultiple">
            <FilterMultiSelect :placeholder="$t('frm_my_collections')" name="colls[]" :multiple="true" field="collections.collections"/>
            <input v-for="(collection, index) in getCollectionsFilter('collections')" :key="index" type="hidden" name="colls[]" :value="collection.id"  :disabled="isAdvanceFilterDisabled('collections')"/>
        </div>
        <div class="form-group col-sm-2 col-md-4 col-lg-4 companyCollectionSearch">
            <FilterMultiSelect :placeholder="$t('frm_company_collections')" name="dcoll" field="collections.companyCollection"/>
            <input type="hidden" name="dcoll" :value="getCollectionsFilter('companyCollection').id"  :disabled="isAdvanceFilterDisabled('collections')"/>
        </div>

        <div class="form-group col-sm-2 col-md-4 col-lg-4">
            <FilterMultiSelect :placeholder="$t('frm_document_type')" name="type" field="collections.documentType" :hasClear="false" :searchable="false"/>
            <input type="hidden" name="type" :value="getCollectionsFilter('documentType').id"  :disabled="isAdvanceFilterDisabled('collections')"/>
        </div>
        <div class="form-group col-sm-2 col-md-4 col-lg-4">
            <FilterMultiSelect :placeholder="$t('frm_current_label')" name="include[]" :multiple="true" field="collections.status" lookupField="standards.status" :hasClear="false" :searchable="false" :tagging="true"/>
            <input v-for="(status, index) in getCollectionsFilter('status')" :key="index" type="hidden" name="include[]" :value="status.id"  :disabled="isAdvanceFilterDisabled('collections')"/>
        </div>
        <div class="form-group col-sm-2 col-md-4 col-lg-4 myTagMultiple">
            <FilterMultiSelect :placeholder="$t('frm_tag')" name="tags[]"  :multiple="true" field="collections.tags" :initialOptions="collectionTagInitOptions"/>   
            <input v-for="(tag, index) in getCollectionsFilter('tags')" :key="index" type="hidden" name="tags[]" :value="tag.id" :disabled="isAdvanceFilterDisabled('collections')"/>                                                                                     
        </div>
        <div class="form-group col-sm-2 col-md-4 col-lg-4 search-via">
            <SaiInput type="text" class="assetsSection form-control form-control-select shadow-none form-control-dropdown form2row form-control-text-second-row"
            :placeholder="$t('frm_search_notes')" name="notes" id="notes"
            :value="getCollectionsFilter('notes')"
            @input="$event => setCollectionsFilter({field: 'notes', payload: $event})"
            autocomplete="off" :disabled="isAdvanceFilterDisabled('collections')"/>
        </div>
    </div>
</template>
<script>
    import FilterMultiSelect from '@/components/SearchBar/SearchBarDesktop/FilterMultiSelect';
    import SaiInput from '@/components/common/SaiInput';
    import { createNamespacedHelpers } from 'vuex';
    const { mapGetters, mapActions } = createNamespacedHelpers('searchbar');
    export default {
        name: "AdvanceSearchCollection",
        components: {
            FilterMultiSelect,
            SaiInput
        },
        data() {
            return {
                collectionTagInitOptions: []
            }
        },
        mounted() {
            this.collectionTagInitOptions = this.getCollectionsFilter('tags');
        },
        computed: {
            ...mapGetters([
                'getCollectionsFilter',
                'isAdvanceFilterDisabled'
            ]),
        },
        methods: {
            ...mapActions([
                'setCollectionsFilter'
            ])
        }
    }
</script>
