<template>
    <div :class="display ? 'sai-mobile-modal show' : 'sai-mobile-modal' ">

        <div class="mobile-modal-header">
            <span class="modal-header-left" @click="closeOverlay">
                <i class="si si-chevron-left"></i>
            </span>
            <span class="modal-header-center">
                {{headerTitle}}
            </span>
            <span class="modal-header-right" @click="saveChanges">
                {{saveTitle}}
            </span>
        </div>
        <div class="mobile-modal-content">
            <div class="modal-content-search" v-if="searchable" >
                <SaiInput className="content-search-input" v-model="search" placeholder="search" type="text"/>
            </div>
            <ul class="content-list">
                <li v-for="result in filteredResults" :key="result.id"
                    @click="selectItem({id: result.id ,text:result.text})">
                    <a>{{result.text}}</a>
                </li>
            </ul>
        </div>

        <div class="clear-selected" v-if="hasClearSelect && preSelected">
            <a class="clearBtn" @click="clearSelected">{{clearSelectedText}}</a>
        </div>

    </div>
</template>

<script>
    import SaiInput from '@/components/common/SaiInput';
    import { apiGetFieldOptions } from '@/components/SearchBar/api';
    import { createNamespacedHelpers } from 'vuex';
    const { mapGetters, mapActions } = createNamespacedHelpers('searchbar');
    export default {
        name: "OverlaySingleSelect",
        props: {
            display: {type: Boolean, default: false},
            headerTitle: {type: String, default: ''},
            saveTitle: {type: String, default: 'Done'},
            searchable: {type: Boolean, default: false},
            hasClearSelect: {type: Boolean, default: false},
            field: {type: String, default: '', required: true},
            options: {type: Array, default: () => [] },
            lookupField: { type: String, default: '', required: false },
            preSelected: {type: String, default: ''},
        },
        components: {
            SaiInput
        },
        mounted() {
            //load options from store, if its already loaded
            this.lookupFieldName = this.lookupField ? this.lookupField  : this.field;
            const options = this.getLookUpOptions(this.lookupFieldName);
            if(options && options.length) {
                this.results = options;
                this.isOptionsLoaded = true;
            } else {
                this.fetchData(this.field);
            }
        },
        data() {
            return {
                search: '',
                isOptionsLoaded: false,
                results: [],
                lookupFieldName: '',
                clearSelectedText: 'clear selected'
            }
        },
        methods: {
            ...mapActions([
                'setLookUpOptions',
            ]),
            fetchData: async function (field) {
                let loader = this.$loading.show();
                this.results = await apiGetFieldOptions(field);
                this.setLookUpOptions({type: this.lookupFieldName, options: this.results});
                await loader.hide();
            },
            closeOverlay: function () {
                this.search = '';
                this.$emit('closeOverlay')
            },
            selectItem: function (value) {
                this.search = '';
                this.$emit('selectItem', value)
            },
            clearSelected: function() {
                this.clearSelectedText = 'cleared';
                setTimeout(()=>{
                    this.$emit('clearSelected');
                },100);

            },
            saveChanges: function () {
                this.search = '';
                this.$emit('saveChanges');
            },
        },
        computed: {
            ...mapGetters([
                'getLookUpOptions'
            ]),
            filteredResults() {
                return this.results.filter(result => {
                    return result.text.toLowerCase().includes(this.search.toLowerCase())
                })
            },
        }
    }
</script>

