<template>
    <div class="form-group col-sm-4 col-md-4 col-lg-4 callModalBtn">
        <span v-if="getMetalsCompositionFiltersCount(type) > 0"  style="display: flex"  class="sai-btn compositeSelected" @click="showFilterPopup"><span>{{getMetalsCompositionFiltersCount(type)}} {{$t("criteria_selected")}}</span></span>
        <span v-else class="sai-btn compositeLabel" style="display: flex" data-value="" @click="showFilterPopup">{{$t("frm_" + type.replace('Composition', '') + "_composition")}}</span>
        <input :name="'metals_'+ type.replace('Composition', '') + '_composition'" type="hidden" :value="JSON.stringify(getMetalsCompositionFilters(type))" :disabled="disabled">
        <FilterPopupComposition :max="max" :type="type" :placeholder="placeholder" :display="displayMetalsCompositionFilters(type)"/>
    </div>
</template>
<script>
    import { createNamespacedHelpers } from 'vuex';
    const searchBarStore = createNamespacedHelpers('searchbar')
    import FilterPopupComposition from './FilterPopupComposition';
    export default {
        name: "FilterComposition",
        props: {
            max: { type: Number,  default: 100, required: true },
            type: { type: String,  default: '', required: true },
            placeholder: { type: String, required: true },
            disabled: { type: Boolean, default: false}
        },
        components: {
            FilterPopupComposition
        },
        computed: {
            ...searchBarStore.mapGetters([
                'getMetalsCompositionFilters',
                'getMetalsCompositionFiltersCount',
                'displayMetalsCompositionFilters'
            ])
        },
        methods: {
            ...searchBarStore.mapActions([
                'showMetalsCompositionFilters',
                'setMetalsCompositionFiltersErrMsg'
            ]),
            showFilterPopup() {
                this.setMetalsCompositionFiltersErrMsg({type: this.type, errMsg: ''});
                this.showMetalsCompositionFilters({type: this.type, show: true});
            }
        }
    }
</script>
