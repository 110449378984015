import Mutation from './mutationTypes';

export default {
    [Mutation.SET_ACCORDION_OPTIONS]: (state, {payload}) => {
        state = Object.assign(state, payload);
        Object.assign(state.homeAccordions, state);
    },
    [Mutation.SET_HOME_ACCORDIONS]: (state, accordions) => {
        state.homeAccordions = accordions
    },
    [Mutation.SET_ACCORDION_CONTENT]: (state, {accordionName, accordionContent}) => {
        state[accordionName]["content"] = accordionContent;
    },
    [Mutation.SET_ACCORDION_FOOTER]: (state, {accordionName, accordionFooter}) => {
        state[accordionName]["footer"] = accordionFooter;
    },
    [Mutation.SET_LOADING_STATUS]: (state, status) => {
        state.loading = status
    },
}