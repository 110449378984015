<template>
    <div class="form-row section advanced-icscode" style="">
            <div class="form-group col-sm-4 col-md-4 col-lg-4 icsPublisherSearch">
                <FilterMultiSelect :placeholder="$t('fld_publisher')" name="publisher" field="categories.publisher" lookupField="standards.publisher"/>
                <input type="hidden" name="publisher" :value="getCategoriesFilter('publisher').id" :disabled="isAdvanceFilterDisabled('categories')"/>
            </div>
            <div class="form-group col-sm-4 col-md-4 col-lg-4 icsCountrySearch">
                <FilterMultiSelect :placeholder="$t('fld_country')" name="country" field="categories.country" lookupField="standards.country"/>
                <input type="hidden" name="country" :value="getCategoriesFilter('country').id" :disabled="isAdvanceFilterDisabled('categories')"/>
            </div>
            <div class="form-group col-sm-4 col-md-4 col-lg-4">
                <FilterMultiSelect :placeholder="$t('frm_current_label')" name="include[]" :multiple="true" field="categories.status" lookupField="standards.status" :searchable="false" :hasClear="false" :tagging="true"/>
                <input type="hidden" v-for="(status, index) in getCategoriesFilter('status')" :key="index"  name="include[]" :value="status.id" :disabled="isAdvanceFilterDisabled('categories')"/>
            </div>
        </div>
</template>
<script>
    import FilterMultiSelect from '@/components/SearchBar/SearchBarDesktop/FilterMultiSelect';
    import { createNamespacedHelpers } from 'vuex';
    const { mapGetters } = createNamespacedHelpers('searchbar');
    export default {
        name: "AdvanceSearchCategories",
        components: {
            FilterMultiSelect
        },
        computed: {
            ...mapGetters([
                'getCategoriesFilter',
                'isAdvanceFilterDisabled'
            ])
        }
    }
</script>

