<template>
    <div class="advanced-filter-row">
        <div class="form-row advanced-standards-filter">
            <div class="filterBy-header">
                {{$t('frm_filter_by')}} :
            </div>
            <AccordionFilterCheckbox :title="$t('frm_my_collections')"
                                     :searchable="true"
                                     :isActive="true"
                                     :hasClear="true"
                                     prefix="coll_"
                                     field="colls"
                                     labelBy="id"
                                     trackBy="value"
                                     name="filter[colls][]"/>

            <AccordionFilterCheckbox v-if="getSearchResultsFilterOptions('publishers').length"
                                     :title="$t('fld_publisher')"
                                     :searchable="true"
                                     :isActive="true"
                                     :hasClear="true"
                                     field="publishers"
                                     prefix="pub_"
                                     labelBy="id"
                                     trackBy="value"
                                     name="filter[publisher][]"/>

            <AccordionFilterCheckbox :title="$t('frm_company_collections')"
                                     :searchable="true"
                                     :hasClear="true"
                                     :isActive="true"
                                     prefix="dcol_"
                                     field="dcolls"
                                     labelBy="id"
                                     trackBy="value"
                                     name="filter[dcoll][]"/>

            <AccordionFilterCheckbox :title="$t('frm_current_label')"
                                     :hasClear="true"
                                     :isActive="isActiveStatus"
                                     prefix="inc_"
                                     field="include"
                                     labelBy="id"
                                     trackBy="value"
                                     name="filter[include][]"/>

            <AccordionFilterRadio :title="$t('frm_document_type')"
                                  prefix="typ_"
                                  field="types"
                                  labelBy="id"
                                  trackBy="value"
                                  name="filter[type][]"/>

            <AccordionFilterCheckbox v-if="getSearchResultsFilterSelectedValues('tags').length"
                                     :title="$t('frm_tags')"
                                     :searchable="true"
                                     :hasClear="true"
                                     prefix="tgs_"
                                     field="tags"
                                     labelBy="id"
                                     trackBy="value"
                                     name="filter[tags][]"/>


            <div class="clear-fields">
                <a href="#" v-on:click.prevent="reset()" class="clear-fields-filter"
                   :class="(getCanReset || enableByDefaultReset ) ? '' : 'deActive'">
                    {{ $t('frm_clear_all') }} </a>
            </div>
            <div class="apply-filter">
                <button class="sai-btn-primary" @click="applyFilter">{{ $t('frm_apply')}}</button>
            </div>
        </div>
    </div>
</template>

<script>
import {createNamespacedHelpers} from 'vuex';

const {mapActions, mapGetters} = createNamespacedHelpers('searchresults');
const {mapGetters: searechBarGetters} = createNamespacedHelpers('searchbar');

import AccordionFilterCheckbox from "../AccordionFilterCheckbox";
import AccordionFilterRadio from "@/components/SearchResultFacets/AccordionFilterRadio";

const fields = ['colls','publishers', 'include', 'dcolls', 'tags', 'types'];

export default {
    name: 'SearchResultFacetsCollections',
    components: {
        AccordionFilterCheckbox,
        AccordionFilterRadio
    },
    created() {
        this.isActiveStatus = !!this.getSearchResultsFilterSelectedValues('include').length;
    },
    data() {
        return {
            isActiveStatus: false
        }
    },
    computed: {
        ...mapGetters([
            'getCanReset',
            'getSearchResultsFilterSelectedValues',
            'getSearchResultsFilterOptions'
        ]),
        ...searechBarGetters([
            'getActiveSearchTab'
        ]),
        enableByDefaultReset() {
            let canReset = false;
            fields.forEach(field => {
                if (this.getSearchResultsFilterSelectedValues(field).length > 0) {
                    canReset = true;
                }
            });
            return canReset;
        }
    },
    methods: {
        ...mapActions([
            'setCurrentPage',
            'fetchItems',
            'resetFilters',
            'setSearchResultsFilterSelectedValues'
        ]),
        applyFilter() {
            window.scrollTo(0, 0);
            this.setCurrentPage(1);
            this.fetchItems('collections');
        },
        reset() {
            fields.forEach(field => {
                if (this.getSearchResultsFilterSelectedValues(field).length) {
                    if (field === 'types') {
                        this.setSearchResultsFilterSelectedValues({field: field, fieldType: 'radio', payload: ' '});
                    } else {
                        this.setSearchResultsFilterSelectedValues({field: field, fieldType: 'checkbox', payload: []})
                    }
                }

            });
            this.applyFilter();
        }
    }
}
</script>
