<template>
<div class="materials-quick-search" style="">
        <div class="materials-col materials-name-col">
            <SaiAutoSuggest 
            labelBy="label" 
            trackBy="value"
            className="search-box materials-inputs materialsSection form-control shadow-none form-control-text row1-font title-field ui-autocomplete-input"
            api="/management/search/field/material_trade_name"
            :apiExtraParams="{type: 'material_trade_name'}"
            apiHeaderContentType="form-urlencoded"
            :placeholder="$t('frm_trade_name')"
            name="material_trade_name" id="material_trade_name"
            :value="getMaterialsFilter('tradeName')"
            @input="$event => setMaterialsFilter({field: 'tradeName', payload: $event})"
            @addSuggestValue="$event => setMaterialsFilter({field: 'tradeName', payload: $event})" :disabled="!isActiveTab('materials')"/>
        </div>

        <div class="materials-col materialsDescriptionSearch">
            <FilterMultiSelect name="material_description" :placeholder="$t('frm_description')" field="materials.description"/>
            <input type="hidden" name="material_description" :value="getMaterialsFilter('description').id" :disabled="!isActiveTab('materials')"/>
        </div>

        <div class="materials-col materialsClassSearch">
            <FilterMultiSelect name="material_class" :placeholder="$t('frm_class')" field="materials.class"/>
            <input type="hidden" name="material_class" :value="getMaterialsFilter('class').id" :disabled="!isActiveTab('materials')"/>
        </div>

        <div class="materials-col materialsFormSearch">
            <FilterMultiSelect :multiple="true" name="material_form[]" :placeholder="$t('frm_form')" field="materials.form" :searchable="false"/>
            <input v-for="(form, index) in getMaterialsFilter('form')" :key="index" type="hidden" name="material_form[]" :value="form.id" :disabled="!isActiveTab('materials')"/>
        </div>

        <div class="materials-col materialsTypeSearch">
            <FilterMultiSelect name="material_product_type" :placeholder="$t('frm_material_type')" field="materials.productType"/>
            <input type="hidden" name="material_product_type" :value="getMaterialsFilter('productType').id" :disabled="!isActiveTab('materials')"/>                       
        </div>

        <div class="materials-col materialsAdditiveSearch">
            <FilterMultiSelect :multiple="true" name="material_additive[]" :placeholder="$t('frm_additives')" field="materials.additives" :searchable="false"/>
            <input v-for="(additive, index) in getMaterialsFilter('additives')" :key="index" type="hidden" name="material_additive[]" :value="additive.id" :disabled="!isActiveTab('materials')"/>
        </div>

        <div class="materials-col materialsKeywordSearch">
            <SaiInput class="materialsSection form-control form-control-select shadow-none form-control-dropdown form2row form-control-text-second-row"
                  :placeholder="$t('frm_material_keyword')" name="material_keyword" id="material_keyword"
                  :value="getMaterialsFilter('keyword')"
                  @input="$event => setMaterialsFilter({field: 'keyword', payload: $event})" :disabled="!isActiveTab('materials')"/>
        </div>
    </div>
</template>
<script>
    import SaiAutoSuggest from '@/components/common/SaiAutoSuggest';
    import SaiInput from '@/components/common/SaiInput';
    import FilterMultiSelect from '@/components/SearchBar/SearchBarDesktop/FilterMultiSelect';
    import { createNamespacedHelpers } from 'vuex';
    const { mapGetters, mapActions } = createNamespacedHelpers('searchbar');
    export default {
        name: "QuickSearchMaterial",
        components: {
            FilterMultiSelect,
            SaiAutoSuggest,
            SaiInput
        },
        computed: {
            ...mapGetters([
                'getMaterialsFilter',
                'isActiveTab'
            ])
        },
        methods: {
            ...mapActions([
                'setMaterialsFilter'
            ])
        }
    }
</script>
<style lang="scss">
.materials-name-col {
    input {
        width: 100% !important;
    }
}
</style>