<template>
    <div :class="getLoadingStatus ? 'home-shadow-loading': '' ">
        <QuickViews/>
    </div>
</template>

<script>
import {createNamespacedHelpers} from "vuex";
const {mapGetters} = createNamespacedHelpers('quickviews');

import QuickViews from "@/components/QuickViews/QuickViews";

export default {
    name: "index",
    components: {
        QuickViews
    },
    computed: {
        ...mapGetters([
            'getLoadingStatus'
            ]
        ),
    }
}
</script>


<style lang="scss">
    @import "quickViews";
</style>