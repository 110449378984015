import Mutation from './mutationTypes';

export default {
    [Mutation.SET_PWA_STATUS]: (state, status) => {
      state.PWAStatus = status
    },
    [Mutation.SET_PWA_NAV_OVERLAY_VARS]: (state, vars) => {
        state.pwaNavOverlayVars = vars
    },
    [Mutation.TOGGLE_PWA_NAV_OVERLAY_LEFT]: (state, status) => {
        state.pwaNavOverlayLeftVisibility = status
    },
    [Mutation.TOGGLE_PWA_NAV_OVERLAY_RIGHT]: (state, status) => {
        state.pwaNavOverlayRightVisibility = status
    },
    [Mutation.SET_USER_PROFILE]: (state, userProfile) => {
        state.subId = userProfile.subId;
        state.searchTabs = userProfile.searchTabs;
        state.userProfile = userProfile;
    },
    [Mutation.SET_DISPLAY_SEARCH]: (state, blnDisplay) => {
        state.displaySearch = blnDisplay;
    },
    [Mutation.TOGGLE_SEARCH_BAR]: (state) => {
        state.displaySearch = !state.displaySearch;
    },
    [Mutation.SET_INITIALIZE_SEARCH_BAR_FILTERS]: (state, searchBarFilterValues) => {
        state = Object.assign(state, searchBarFilterValues);
    },
    [Mutation.SET_ACTIVE_SEARCH_TAB]: (state, activeSearchTab) => {
        state.activeSearchTab = activeSearchTab;
    },
    [Mutation.SET_SEARCH_TABS]: (state, searchTabs) => {
        state.searchTabs = searchTabs;
    },
    [Mutation.SET_SUB_ID]: (state, subId) => {
        state.subId = subId;
    },
    [Mutation.SET_RECENTLY_VIEWED_DOCS]: (state, recentlyViewedDocs) => {
        state.recentlyViewedDocs = recentlyViewedDocs;
    },
    [Mutation.SET_ADVANCE_SEARCH_DISPLAY]: (state, {tab, blnDisplay}) => {
        state[tab].displayAdvanceSearch = blnDisplay;
    },
    [Mutation.ALLOW_ADVANCE_SEARCH]: (state, {tab, blnAllow}) => {
        state[tab].allowAdvanceSearch = blnAllow;
    },
    [Mutation.SET_LOOKUP_OPTIONS]: (state, {type, options}) => {
        state.lookUpOptions[type] = options;
    },

    //standards mutations
    [Mutation.SET_STANDARDS_FILTER]: (state, { field, payload}) => {
        state.standards[field] = payload;
    },

    //collections mutations
    [Mutation.SET_COLLECTIONS_FILTER]:(state, {field, payload}) => {
        state.collections[field] = payload;
    },

    //categories mutations
    [Mutation.SET_CATEGORIES_FILTER]:(state, {field, payload}) => {
        state.categories[field] = payload;
    },
 
    //latest mutations
    [Mutation.SET_LATEST_FILTER]:(state, {field, payload}) => {
        state.latest[field] = payload;
    },
    
    //metals mutations
    [Mutation.SET_METALS_COMPOSITION_FILTERS]: (state, {type, compositions})  => {
        state.metals[type].filters = [...compositions];
    },
    [Mutation.SHOW_METALS_COMPOSITION_FILTERS]: (state, {type, show})  => {
        state.metals[type].showFilters = show;
    },
    [Mutation.SET_METALS_COMPOSITION_FILTERS_ERR_MSG]: (state, {type, errMsg}) => {
        state.metals[type].errMsg = errMsg;
    },
    [Mutation.SET_METALS_FILTER]: (state, { field, payload}) => {
        state.metals[field] = payload;
    },
    

    //metals supplier mutations
    [Mutation.SET_METALS_SUPPLIER_FILTER]: (state, { field, payload}) => {
        state.metalsSupplier[field] = payload;
    },

    
    //materials mutations
    [Mutation.SET_MATERIALS_PROPERTY_FILTERS]: (state, {type, properties})  => {
        state.materials[type].filters =  [...properties];
    },
    [Mutation.SHOW_MATERIALS_PROPERTY_FILTERS]: (state, {type, show})  => {
        state.materials[type].showFilters = show;
    },
    [Mutation.SET_MATERIALS_PROPERTY_FILTERS_ERR_MSG]: (state, {type, errMsg}) => {
        state.materials[type].errMsg = errMsg;
    },
    [Mutation.SET_MATERIALS_FILTER]: (state, { field, payload}) => {
        state.materials[field] = payload;
    },
    
    
    //materials supplier mutations
    [Mutation.SET_MATERIALS_SUPPLIER_FILTER]: (state, { field, payload}) => {
        state.materialsSupplier[field] = payload;
    },

    [Mutation.SET_SEARCH_BAR_FIELD]: (state, { field, payload}) => {
        state[field] = payload;
    }
}