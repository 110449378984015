import Mutation from './mutationTypes';
import {
    apiGetTourGuides,
    apiUpdateTourGuideSettings
} from "@/components/TourGuide/api"

export default {
    fetchTourGuides: async ({commit}) => {
        try {
            commit(Mutation.SET_LOADING_STATUS, true);
            let tourGuides = await apiGetTourGuides();
            commit(Mutation.SET_TOUR_GUIDES, tourGuides);
            commit(Mutation.SET_LOADING_STATUS, false);
        } catch (error) {
            console.log('apiGetTourGuides failed.' + error);
            commit(Mutation.SET_LOADING_STATUS, false);
        }
    },
    updateTourGuideSettings: async ({commit, getters}, id) => {
        const payload = getters.getTourGuides;
        payload.forEach(tourGuide => {
            if (parseInt(tourGuide.id) === parseInt(id)) {
                tourGuide['completed'] = true
            }
        })
        commit(Mutation.UPDATE_TOUR_GUIDE_SECTION, payload);
        await apiUpdateTourGuideSettings(getters.getTourGuides, id);
    }

}