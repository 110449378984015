<template>
    <div>
        <div v-for="content in bodyContent" class="accordion-plain-content" :key="content.id">
            <span>{{ content.note }}</span>
            <span class="plain-content-link">
                <a :href="content.linkURL">
                    {{ content.linkName }}
                </a>
                &rarr;
            </span>
        </div>
    </div>
</template>

<script>
export default {
    name: "PlainContent",
    props: {
        bodyContent: {type:Array,default:()=>{}}
    }
}
</script>

<style lang="scss" scoped>
    .accordion-plain-content {
        display: flex;
        margin: 10px 0;
        span {
            margin-right: 20px;
            a {
                font-weight: 700;
                text-decoration: underline;
                color:#5D4266;
            }
        }
        @media (max-width: 768px) {
            flex-direction: column;
            .plain-content-link {
                margin: 10px 0;
                text-align: center;
            }
        }
    }
</style>