<template>
    <div :class="['box-accordion', isActiveAccordion ? 'accordion__active': 'accordion__collapsed']">
        <div class="accordion-header" @click="isActiveAccordion = !isActiveAccordion">
           <span>{{ title }}</span>
            <span :class="['accordion-header-arrow', isActiveAccordion ? 'active' : '' ]"></span>
        </div>
        <div v-show="isActiveAccordion" class="accordion-body">
            <div v-if="hasClear" class="clear-select">
                <span @click="clearSelected">{{ $t('clear') }}</span>
            </div>
            <div v-if="searchable && getSearchResultsFilterOptions(field).length >= 6">
                <SaiInput class="sai-input" v-model="search" :placeholder="$t('frm_generic')" type="text"/>
            </div>
            <div class="accordion-content">
                <SaiCheckbox
                        :value="getSearchResultsFilterSelectedValues(field)"
                        :options="filteredOptions"
                        :name="name"
                        @change="handleCheckboxChange"
                        :labelBy="labelBy"
                        :trackBy="trackBy"
                        :prefix="prefix"/>
            </div>
        </div>
    </div>

</template>

<script>
    import {createNamespacedHelpers} from 'vuex';
    const {mapGetters, mapActions} = createNamespacedHelpers('searchresults');

    import SaiCheckbox from '@/components/common/SaiCheckbox';
    import SaiInput from '@/components/common/SaiInput';
    export default {
        name: "AccordionFilterCheckbox",
        props: {
            title: {type:String, default:''},
            isActive: {type: Boolean, default: false},
            hasClear:{type: Boolean, default: false},
            searchable:{type: Boolean, default: false},
            options: {type: Array, default: () => []},
            field: { type: String, default: '', required: true },
            name: {type: String},
            value: {type: String},
            labelBy: {type: String, default: 'key'},
            trackBy: {type: String, default: 'val'},
            prefix: {type: String, default: ''}
        },
        data() {
            return{
                search: '',
                isActiveAccordion: this.isActive
            }
        },
        components: {
            SaiCheckbox,
            SaiInput
        },
        computed: {
            ...mapGetters([
                'getSearchResultsFilterOptions',
                'getSearchResultsFilterSelectedValues'
            ]),
            filteredOptions() {
                return this.getSearchResultsFilterOptions(this.field).filter(option => {
                    return option.value.toLowerCase().includes(this.search.toLowerCase())
                })
            },
        },
        methods: {
            ...mapActions([
                'setSearchResultsFilterSelectedValues',
                'setCanReset'
            ]),
            clearSelected: function(){
              this.setCanReset(true);
              this.setSearchResultsFilterSelectedValues({ field:this.field,fieldType:'checkbox', payload:[]});
            },
            handleCheckboxChange: function($arrValues) {
              this.setCanReset(true);
              this.setSearchResultsFilterSelectedValues({ field:this.field ,fieldType:'checkbox', payload:$arrValues});
            }
        }
    }
</script>
