import Mutation from './mutationTypes';

export default {
    [Mutation.SET_PWA_STATUS]: (state, value) => {
        state.isPWA = value;
    },
    [Mutation.SET_ORDER_BY]: (state, value) => {
        state.orderBy = value;
    },
    [Mutation.SET_CAN_RESET]: (state, blnState) => {
        state.canReset = blnState;
    },
    [Mutation.SET_PAGE_NUMBER]: (state, pageNumber) => {
        state.page = pageNumber;
    },
    [Mutation.SET_LOADING_STATUS]: (state, blnStatus) => {
        state.loading = blnStatus;
    },
    [Mutation.SET_LOADMORE_LOADING_STATUS]: (state, status) => {
        state.loadingLoadMore = status;
    },
    [Mutation.SET_SEARCH_PROGRESS_STATUS]: (state, status) => {
        state.searchProgressStatus = status;
    },
    [Mutation.SET_IS_SAVED_SEARCH]: (state, isSavedSearch) => {
        state.isSavedSearch = isSavedSearch;
    },
    [Mutation.SET_SEARCH_RESULTS]: (state, searchResultsValues) => {
        Object.assign(state, searchResultsValues);
    },
    [Mutation.SET_SEARCH_RESULTS_PWA]: (state, searchResultsValues) => {
        state.allRecords = state.records ?? [];
        state = Object.assign(state, searchResultsValues);
        state.allRecords = [...state.allRecords, ...searchResultsValues.records];
        state.records = state.allRecords;
    },
    [Mutation.SET_BULK_SELECTED_RECORDS]: (state, bulkSelectedRecords) => {
        state.bulkSelectedRecords = bulkSelectedRecords;
    },
    [Mutation.SET_IS_SEARCH_RESULT_ACTIVE]: (state, isSearchResultActive) => {
        state.isSearchResultActive = isSearchResultActive;
    },
    [Mutation.SET_SEARCH_RESULTS_FILTER_SELECTED_VALUES]: (state, {field, fieldType ,payload}) => {
        if (fieldType === 'checkbox') {
            const filteredPayload = payload.map(payl => String(payl));
            for (let value of state['filters'][field]) {
                value.selected = (filteredPayload.indexOf(String(value.id)) >= 0 ) ? true : false;
            }
        } else if (fieldType === 'radio'){
            for (let value of state['filters'][field]) {
                value.selected = (String(payload) === String(value.id)) ? true : false;
            }
        } else {
            state['filters'][field].value = payload;
        }
    },
    [Mutation.SET_ACTIVE_SEARCH_TAB]: (state, activeSearchTab) => {
        state.activeSearchTab = activeSearchTab;
    },
    [Mutation.SET_DOC_ROW_ACTION_PROGRESS]: (state, { action, docIds, actionProcessing, bulkActionProcessing }) => {
        for (let docid of docIds) {      
            for (let key of Object.keys(state['records'])) {
                if(state['records'][key]['doc_id'] == docid) {
                    state['records'][key][action + 'Processing'] = actionProcessing;
                    state['records'][key]['bulkActionProcessing'] = bulkActionProcessing;
                    break;
                }
            }
        }
        let records = [];
        state['records'] = Object.assign(records, state['records']);
    },
    [Mutation.SET_ROW_FIELDS]: (state, { docId, fieldValues }) => {
        for (let key of Object.keys(state['records'])) {
            if(state['records'][key]['doc_id'] == docId) {
                let docRecord = state['records'][key];
                state['records'][key] = Object.assign(docRecord, fieldValues);
                break;
            }
        }
        state['records'] = Object.assign([], state['records']);
    },
    [Mutation.SET_TOASTR_MESSAGE]: (state, { type, message }) => {
        state['toastr']['type'] = type;
        state['toastr']['message'] = message;
    },
    [Mutation.SET_SEARCH_RESULT_FIELD]: (state, { field, value }) => {
        state[field] = value;
    },
    [Mutation.SET_EXPORT_STATUS]: (state, exportStatus) => {
        state.exportStatus = exportStatus;
    },
    [Mutation.SET_SELECTED_FIELDS_FOR_EXPORT]: (state, selectedfieldsForExport) => {
        state.selectedfieldsForExport = selectedfieldsForExport;
    },
    [Mutation.SET_EXPORT_RESULT]: (state, exportResult) => {
        state.exportResult =  exportResult;
    },
    [Mutation.SET_EXPORT_CSV_SEPARATOR]: (state, exportCsvSeparator) => {
        state.exportCsvSeparator =  exportCsvSeparator;
    }
}