<template>
    <div class="form-row section advanced-metalsSupplier" style="">
            <div class="form-group col-sm-4 col-md-4 col-lg-4 metalsSupplierCountrySearch">
                <FilterMultiSelect :placeholder="$t('fld_country')" name="metals_supplier_country" field="metalsSupplier.country" lookupField="metals.country"/>
                <input type="hidden" name="metals_supplier_country" :value="getMetalsSupplierFilter('country').id" :disabled="isAdvanceFilterDisabled('metalsSupplier')"/>

            </div>
            <div class="form-group col-sm-4 col-md-4 col-lg-4 metalsSupplierFormSearch">
                <FilterMultiSelect :multiple="true" :placeholder="$t('frm_form')" name="metals_supplier_form[]" field="metalsSupplier.form" lookupField="metals.form"/>
                <input v-for="(form, index) in getMetalsSupplierFilter('form')" :key="index" type="hidden" name="metals_supplier_form[]" :value="form.id" :disabled="isAdvanceFilterDisabled('metalsSupplier')"/>
            </div>
            <div class="form-group col-sm-4 col-md-4 col-lg-4 metalsSupplierEstablishmentTypeSearch">
                <FilterMultiSelect :placeholder="$t('frm_establishment_type')" name="metals_supplier_establishment_type" field="metalsSupplier.establishmentType"/>
                <input type="hidden" name="metals_supplier_establishment_type" :value="getMetalsSupplierFilter('establishmentType').id" :disabled="isAdvanceFilterDisabled('metalsSupplier')"/>
            </div>
           <Description />

            <div class="form-group col-sm-2 col-md-4 col-lg-4 metalsSupplierKeywordSearch">
                <SaiInput  class="metalsSupplierSection form-control form-control-select shadow-none form-control-dropdown form2row form-control-text-second-row"
                    :placeholder="$t('frm_supplier_keywords')" name="metals_supplier_keyword" id="metals_supplier_keyword"
                    :value="getMetalsSupplierFilter('keyword')"
                    @input="$event => setMetalsSupplierFilter({field: 'keyword', payload: $event})" :disabled="isAdvanceFilterDisabled('metalsSupplier')"/>
            </div>
        </div>
</template>
<script>
    import FilterMultiSelect from '@/components/SearchBar/SearchBarDesktop/FilterMultiSelect';
    import Description from "@/components/SearchBar/SearchBarDesktop/MetalSuppliers/Description";
    import SaiInput from '@/components/common/SaiInput';

    import { createNamespacedHelpers } from 'vuex';
    const { mapGetters, mapActions } = createNamespacedHelpers('searchbar');
    export default {
        name: "AdvanceSearchMetalSupplier",
          components: {
            FilterMultiSelect,
            SaiInput,
            Description
        },
        computed: {
            ...mapGetters([
                'getMetalsSupplierFilter',
                'isAdvanceFilterDisabled'
            ])
        },
        methods: {
            ...mapActions([
                'setMetalsSupplierFilter'
            ])
        }
    }
</script>
<style lang="scss">
.metals-supplier-description {
    border: 1px solid #5d4266 !important;
    width: 100% !important;
}
</style>