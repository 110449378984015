import Mutation from '@/components/SearchResults/store/mutationTypes';
const handleSearchUISideEffects = store => {
    store.subscribe((mutation) => {
        if (mutation.type === ("searchresults/" + Mutation.SET_IS_SEARCH_RESULT_ACTIVE)) {
            const payload = mutation.payload;
            if(payload == 1) {
                let contentPanes = document.querySelectorAll('[id=contentPane]');
                //remove all other content to display the search result in other pages.
                for(let i =0; i< contentPanes.length; i++)  {
                    if(!contentPanes[i].classList.contains('searchResultsPane')) {
                        contentPanes[i].parentNode.removeChild(contentPanes[i]);
                    }
                }
            }
        }
        if (mutation.type === ("searchresults/" + Mutation.SET_LOADING_STATUS)) {
            //for ajax search result loading, remove quick view css if there is one
            document.getElementById('bodyPane').classList.remove('quickView-background');
        }
    });
}


const hideShowSearchBarAdvanceFilters = store => {
    store.subscribe((mutation) => {
        if (mutation.type === ("searchresults/" + Mutation.SET_SEARCH_RESULTS)) {
            let tabs = [ 'standards', 'collections', 'latest', 'categories', 'metals', 'metalsSupplier', 'materials', 'materialsSupplier'];
            for(let tab of tabs) {
                store.dispatch('searchbar/allowAdvanceSearch', { tab, blnAllow: true });
            }
            //hide the advance serach options in search bar
            const searchResults = mutation.payload; 
            if(searchResults.records &&  searchResults.records.length > 0) {
                const activeSearchTab = store.getters['searchbar/getActiveSearchTab'];
                store.dispatch('searchbar/allowAdvanceSearch', { tab: activeSearchTab, blnAllow: false });
            }

            //change page title from "i2i - Home" to "i2i - Search";
            document.title = "i2i - Search";
        }
    });
}

const showToastrMsg = store => {
    store.subscribe((mutation) => {
        if (mutation.type === ("searchresults/" + Mutation.SET_TOASTR_MESSAGE)) {
            const payload = mutation.payload;
            switch(payload.type) {
                case 'error':
                    store._vm.$toastr.e(payload.message);
                    break;
                case 'success':
                    store._vm.$toastr.s(payload.message);
                    break;
                case 'warning':
                    store._vm.$toastr.w(payload.message);
                    break;
                case 'info':
                    store._vm.$toastr.i(payload.message);
                    break;
            }
            //reset toaster message
            store.dispatch('searchbar/setToastrMsg', { type: '',  message: '' });
        }
    });
}

export default [
    handleSearchUISideEffects,
    hideShowSearchBarAdvanceFilters,
    showToastrMsg
];