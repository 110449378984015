<template>
    <multiSelect :placeholder="placeholder"
                 :class="className"
                 :options="options"
                 v-on="inputListeners"
                 :value="value"
                 :show-labels="false"
                 :label="labelBy"
                 :track-by="trackBy"
                 :openDirection="openDirection"
                 :resetAfter="resetAfter"
                 :searchable="searchable">
    </multiSelect>
</template>

<script>
    import multiSelect from '@/plugins/multiSelect';
    export default {
        name: "SaiSelectSingle",
        model: {
            prop: 'value',
            event: 'change'
        },
        props:{
            options: {},
            className: {},
            searchable: {type:Boolean, default:false},
            placeholder: {type:String, default: ''},
            value: {type:String, default: ''},
            labelBy: {type:String, default: ''},
            trackBy:{type:String, default: ''},
            openDirection: {type:String, default: 'below'},
            resetAfter: {type: Boolean, default:false}
        },
        components: {
            multiSelect,
        },
        computed: {
            inputListeners: function () {
                var vm = this;
                return Object.assign({},
                    this.$listeners,
                    {
                        input: function (event) {
                            vm.$emit('change', event)
                        }
                    }
                )
            }
        },
    }
</script>

<style scoped>

</style>