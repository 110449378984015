<template>
    <div :class="['box-accordion', isActive ? 'accordion__active': 'accordion__collapsed']">
        <div class="accordion-header" @click="isActive = !isActive">
            <span>{{ title }}</span>
            <span :class="['accordion-header-arrow', isActive ? 'active' : '' ]"></span>
        </div>
        <transition name="accordion"
                    v-on:before-enter="beforeEnter" v-on:enter="enter"
                    v-on:before-leave="beforeLeave" v-on:leave="leave">
            <div v-show="isActive" class="accordion-body">
                <div v-if="hasClear" class="clear-select">
                    <span @click="clearSelected">{{ $t('clear') }}</span>
                </div>
                <div v-if="searchable">
                    <SaiInput class="sai-input" v-model="search" :placeholder="$t('frm_generic')" type="text"/>
                </div>
                <div class="accordion-content">
                    <SaiRadio :name="name"
                              :prefix="prefix"
                              :value="getSearchResultsFilterSelectedValues(field)"
                              :options="filteredOptions"
                              @change="handleCheckboxChange"
                              :labelBy="labelBy"
                              :trackBy="trackBy"/>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    import {createNamespacedHelpers} from 'vuex';
    const {mapGetters, mapActions} = createNamespacedHelpers('searchresults');
    import SaiInput from '@/components/common/SaiInput';
    import SaiRadio from "@/components/common/SaiRadio";

    export default {
        name: "AccordionFilterRadio",
        props: {
            title: {type: String, default: ''},
            isActive: {type: Boolean, default: false},
            hasClear: {type: Boolean, default: false},
            searchable: {type: Boolean, default: false},
            options: {type: Array, default: () => []},
            field: { type: String, default: '', required: true },
            name: {type: String},
            value: {type: String},
            labelBy: {type: String, default: 'key'},
            trackBy: {type: String, default: 'val'},
            prefix: {type: String, default: ''}
        },
        data() {
            return {
                search: ''
            }
        },
        components: {
            SaiInput,
            SaiRadio
        },
        computed: {
            ...mapGetters([
                'getSearchResultsFilterOptions',
                'getSearchResultsFilterSelectedValues'
            ]),
            filteredOptions() {
                return this.getSearchResultsFilterOptions(this.field).filter(option => {
                    return option.value.toLowerCase().includes(this.search.toLowerCase())
                })
            }
        },
        methods: {
            beforeEnter: function(el) {
                el.style.height = '0';
            },
            enter: function(el) {
                el.style.height = el.scrollHeight + 'px';
            },
            beforeLeave: function(el) {
                el.style.height = el.scrollHeight + 'px';
            },
            leave: function(el) {
                el.style.height = '0';
            },
            ...mapActions([
                'setSearchResultsFilterSelectedValues',
                'setCanReset'
            ]),
            ...mapActions([
                'setSearchResultsFilterSelectedValues'
            ]),
            clearSelected: function () {
                this.setSearchResultsFilterSelectedValues({ field:this.field,fieldType:'radio', payload:''});
            },
            handleCheckboxChange: function($event) {
                this.setSearchResultsFilterSelectedValues({ field:this.field,fieldType:'radio', payload:$event});
            }
        }
    }
</script>
