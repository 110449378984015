<template>
  <div>
      <template v-if="isActivePWA">
          <SearchBarPwa class="searchBarPwa"/>
      </template>
      <template v-else>
          <SearchBarDesktop class="searchBarDesktop"/>
          <SearchBarMobile class="searchBarMobile"/>
      </template>

      <PortalTarget name="sai-modal" slim/>
      <PortalTarget name="sai-dropdown-options" slim/>
  </div>
</template>

<script>
import SearchBarDesktop from './SearchBarDesktop';
import SearchBarPwa from './SearchBarPwa';
import SearchBarMobile from './SearchBarMobile';
import {createNamespacedHelpers} from "vuex";

const {mapActions, mapGetters} = createNamespacedHelpers('searchbar');
const user_profile = window.user_profile || '';
const search_bar_vars = window.search_bar_vars || "";

//check if  PWA as Active
let isActivePWA = false;
if (window.matchMedia('(display-mode: standalone)').matches) {
     isActivePWA = true;
}

export default {
  name: 'SearchBar',
  components: {
    SearchBarDesktop,
    SearchBarMobile,
    SearchBarPwa
  },
  created() {
      const y=window.matchMedia('(display-mode: standalone)');
      if (y.addEventListener) {
          y.addEventListener('change', ({ matches }) => {
              if (!matches) {
                  this.changePWAStatus(false);
              } else {
                  this.changePWAStatus(true);
                  this.setDisplaySearch(false);
                  this.setAdvanceSearchDisplay({ tab: 'standards', blnDisplay: false });
                  this.setAdvanceSearchDisplay({ tab: 'collections', blnDisplay: false });
              }
          });
      }


    if (typeof (user_profile) != "undefined" && user_profile !== '') {
      // console.log('user_profile', user_profile, JSON.parse(Buffer.from(user_profile, "base64")));
      this.setUserProfile(JSON.parse(Buffer.from(user_profile, "base64")));
    }
    
    if (typeof (search_bar_vars) != "undefined" && search_bar_vars !== '') {
      // console.log('search_bar_vars', search_bar_vars, JSON.parse(Buffer.from(search_bar_vars, "base64")));
      let searchbarVars = JSON.parse(Buffer.from(search_bar_vars, "base64"));
      searchbarVars.activeSearchTab = this.getTabIdByValue(searchbarVars.activeSearchTab);
      this.initializeSearchBarFilters(searchbarVars);
      this.setDisplaySearch(searchbarVars.displaySearch);
    }

    if (isActivePWA) {
       this.setPWAStatus(true);
       this.setDisplaySearch(false);
       this.setAdvanceSearchDisplay({ tab: 'standards', blnDisplay: false });
       this.setAdvanceSearchDisplay({ tab: 'collections', blnDisplay: false });
    }
  },
  computed: {
    ...mapGetters([
      'getTabIdByValue',
       'isSearchOpen',
       'isActivePWA',
       'getActiveSearchTab'
    ])
  },
  methods: {
    ...mapActions([
      'setDisplaySearch',
      'setUserProfile',
      'initializeSearchBarFilters',
      'setPWAStatus',
       'setAdvanceSearchDisplay'
    ]),
      changePWAStatus(status) {
          this.setPWAStatus(status);
      }
  }
}
</script>
<style lang="scss">
@import "./searchBar";
</style>