<template>
  <div class="search-results-header">
    <div class="search-results-actions">
      <template  v-if="getSearchResultsField('showToolbar')">
        <div class="search-results-choose"><i class="si si-list_arrow"></i>{{ $t('frm_Choose') }}</div>
        <SearchResultsBulkActions :actions="getToolbarActions"/>
      </template>
    </div>

    <div class="search-tools" v-if="!isLiteSubscription">
      <div class="search-results-exp">

        <div v-if="isSavedSearch" class="search-tools-delete">
          <i class="si si-star si-2x"></i>{{ $t('frm_ss') }}
        </div>
        <a v-if="!isSavedSearch" class="search-tools-save" @click="displaySaveSearchModal=true">
          <i class="si si-star-o si-2x"></i>{{ $t('frm_save_search') }}
        </a>

        <SaveSearchModal :display="displaySaveSearchModal" @closeModal="displaySaveSearchModal=false"/>

        <div class="search-tools-exportBtn sai-btn-action tes" @click="displayExport=true">{{ $t('export') }}</div>
        <SearchResultExport :display="displayExport" :fieldOptions="exportFieldOptions" @close="displayExport=false"/>
      </div>

      <div class="filterBar" v-if="getSearchResultsField('showFilterSort')">
        <p>
          <span>{{ $t('frm_sortBy') }}</span>
         

          <SaiSelectSingle labelBy="key" trackBy="val"
                          name="orderby"
                          class="bulkAction"
                          :reset-after="true"
                          @change="updateOrderBy"
                          :placeholder="sortOptionsPlaceHolder"
                          :options="sortOptions"/>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import {createNamespacedHelpers} from 'vuex';

const {mapGetters, mapActions} = createNamespacedHelpers('searchresults');
const { mapGetters: searechBarGetters } = createNamespacedHelpers('searchbar');

import SaveSearchModal from '../SaveSearchModal'
import SaiSelectSingle from '@/components/common/SaiSelectSingle';
import SearchResultsBulkActions from '../SearchResultsBulkActions';
import SearchResultExport from '../SearchResultExport';
import i18n from "@/plugins/i18n";

export default {
  name: 'SearchResultsHeader',
  data() {
    return {
      displaySaveSearchModal: false,
      displayExport: false,
      exportFieldOptions: [
        {key: 'docId', val: 'Doc Id'},
        {key: 'name', val: 'Name'},
        {key: 'title', val: 'Title'},
        {key: 'status', val: 'Status'},
        {key: 'language', val: 'Language'},
        {key: 'publisher', val: 'Publisher'},
        {key: 'country', val: 'Country'},
        {key: 'latestVersion', val: 'Latest Version'},
        {key: 'versionDate', val: 'Version Date'},
        {key: 'summary', val: 'Summary'},
      ]
    };
  },
  components: {
    SearchResultsBulkActions,
    SearchResultExport,
    SaveSearchModal,
    SaiSelectSingle
  },
  methods: {
    ...mapActions([
      'setOrderBy',
      'fetchItems'
    ]),
    updateOrderBy($event) {
      if (String($event.val) !== String(this.getSearchResultsField('orderBy')) ) {
          this.setOrderBy(String($event.val));
          this.fetchItems('standards')
      }
    }
  },
  computed: {
    ...mapGetters([
      'getSearchResultsField',
      'getToolbarActions',
      'isSavedSearch',
      'getSearchResults'
    ]),
    ...searechBarGetters([
      'isLiteSubscription'
    ]),
    sortOptions: function () {
      return [
        {key: i18n.t('frm_relevance'), val: 0},
        {key: i18n.t('frm_alphabetic'), val: 1},
      ];
    },
    sortOptionsPlaceHolder: function() {
      let orderBy = this.getSearchResultsField('orderBy') ;
      orderBy = orderBy ? orderBy : 0;
      return this.sortOptions[orderBy]['key'];
    }
  }
}
</script>

