<template>
     <form class="search_resutls_row_actions">
    <!-- <form method="post" :action="actions.url" style="margin:0px;padding:0px"> -->

        <!-- adding/removing -->
        <div type="submit" v-if="isActionAllowed('addItem')"
                class="si si-addcollection wxFormIconButton"
                :title="$t('frm_additem', [activeCollectionName])"
                :value="'add:' + record.doc_id"
                name="wxsubmit" @click.prevent="$event => rowAction($event, 'addItem')">
                 <Loading :active="getDocRowActionStatus('addItemProcessing')(rowIndex)" 
                :is-full-page="false" loader="dots" color='#202020' :width="20" :height="20"
                :can-cancel="false"
                backgroundColor="#ffffff"
                :opacity="0.8"
                :z-index="999"
                />
        </div>

        <div type="submit" v-if="isActionAllowed('remItem')"
                class="si si-added_collection wxFormIconButton"
                :title="$t('frm_remitem', [activeCollectionName])"
                :value="'rem:' + record.doc_id"
                name="wxsubmit" @click.prevent="$event => rowAction($event, 'remItem')">
                 <Loading :active="getDocRowActionStatus('remItemProcessing')(rowIndex)" 
                :is-full-page="false" loader="dots" color='#202020' :width="20" :height="20"
                :can-cancel="false"
                backgroundColor="#ffffff"
                :opacity="0.8"
                :z-index="999"
                />
        </div>


        <!-- watching/unwatching -->
        <div type="submit" v-if="isActionAllowed('watchItem')"
                class="si si-eye-s wxFormIconButton"
                :title="$t('frm_watchitem', [activeCollectionName])"
                :value="'watch:' + record.doc_id"
                name="wxsubmit" @click.prevent="$event => rowAction($event,'watchItem')">
                <Loading :active="getDocRowActionStatus('watchItemProcessing')(rowIndex)" 
                :is-full-page="false" loader="dots" color='#202020' :width="20" :height="20"
                :can-cancel="false"
                backgroundColor="#ffffff"
                :opacity="0.8"
                :z-index="999"
                />
        </div>

        <div type="submit" v-if="isActionAllowed('unWatchItemGlobal')"
                class="si si-eye wxFormIconButton"
                :title="$t('frm_unwatch_global_err_msg')"
                :value="'unwatch:' + record.doc_id"
                name="wxsubmit" @click.prevent="$event => rowAction($event, 'unWatchItemGlobal')">
                 <Loading :active="getDocRowActionStatus('unWatchItemGlobalProcessing')(rowIndex)" 
                :is-full-page="false" loader="dots" color='#202020' :width="20" :height="20"
                :can-cancel="false"
                backgroundColor="#ffffff"
                :opacity="0.8"
                :z-index="999"
                />
        </div>

        <div type="submit" v-if="isActionAllowed('unWatchItem')"
                class="si si-eye wxFormIconButton"
                :title="$t('frm_unwatchitem', [activeCollectionName])"
                :value="'unwatch:' + record.doc_id"
                name="wxsubmit" @click.prevent="$event => rowAction($event, 'unWatchItem')">
                 <Loading :active="getDocRowActionStatus('unWatchItemProcessing')(rowIndex)" 
                :is-full-page="false" loader="dots" color='#202020' :width="20" :height="20"
                :can-cancel="false"
                backgroundColor="#ffffff"
                :opacity="0.8"
                :z-index="999"
                />
        </div>
    <!-- </form> -->
    </form>
      
</template>
<script>
import Loading from 'vue-loading-overlay';
import {createNamespacedHelpers} from 'vuex';
const {mapGetters, mapActions} = createNamespacedHelpers('searchresults');
export default {
    name: "SearchResultExport",
    props: {
        record: { type: Object, default: () => {} },
        rowIndex: { type: Number, required: true },
    },
    components: {
        Loading
    },
    computed: {
        ...mapGetters([
            'getSearchResultsField',
            'getDocRowActionStatus',
            'getDocRowActions'

        ]),
        activeCollectionName() {
            return this.getSearchResultsField('activeCollectionName');
        }
    },
    methods: {
        ...mapActions([
            'performDocAction'
        ]),
        isActionAllowed(action) {
            const rowActions = this.getDocRowActions(this.rowIndex);
            return rowActions &&  
                Object.prototype.hasOwnProperty.call(rowActions, action) && 
                rowActions[action];
        },
        rowAction($event, action) {
            const collectionId = this.getSearchResultsField('activeCollectionId');
            this.performDocAction({action, collectionId, docIds: [this.record.doc_id], isBulkAction: 0});
        }
    }
}
</script>
<style lang="scss">
    .search_resutls_row_actions {
        .wxFormIconButton {
            position: relative;
            margin: .1em;
            width: 40px;
            height: 40px;
            border: 1px solid #e0e0df;
            border-radius: 3px;
            padding: 8px 3px;
            cursor: pointer;
            font-size: 20px;
        }
    }
</style>