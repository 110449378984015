<template>
  <div class="advanced-filter-row">
    <div class="form-row advanced-standards-filter">
      <div class="filterBy-header">
        <FilterResultsIn field="filterby"
                         prefix="typ_"
                         labelBy="id"
                         trackBy="value"
                         name="filter[filterby]"/>

        {{$t('frm_filter_by')}} :
      </div>
      <AccordionFilterCheckbox :title="$t('fld_publisher')"
                               :isActive="true"
                               :searchable="true"
                               :hasClear="true"
                               field="publisher"
                               prefix="pub_"
                               labelBy="id"
                               trackBy="value"
                               name="filter[publisher][]"/>

      <AccordionFilterCheckbox :title="$t('frm_current_label')"
                               :isActive="true"
                               prefix="inc_"
                               field="include"
                               labelBy="id"
                               trackBy="value"
                               name="filter[include][]"/>

      <AccordionFilterCheckbox :title="$t('fld_country')"
                               :searchable="true"
                               :hasClear="true"
                               :isActive="isActiveCountry"
                               prefix="country_"
                               field="country"
                               labelBy="id"
                               trackBy="value"
                               name="filter[country][]"/>

      <AccordionFilterCheckbox :title="$t('filter_language')"
                               :searchable="true" :hasClear="true"
                               :isActive="isActiveLanguage"
                               prefix="lng_"
                               field="language"
                               labelBy="id"
                               trackBy="value"
                               name="filter[language][]"/>

      <AccordionFilterCheckbox :title="$t('filter_year')"
                               :searchable="true" :hasClear="true"
                               :isActive="isActiveYear"
                               prefix="yr_"
                               field="year"
                               labelBy="id"
                               trackBy="value"
                               name="filter[year][]"/>

      <AccordionFilterInputSuggest name="filter[query5]" id="filter[query5]"
                            :placeholder="$t('frm_search_within')"
                            field="query5" searchTab="standards" paramType="name"
                            api="/management/search/xref_name_suggest"/>

      <div class="clear-fields">
        <a href="#" v-on:click.prevent="reset()" class="clear-fields-filter" :class="(getCanReset || enableByDefaultReset ) ? '' : 'deActive'">
          {{ $t('frm_clear_all') }} </a>
      </div>
      <div class="apply-filter">
        <button class="sai-btn-primary" @click="applyFilter">{{ $t('frm_apply')}}</button>
      </div>
    </div>
  </div>
</template>

<script>
import {createNamespacedHelpers} from 'vuex';

const {mapActions, mapGetters} = createNamespacedHelpers('searchresults');
const { mapGetters: searechBarGetters } = createNamespacedHelpers('searchbar');

import AccordionFilterCheckbox from "../AccordionFilterCheckbox";
import FilterResultsIn from './FilterResultsIn';
import AccordionFilterInputSuggest from "@/components/SearchResultFacets/AccordionFilterInputSuggest";
const fields = [ 'year', 'publisher','include','language','country' ];
export default {
  name: 'SearchResultFacetsStandards',
  components: {
    AccordionFilterInputSuggest,
    AccordionFilterCheckbox,
    FilterResultsIn
  },
  created() {
      this.isActiveCountry= !!this.getSearchResultsFilterSelectedValues('country').length;
      this.isActiveLanguage = !!this.getSearchResultsFilterSelectedValues('language').length;
      this.isActiveYear = !!this.getSearchResultsFilterSelectedValues('year').length;
  },
  data(){
    return {
      isActiveCountry: false,
      isActiveLanguage: false,
      isActiveYear: false,
    }
  },
  computed: {
      ...mapGetters([
          'getSubId',
          'getCanReset',
          'getSearchResultsFilterSelectedValues',
          'getSearchResultsFilterValue'
      ]),
      ...searechBarGetters([
          'getActiveSearchTab'
      ]),
      enableByDefaultReset() {
          let canReset = false;
          fields.forEach(field => {
              if (this.getSearchResultsFilterSelectedValues(field).length > 0) {
                  canReset = true;
              }
          });
          if (this.getSearchResultsFilterValue('query5')) {
              canReset = true;
          }
          return canReset;
      }
  },
  methods: {
    ...mapActions([
      'setCurrentPage',
      'fetchItems',
      'resetFilters',
      'setSearchResultsFilterSelectedValues'
    ]),
    applyFilter() {
      window.scrollTo(0, 0);
      this.setCurrentPage(1);
      this.fetchItems('standards');
    },
    reset() {
        fields.forEach(field => {
            this.setSearchResultsFilterSelectedValues({field:field,fieldType:'checkbox', payload:[]})
        });
        this.setSearchResultsFilterSelectedValues({field:'query5',fieldType:'input', payload:''});

     this.applyFilter();
    }
  }
}
</script>
