<template>
    <div class="standards-search standards-search-vue">
        <div class="standards-inputs">
            <SaiAutoSuggest labelBy="value" trackBy="label"
                            className="document-input form-control"
                            api="/api/search/suggest.php"
                            :apiExtraParams="{subId: getSubId, type: 'name'}"
                            apiHeaderContentType="form-urlencoded"
                            :placeholder="$t('frm_placeHolder_document_number')"
                            name="query_1" id="query_1"
                            :value="getStandardsFilter('documentNumber')"
                            :hasFieldReset="true"
                            @input="$event => setStandardsFilter({field: 'documentNumber', payload: $event})"
                            @addSuggestValue="handleDocumentNumberClick" :disabled="!isActiveTab('standards')"
                            @keyup.enter="performMainSearch"/>
            <ResetInput v-if="getStandardsFilter('documentNumber')" @clickHandler="setStandardsFilter({field: 'documentNumber', payload: ''})" />
        </div>

        <div class="standards-inputs">
            <SaiAutoSuggest labelBy="value" trackBy="label"
                            className="document-number-input search-box standardSection form-control shadow-none form-control-text standards-inputs row1-font ui-autocomplete-input"
                            api="/api/search/suggest.php"
                            :apiExtraParams="{subId: getSubId, type: 'title'}"
                            apiHeaderContentType="form-urlencoded"
                            :placeholder="$t('frm_placeHolder_document')"
                            name="query_2" id="query_2"
                            :value="getStandardsFilter('documentTitle')"
                            :hasFieldReset="true"
                            @resetField="$event => setStandardsFilter({field: 'documentTitle', payload: ''})"
                            @input="$event => setStandardsFilter({field: 'documentTitle', payload: $event})"
                            @addSuggestValue="handleDocumentTitleClick" :disabled="!isActiveTab('standards')"
                            @keyup.enter="performMainSearch"/>
            <ResetInput v-if="getStandardsFilter('documentTitle')" @clickHandler="setStandardsFilter({field: 'documentTitle', payload: ''})" />

        </div>

    </div>
</template>
<script>
import ResetInput from "@/components/common/ResetInput";
import SaiAutoSuggest from '@/components/common/SaiAutoSuggest';
import {createNamespacedHelpers} from 'vuex';

const {mapGetters, mapActions} = createNamespacedHelpers('searchbar');
const {mapActions: searchResultActions} = createNamespacedHelpers('searchresults');

export default {
    name: "QuickSearchStandard",
    components: {
        SaiAutoSuggest,
        ResetInput
    },
    computed: {
        ...mapGetters([
            'getSubId',
            'getStandardsFilter',
            'isActiveTab'
        ]),
    },
    methods: {
        ...mapActions([
            'setStandardsFilter'

        ]),
        ...searchResultActions([
            'performMainSearch',
            'setLoadingStatus'
        ]),
        handleDocumentNumberClick($event) {
            this.setStandardsFilter({field: 'documentNumber', payload: $event});
            this.$nextTick(() => {
                //submit search on document number selection from the auto suggested list
                this.$parent.$parent.$refs['desktopsearch'].submit();
                this.setLoadingStatus(true);
            });
        },
        handleDocumentTitleClick($event) {
            this.setStandardsFilter({field: 'documentTitle', payload: $event});
            this.$nextTick(() => {
                this.$parent.$parent.$refs['desktopsearch'].submit();
                this.setLoadingStatus(true);
            });
        }
    }
}
</script>

<style lang="scss">
@import "./standards";
</style>

