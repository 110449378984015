<template>
    <div :class="display ? 'sai-mobile-modal show' : 'sai-mobile-modal' ">
        <div class="mobile-modal-header">
            <span class="modal-header-left" @click="closeOverlay">
                <i class="si si-chevron-left"></i>
            </span>
            <span class="modal-header-center">
                {{headerTitle}}
            </span>
            <span class="modal-header-right" @click="saveChanges">
                {{saveTitle}}
            </span>
        </div>
        <div class="mobile-modal-content">
            <div class="modal-content-search">
                <SaiInput  className="content-search-input"
                          :placeholder="inputPlaceholder"
                          type="text"
                          v-model="inputValue"
                          :name="inputName"
                          :disabled="disabled"/>
            </div>
        </div>
    </div>
</template>

<script>
    import SaiInput from '@/components/common/SaiInput';
    export default {
        name: "SearchOverlayInput",
        components: {
            SaiInput
        },
        props: {
            display: {type: Boolean, default: false},
            headerTitle: {type: String, default: ''},
            saveTitle: {type: String, default: ''},
            inputPlaceholder: {type: String, default: ''},
            inputName: {type: String, default: ''},
            inputValue: {type: String, default: ''},
            disabled: {type: Boolean, default: false}
        },
        methods: {
            saveChanges: function() {
                this.$emit('saveChanges', this.inputValue);
            },
            closeOverlay: function () {
                this.search = '';
                this.$emit('closeOverlay')
            },
        }
    }
</script>
