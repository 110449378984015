<template>
   <div class="quickSearchBox">
      <SaiInput type="text" class="mobile-search-input"
                name="name"
                :value="getCollectionsFilter('documentNumber')"
                @input="$event => setStandardsFilter({field: 'documentNumber', payload: $event})"
                :placeholder="$t('frm_collection_document_number')" :disabled="!isActiveTab('collections')"/>
   </div>
</template>

<script>
    import SaiInput from '@/components/common/SaiInput';
    import { createNamespacedHelpers } from 'vuex';
    const { mapGetters, mapActions } = createNamespacedHelpers('searchbar');
    export default {
        name: "QuickSearch",
        components: {
            SaiInput
        },
        computed: {
            ...mapGetters([
                'getCollectionsFilter',
                'isActiveTab'
            ]),
        },
        methods: {
            ...mapActions([
                'setCollectionsFilter'
            ])
        }
    }
</script>

