import Mutation from './mutationTypes';

export default {
    [Mutation.SET_INITIALIZE_ONBOARDING_DETAILS]: (state, onboardingDetailsState) => {
        state = Object.assign(state, onboardingDetailsState);
    },
    [Mutation.SET_ITEM]: (state, { field, value }) => {
        // let localval = value;
        // const evalStr = 'state["' + field.replace(/\./g, '"]["') + '"] = localval';
        // eval(evalStr);
        state[field] = value;
    },
    [Mutation.SET_FORM_ITEM]: (state, { step, field, value }) => {
        state['steps'][step]['fields'][field] = value;
    },
    [Mutation.SET_ERROR]: (state, { step, field, value }) => {
       if(state['error'][step]) {
            state['error'][step][field] = value;
        } 
        else if(state['error']) {
            state['error'][step] = { [field]: value };
        } else {
            state['error'] = { [step]: { [field]: value } };
        }
    },
    [Mutation.SET_WARNING]: (state, { step, field, value }) => {
        if(state['warning'][step]) {
            state['warning'][step][field] = value;
        } 
        else if(state['warning']) {
            state['warning'][step] = { [field]: value };
        } else {
            state['warning'] = { [step]: { [field]: value } };
        }
    }
}