import { extend } from 'vee-validate'
import { min, max, between, required } from "vee-validate/dist/rules";
extend('min', min);
extend('max', max);
extend('between', between);
extend('required', required);
extend('lessThanMaxValue', {
    params: ['maxValue'],
    validate(minValue, { maxValue }) {
        minValue = minValue.toString();
        maxValue = maxValue.toString();
        minValue = minValue.trim() != '' ?  parseFloat(minValue) : '';
        maxValue = maxValue.trim() != '' ?  parseFloat(maxValue) : '';
        if(minValue !='' && isNaN(minValue)) {
            return false;
        }
        if(maxValue !='' && isNaN(maxValue)) {
            return false;
        }
        if(minValue !='' && maxValue !='') {
            return minValue <= maxValue;
        }
        return true;
    },
    message: 'Min value should be less than Max value'
});
extend('uniqueElements', {
    params: [ 'elements', 'ownIndex'],
    validate({id},  { elements, ownIndex } ) {
        let uniqueElements = true;
        elements.forEach((composition, index) => {
            if(ownIndex != index && id &&  composition.elementValue && id == composition.elementValue) {
                uniqueElements = false;
                return;
            } 
        });
        return uniqueElements;
    },
    message: 'Unique Elements must be inserted'
});

extend('elementRequired', {
    validate({id}) {
        if(!id) {
            return false;
        }
        return true;
    },
    message: 'Each row must have an element selected'
});