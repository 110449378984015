<template>
    <div class="row m-0 quick-row">
        <SearchTabs/>
        <QuickSearchStandard v-show="isActiveTab('standards')"/>
        <QuickSearchCollection  v-show="isActiveTab('collections')"/>

    </div>
</template>

<script>
    import SearchTabs from '../SearchBarDesktop/SearchTabs';
    import QuickSearchStandard from './Standards/QuickSearchStandard';
    import QuickSearchCollection from './Collections/QuickSearchCollection';

    import { createNamespacedHelpers } from 'vuex';
    const { mapGetters } = createNamespacedHelpers('searchbar');
    export default {
        name: "QuickSearch",
        components: {
            SearchTabs,
            QuickSearchStandard,
            QuickSearchCollection,
        },
        computed: {
            ...mapGetters([
                'isActiveTab'
            ])
        }
    }
</script>
