<template>
    <div class="sai-auto-suggest">
        <input :class="['autosuggest-input' ,className]"
               type="text"
               autocomplete="off"
               v-bind="$attrs"
               :value="value"
               v-on="inputListeners"
               @keyup="searching=false"
               :api="api"
               @keypress.enter="cancelApi"
               :apiExtraParams="apiExtraParams"
               :apiHeaderContentType="apiHeaderContentType"
               :disabled="disabled"
        />
        <div class="suggests" v-if="searching && results.length > 0">
            <ul class="suggest-results">
                <li v-for="result in results" :key="result[labelBy]"
                    @click="addToInput(result[labelBy])">
                    <span v-html="result[trackBy]"></span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>

    import {debounce} from '@/plugins/axios';
    import axios from '@/plugins/axios';

     const apiSuggest = async (api, value, apiExtraParams, apiHeaderContentType) => {
        // let promise = new Promise((resolve) => {
        //     const data = [
        //         {value: "ASA B16.6.3", label: "<strong>A</strong><strong>S</strong><strong>A</strong> B16.6.3"},
        //         {value: "ASA S3/SC1.4 TR", label: "<strong>A</strong><strong>S</strong><strong>A</strong> S3/SC1.4 TR"},
        //         {value: "ASAE S525", label: "<strong>A</strong><strong>S</strong><strong>A</strong>E S525"},
        //         {value: "ASA B6.1", label: "<strong>A</strong><strong>S</strong><strong>A</strong> B6.1"},
        //         {value: "ASA B2.2", label: "<strong>A</strong><strong>S</strong><strong>A</strong> B2.2"}
        //     ];
        //     setTimeout(() => resolve(data), 2000)
        // });
        // let res = await promise;
        // return res;

        try {
            let options = {};
            let params = {};
            if (apiHeaderContentType === 'form-urlencoded') {
                options = {headers: {'Content-Type': 'application/x-www-form-urlencoded'}};
                params = new URLSearchParams();
                params.append('search' , value);
                if (apiExtraParams) {
                    for (const [key, value] of Object.entries(apiExtraParams)) {
                        params.append(key, value)
                    }
                }
            } else {
                 params = { search : value };
                if (apiExtraParams) {
                    for (const [key, value] of Object.entries(apiExtraParams)) {
                        params[key] = value
                    }
                }
            }

            const response = await axios.post({ endpoint: api, params: params, options: options });
            if(response.data) {
                return response.data;
            } else  {
                return [];
            }
        } catch(e) {
            console.log('There has been a problem with your fetch operation: ' + e.message);
        }
    };

    export default {
        name: "SaiAutoSuggest",
        inheritAttrs: false,
        model: {
            prop: 'value',
            event: 'input'
        },
        props: {
            value: [String, Array],
            className: String,
            api: String,
            apiExtraParams: {type: Object, default: () => {}},
            apiHeaderContentType: {type: String, default: ''},
            labelBy: {type:String, default: 'key' },
            trackBy: {type:String, default: 'val' },
            disabled: {type:Boolean, default: false },
        },
        created() {
            window.addEventListener('click', this.closeList)
        },
        data() {
            return {
                searching: false,
                results: [],
            }
        },
        methods: {
            cancelApi() {
                this.loadSuggest('');
                this.searching = false;
            },
            closeList() {
                this.searching = false;
            },
            addToInput(res) {
                this.searching = false;
                this.$emit('input', res);
                this.$emit('addSuggestValue', res);
            },
            fillResults(response) {
                this.results = response;
            },
            loadSuggest: debounce(async function(value) {
                let response = await apiSuggest( this.api, value, this.apiExtraParams, this.apiHeaderContentType );
                await this.fillResults(response);
                this.searching = true;
            },1000),

        },
        computed: {
            inputListeners: function () {
                var vm = this;
                return Object.assign({},
                    // We add all the listeners from the parent
                    this.$listeners,
                    {
                        // This ensures that the component works with v-model
                        input: function (event) {
                            const value = event.target.value;
                            vm.$emit('input', value);
                            if (value.length >= 2) {
                                vm.loadSuggest(value);
                            }
                        },
                    }
                )
            }
        }
    }
</script>

<style lang="scss" scoped>
    .autosuggest-input {
        background: #fff;
    }
</style>