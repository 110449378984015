<template>
    <Portal to="sai-modal" v-if="display">
        <div ref="elem" :class="'sai-modal-popup show ' + className">
            <div class="modal-content">
                <div class="modal-header">
                    <slot name="title"></slot>
                    <span class="close-popup" @click="closeModal">&times;</span>
                </div>
                <div class="sai-modal-body">
                    <div class="modal-body"><slot name="body"></slot></div>
                </div>
            </div>
        </div>
    </Portal>
</template>
<script>
    export default {
        props: {
            display: {
                type: Boolean,
                default: false
            },
            className: { type: String, default: ''}
        },
        methods: {
            closeModal: function() {
                this.$emit('closeModal')
            }
        }
    }
</script>
