import axios from '@/plugins/axios';
export const apiGetMetalsFieldOptions = async (type) => {
    try {
        const apiEndPoint = {
             mechanical: 'metalsPhysicalProperty',
             chemical: 'metalsElement'
        }
        const response = await axios.get({ endpoint: '/management/search/field/' + apiEndPoint[type]});
        if(response.data.results) {
            return response.data.results;
        } else  {
            return [];
        }
    } catch(e) {
        console.log('There has been a problem with your fetch operation: ' + e.message);
    }
 };

 
 export const apiGetMaterialsFieldOptions = async (type) => {
    try {
        const apiEndPoint = {
            mechanical: "material_mechanical_properties",
            physical: "material_physical_properties",
            electrical: "material_electrical_properties",
            impact: "material_impact_properties",
            processing: "material_processing_properties",
            thermal: "material_thermal_properties",
            other: "material_other_properties",
            country: "material_supplier_country"
        }
        const response = await axios.get({ endpoint: '/management/search/field/' + apiEndPoint[type]});
        if(response.data.results) {
            return response.data.results;
        } else  {
            return [];
        }
    } catch(e) {
        console.log('There has been a problem with your fetch operation: ' + e.message);
    }
 };

export const apiGetFieldOptions = async (type) => {
    try {
        const apiEndPoint = {
            "metals.mechanicalComposition": "metalsPhysicalProperty",
            "metals.chemicalComposition": "metalsElement",
            "metals.country": "metals_country",
            "metals.form": "metals_form",
            "metalsSupplier.establishmentType": "metals_supplier_establishment_type",
            "metalsSupplier.name": "metals_supplier_name",
            "metalsSupplier.country": "metals_country",
            "metalsSupplier.form": "metals_form",
            
            "materials.mechanicalProperty": "material_mechanical_properties",
            "materials.physicalProperty": "material_physical_properties",
            "materials.electricalProperty": "material_electrical_properties",
            "materials.impactProperty": "material_impact_properties",
            "materials.processingProperty": "material_processing_properties",
            "materials.thermalProperty": "material_thermal_properties",
            "materials.otherProperty": "material_other_properties",
            "materials.chemicalAnalysis": "material_chemical_analysis[]",
            "materials.formulation": "material_formulation[]",
            "materials.specialCharacteristics": "material_special_char[]",
            "materials.chemicalResistance": "material_chemical_resistance[]",
            "materials.processing": "material_processing[]",
            "materials.description": "material_description",
            "materials.class": "material_class",
            "materials.form": "material_form[]",
            "materials.productType": "material_product_type",
            "materials.additives": "material_additive[]",
            "materialsSupplier.country": "material_supplier_country",

            "standards.publisher": "publisher",
            "standards.country": "country",
            "collections.collections": "colls[]",
            "collections.companyCollection": "dcoll",
            "collections.tags": "tags[]",

            "categories.publisher": "publisher",
            "categories.country": "country",
            "latest.publisher": "publisher",
            
        }
        const response = await axios.get({ endpoint: '/management/search/field/' + apiEndPoint[type]});
        if(response.data.results) {
            if(type=='collections.tags') {
                const options = response.data.results; 
                let tagOptions = [];
                options.forEach(eleOption => { 
                    tagOptions.push( { id: eleOption['text'], text: eleOption['text'] });
                });
                return tagOptions;
            } else {
                return response.data.results;
            }
        } else  {
            return [];
        }
    } catch(e) {
        console.log('There has been a problem with your fetch operation: ' + e.message);
    }
 };


 export const apiGetStandardsPublishers = async (api) => {
    try {
        const response = await axios.get({ endpoint: api});
        if(response.data.results) {
            return response.data.results;
        } else  {
            return [];
        }
    } catch(e) {
        console.log('There has been a problem with your fetch operation: ' + e.message);
    }
};

export const apiGetICSCodeOptions = async (icsCode) => {
    try {
        const response = await axios.get({ endpoint: '/management/search/ajax_getNextICS/' + icsCode });
        if(response.data) {
            let options  = [];
            if(response.data.data && response.data.data.labels) {
                const codes = response.data.data.codes;
                const labels = response.data.data.labels;
                labels.forEach((label, index) => { 
                    options.push( { id: codes[index], text: label });
                });
            }
            return options;
        } else  {
            return [];
        }
    } catch(e) {
        console.log('There has been a problem with your fetch operation: ' + e.message);
    }
};