import Mutation from './mutationTypes';
import {apiSearchResults} from "@/components/SearchResults/api";
export default {
    setPWAStatus: ({ commit }, value) => {
        commit(Mutation.SET_PWA_STATUS, value);
    },
    setOrderBy: ({ commit }, value) => {
        commit(Mutation.SET_ORDER_BY, value);
    },
    setLoadMoreLoadingStatus: ({ commit }, status) => {
        commit(Mutation.SET_LOADMORE_LOADING_STATUS, status);
    },
    setLoadingStatus: ({ commit }, blnStatus) => {
        commit(Mutation.SET_LOADING_STATUS, blnStatus);
    },
    setCurrentPage: ({ commit }, pageNumber) => {
        commit(Mutation.SET_PAGE_NUMBER, pageNumber);
    },
    setSearchResults: ({ commit }, searchResultsValues) => {
        commit(Mutation.SET_SEARCH_RESULTS, searchResultsValues);
    },
    setBulkSelectedRecords: ({ commit }, bulkSelectedRecords) => {
        commit(Mutation.SET_BULK_SELECTED_RECORDS, bulkSelectedRecords);
    },
    setActiveTab: ({ commit }, tab) => {
        commit(Mutation.SET_ACTIVE_TAB, tab);
    },
    setIsSavedSearch: ({ commit }, isSavedSearch) => {
        commit(Mutation.SET_IS_SAVED_SEARCH, isSavedSearch);
    },
    setSearchResultsFilterSelectedValues: ({ commit }, {field,fieldType, payload}) => {
        commit(Mutation.SET_SEARCH_RESULTS_FILTER_SELECTED_VALUES, {field,fieldType, payload});
    },
    setCanReset: ({ commit }, blnStatus) => {
        commit(Mutation.SET_CAN_RESET, blnStatus);
    },
    setSearchResultActive:  ({ commit }, isSearchResultActive) => {
        commit(Mutation.SET_IS_SEARCH_RESULT_ACTIVE, isSearchResultActive);
    },
    setSearchResultField: ({ commit }, {field, value}) => {
        commit(Mutation.SET_SEARCH_RESULT_FIELD, {field, value});
    },
    fetchItems: async ({ commit, getters }, activeTab) => {
        try {
            if (getters.getPWAStatus) {
                commit(Mutation.SET_LOADMORE_LOADING_STATUS, true);
            } else {
                commit(Mutation.SET_LOADING_STATUS, true);
                commit(Mutation.SET_SEARCH_PROGRESS_STATUS, 'start');
            }

            let criteria = getters.getSearchCriteria(activeTab);
            let tab = activeTab;

            let results = await apiSearchResults(tab, criteria);
            if (getters.getPWAStatus) {
                commit(Mutation.SET_SEARCH_RESULTS_PWA, results.data);
            } else {
                commit(Mutation.SET_SEARCH_RESULTS, results.data);
            }
            if(results.status != 'error') {
                const searchContext = getters.getSearchResultsField('searchContext');
                if(typeof(results.data) != 'undefined' &&
                    typeof(results.data.hits) != 'undefined' &&
                    results.data.hits == 1) {
                    let docId = results.data.docIds[0];
                    if(typeof(docId) == 'undefined') {
                        docId = results.data.records[0].doc_id;
                    }
                    let productPageURL = '/management/display/index/0/' + docId + '/-/' + searchContext;
                    document.location = productPageURL;
                }
                else if(typeof(results.data) != 'undefined' &&
                    typeof(results.data.hits) != 'undefined' &&
                    results.data.hits == 0) {
                    let productPageURL = '/management/search/index/0/-/' + searchContext;
                    document.location = productPageURL;
                }
                else {
                    if (getters.getPWAStatus) {
                        commit(Mutation.SET_LOADMORE_LOADING_STATUS, false);
                    } else {
                        commit(Mutation.SET_SEARCH_PROGRESS_STATUS, 'complete');
                        commit(Mutation.SET_LOADING_STATUS, false);
                    }
                }
            } else if(results.status == 'error' && results.code == "401") { //if nolonger authenticated go to home page
                document.location = '/management/home/index';
            }

        } catch(error) {
            console.log('apiSearchResults failed.');
            console.log(error);
            if (getters.getPWAStatus) {
                commit(Mutation.SET_LOADMORE_LOADING_STATUS, false);
            } else {
                commit(Mutation.SET_SEARCH_PROGRESS_STATUS, 'complete');
                commit(Mutation.SET_LOADING_STATUS, false);
            }
        }

    },
    resetFilters: async ({ commit, getters }) => {
        try {
            commit(Mutation.SET_LOADING_STATUS, true);

            let criteria = {};
            let tab = getters.getActiveSearchTab;
            let page = getters.getCurrentPage;
            let orderBy = getters.getSearchResultsField('orderBy');

            let initialQuery = getters.getSearchResultsField('initialQuery');
            for (const [key, value] of Object.entries(initialQuery)) {
                criteria[key] = value;
            }

            criteria['page'] = page;
            criteria['orderBy'] = orderBy;

            let results = await apiSearchResults(tab, criteria);

            commit(Mutation.SET_SEARCH_RESULTS, results.data);
            commit(Mutation.SET_LOADING_STATUS, false);
        } catch(error) {
            console.log('apiSearchResults failed.');
            console.log(error);
            commit(Mutation.SET_LOADING_STATUS, false);
        }
    },
    performDocAction: async ({ commit }, {action, collectionId, docIds, isBulkAction}) => {
        try {
            const bulkActionProcessing = isBulkAction;
            const actionProcessing = (isBulkAction == 1) ? 0 : 1;
            commit(Mutation.SET_DOC_ROW_ACTION_PROGRESS, { action, docIds, actionProcessing, bulkActionProcessing});
            let results = [];
            switch(action) {
                case 'addItem':
                    results =  await apiSearchResults('addToCollection', { collectionId, docIds });
                    break;
                case 'remItem':
                    results =  await apiSearchResults('removeFromCollection', { collectionId, docIds });
                    break;
                case 'watchItem':
                    results =  await apiSearchResults('addToWatch', { collectionId, docIds });
                    break;
                case 'unWatchItem':
                case 'unWatchItemGlobal':
                    results =  await apiSearchResults('removeFromWatch', { collectionId, docIds });
                    break;
            }
            
            if(results.status != 'error') {
                const { collectionId: activeCollectionId, collectionName:  activeCollectionName, warnMsg,  successMsg} = results.data; // get the collection id after row action, as it can be a new collection id
                commit(Mutation.SET_SEARCH_RESULT_FIELD, { field: 'activeCollectionId', value: activeCollectionId}); //this set new collection id as acive collection
                commit(Mutation.SET_SEARCH_RESULT_FIELD, { field: 'activeCollectionName', value: activeCollectionName}); 
                if(warnMsg && warnMsg.length) {
                    for(let msg of warnMsg) {
                        commit(Mutation.SET_TOASTR_MESSAGE,{ type: 'warning',  message: msg });
                    }
                }
                if(successMsg && successMsg.length) {
                    for(let msg of successMsg) {
                        commit(Mutation.SET_TOASTR_MESSAGE,{ type: 'success',  message: msg });
                    }
                }
                results =  await apiSearchResults('getDocumentActions', { activeCollectionId, docIds });
                if(results.status != 'error' && results.data) {
                    for(const record of results.data) {
                        commit(Mutation.SET_ROW_FIELDS, { docId: record.doc_id, fieldValues: { actions: record.actions, collection: record.collection } });            
                    }
                }
            }
            commit(Mutation.SET_DOC_ROW_ACTION_PROGRESS, { action, docIds, actionProcessing: 0, bulkActionProcessing: 0});
        } catch(error) {
            commit(Mutation.SET_DOC_ROW_ACTION_PROGRESS, { action, docIds, actionProcessing: 0, bulkActionProcessing: 0 });
            console.log('add to watch api call failed for Doc Ids  ' + docIds);
            console.log(error);
        }
    },
    performMainSearch:  async ({ dispatch, commit, getters, rootGetters }) => {
        try {
            const isAdvancedSearchActiveAndAllowed = (tab) => {
                const isAdvanceSearchAllowed =  rootGetters['searchbar/isAdvanceSearchAllowed'](tab);
                const isAdvanceSearchActive =  rootGetters['searchbar/isAdvanceSearchActive'](tab);
                return (isAdvanceSearchAllowed && isAdvanceSearchActive);
            };
            commit(Mutation.SET_LOADING_STATUS, true);
            commit(Mutation.SET_IS_SEARCH_RESULT_ACTIVE, 1);
            commit(Mutation.SET_SEARCH_PROGRESS_STATUS, 'start');
            let params = {};
            const activeTab =  rootGetters['searchbar/getActiveSearchTab'];
            const getSearchBarField = rootGetters['searchbar/getItem'];
            const isActivePwa = rootGetters['searchbar/isActivePWA'];

            if (activeTab === 'standards') {
                params = {
                    query_1: getSearchBarField('standards.documentNumber').trim(),
                    query_2: getSearchBarField('standards.documentTitle').trim(),
                    query_5: '',
                    include: [],
                    exclude: [],
                    country: '',
                    publisher: '',
                    dataset: '',
                    mainsearch: 1 //set to 1 if the search is performd from the search bar
                };
                if( isAdvancedSearchActiveAndAllowed(activeTab) ) {
                    let status = getSearchBarField('standards.status');
                    let include = [];
                    for (let statusItem of status) {
                        include.push(statusItem.id);
                    }
                    params['query_5'] = getSearchBarField('standards.crossReference');
                    params['include'] = include;
                    params['exclude'] = [];
                    if(getSearchBarField('standards.country').id) {
                        params['country'] = getSearchBarField('standards.country').id;
                    }
                    if(getSearchBarField('standards.publisher').id) {
                        params['publisher'] = getSearchBarField('standards.publisher').id;
                    }
                    if(getSearchBarField('standards.dataset').id !== '') {
                        params['dataset'] = getSearchBarField('standards.dataset').id;
                    }
                }

                const isAdvanceSearchFilled = params['include'].length || params['exclude'].length || params['query_5'] || params['country'] || params['publisher'] || (params['dataset'] !=='');
                const isQuickSearchFilled = params['query_1'] || params['query_2'];
                if(isAdvanceSearchFilled && !isQuickSearchFilled) {
                    params['query_1'] = '*';
                    dispatch('searchbar/setItem', { field: 'standards.documentNumber', payload: '*' }, {root:true});
                }

            } else if (activeTab === 'collections') {
                params = {
                    name: getSearchBarField('collections.documentNumber').trim(),
                    notes: '',
                    'filter-tags': [],
                    'filter-include': [],
                    'filter-type': '',
                    'filter-colls': [],
                    'filter-dcoll': '',
                    'filter-exclude': [],
                    mainsearch: 1 //set to 1 if the search is performd from the search bar
                };
                if( isAdvancedSearchActiveAndAllowed(activeTab) ) {
                    params['notes'] = getSearchBarField('collections.notes').trim();
                    let status = getSearchBarField('collections.status');
                    let include = [];
                    for (let statusItem of status) {
                        include.push(statusItem.id);
                    }
                    params['filter-include'] = include;
                    params['filter-exclude'] = [];

                    if(getSearchBarField('collections.documentType').id) {
                        params['filter-type'] = getSearchBarField('collections.documentType').id;
                    }
                    if(getSearchBarField('collections.companyCollection')) {
                        params['filter-dcoll'] = getSearchBarField('collections.companyCollection').id;
                    }
                    let colls = getSearchBarField('collections.collections');
                    let collections = [];
                    for (let coll of colls) {
                        collections.push(coll.id);
                    }
                    params['filter-colls'] = collections;

                    let tagList = getSearchBarField('collections.tags');
                    let tags = [];
                    for (let tg of tagList) {
                        tags.push(tg.id);
                    }
                    params['filter-tags'] = tags.join('|');
                }
            }

            let results = await apiSearchResults(activeTab, params);
            if(results.status != 'error') {
                commit(Mutation.SET_SEARCH_RESULTS, results.data);    
                const searchContext = getters.getSearchResultsField('searchContext');
                if(typeof(results.data) != 'undefined' &&
                    typeof(results.data.hits) != 'undefined' &&results.data.hits == 1) {
                    let docId = results.data.docIds[0];
                    if(typeof(docId) == 'undefined') {
                        docId = results.data.records[0].doc_id;
                    }
                    let productPageURL = '/management/display/index/0/' + docId + '/-/' + searchContext;
                    document.location = productPageURL;
                }
                else if(typeof(results.data) != 'undefined' &&
                    typeof(results.data.hits) != 'undefined' &&
                    results.data.hits == 0) {

                    let productPageURL = '/management/search/index/0/-/' + searchContext;
                    document.location = productPageURL;
                }
                else {
                    commit(Mutation.SET_SEARCH_PROGRESS_STATUS, 'complete');
                    commit(Mutation.SET_LOADING_STATUS, false);
                    if (isActivePwa) {
                        dispatch('searchbar/setDisplaySearch', false, {root:true});
                    }
                }
            } else if(results.status == 'error' && results.code == "401") { //if nolonger authenticated go to home page
                document.location = '/management/home/index';
            } else {
                const resetSearchResults = {
                    actions: [],
                    summary: "",
                    initialQuery: {},
                    "records": [],
                    "hits": 0,
                    "total": 0,
                    "docIds": [],
                    "facets": {},
                    "cached": false,
                    "filters": {},
                    "nohits": true
                };
                commit(Mutation.SET_SEARCH_RESULTS, resetSearchResults);    
                commit(Mutation.SET_TOASTR_MESSAGE,{ type: 'error',  message: results.data.reason });
                commit(Mutation.SET_SEARCH_PROGRESS_STATUS, 'complete');
                commit(Mutation.SET_LOADING_STATUS, false);
            }
        } catch(error) {
            console.log('api main search failed.');
            console.log(error);
            commit(Mutation.SET_SEARCH_PROGRESS_STATUS, 'complete');
            commit(Mutation.SET_IS_SEARCH_RESULT_ACTIVE, 0);
            commit(Mutation.SET_LOADING_STATUS, false);

        }
    },
    setToastrMsg: ({ commit }, { type, message }) => {
        commit(Mutation.SET_TOASTR_MESSAGE, { type, message });
    },
    performExport: async ({ commit, getters, rootGetters }) => {
        try {
            commit(Mutation.SET_EXPORT_STATUS, 'inprogress');
            const activeTab =  rootGetters['searchbar/getActiveSearchTab'];
            let criteria = getters.getSearchCriteria(activeTab);
            criteria['fieldsToExport'] = getters.getSelectedFieldsToExport;
            criteria['csvSeparator'] = getters.getExportCsvSeparator;
            let results = await apiSearchResults(activeTab + '/export', criteria);
            if(results.status != 'error') {
                commit(Mutation.SET_EXPORT_RESULT, results.data);
                commit(Mutation.SET_EXPORT_STATUS, 'complete');
            } else if(results.status == 'error' && results.code == "401") { //if nolonger authenticated go to home page
                commit(Mutation.SET_EXPORT_STATUS, 'completewitherror');
                document.location = '/management/home/index';
            } else {
                commit(Mutation.SET_TOASTR_MESSAGE,{ type: 'error',  message: results.data.reason });
                commit(Mutation.SET_EXPORT_STATUS, 'completewitherror');
            }
        } catch(error) {
            commit(Mutation.SET_EXPORT_STATUS, 'completewitherror');
            console.log('apiSearchResults export failed.');
            console.log(error);
        }

    },
    setExportStatus: ({ commit }, exportStatus) => {
        commit(Mutation.SET_EXPORT_STATUS, exportStatus);
    },
    setSelectedFieldsToExport: ({ commit }, selectedFieldsForExport) => {
        commit(Mutation.SET_SELECTED_FIELDS_FOR_EXPORT, selectedFieldsForExport);
    },
    setExportResult: ({ commit }, exportResult) => {
        commit(Mutation.SET_EXPORT_RESULT, exportResult);
    },
    setExportCsvSeparator: ({ commit }, exportCsvSeparator) => {
        commit(Mutation.SET_EXPORT_CSV_SEPARATOR, exportCsvSeparator);
    },
}