<template>
    <div>
        <SearchBoxMobile
                :title="title"
                datatype="object"
                :selectedContent="getItem(field)"
                @clickHandler="overlay=true"/>

        <OverlaySingleSelect v-if="overlay"
                             :searchable="searchable"
                             :hasClearSelect="true"
                             :display="overlay"
                             :saveTitle="$t('frm_done')"
                             @closeOverlay="overlay=false"
                             @saveChanges="overlay=false"
                             :preSelected="getItem(field)"
                             @selectItem="selectItem"
                             @clearSelected="clearItem"
                             :headerTitle="title"
                             :field="field"
                             :lookupField="lookupField"/>
    </div>
</template>

<script>
    import {createNamespacedHelpers} from 'vuex';
    const {mapGetters, mapActions} = createNamespacedHelpers('searchbar');
    import OverlaySingleSelect from '@/components/SearchBar/SearchBarMobile/OverlaySingleSelect';
    import SearchBoxMobile from '@/components/common/SearchBoxMobile';
    export default {
        name: "FilterBoxSelect",
        props: {
            title: {type: String, default: ''},
            field: {type: String, default: '', required: true},
            overlay: {type: Boolean, default: false},
            searchable: {type: Boolean, default: false, required:false},
            lookupField: { type: String, default: '', required: false },
        },
        components: {
            SearchBoxMobile,
            OverlaySingleSelect
        },
        computed: {
            ...mapGetters([
                'getItem',
            ]),
        },
        methods: {
            ...mapActions([
                'setItem',
            ]),
            selectItem: function(value) {
                this.overlay = false;
                this.setItem({  field: this.field, payload: value })
            },
            clearItem: function() {
                this.setItem({  field: this.field, payload: '' })
            }
        }
    }
</script>

<style scoped>

</style>