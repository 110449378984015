<template>
    <SaiModal :display="display" @closeModal="closeFilterPoup">
        <span slot="title">{{$t("frm_" + type.replace("Property", "")  + "_properties")}}</span>
        <div slot="body">
            <ValidationObserver slim ref="validationObserver">
            <div class="modalBasic composition-form ui-dialog-content ui-widget-content" style="width: auto; min-height: 48.833px; max-height: none; height: auto;">
                <div :class="'composition-container ' + type + '-composition-container'">
                    <div class="composition-box-container">
                        <div v-for="(property, index) in properties" :key="index" :class="'element-box ' + type + '-element-box'">
                                <div :class="'property element sai-field ' + type + 'ElementSearch'">
                                    <ValidationProvider :bails="false" :vid="'elementValue' + index" slim :rules="{ elementRequired: true }">
                                        <FilterDropDown
                                            :value ="{ id: property.elementValue, text: property.element }"
                                            @change = "selectElement($event, index)"
                                            :name="'materials_' + type + '_' + index" 
                                            labelBy="text"
                                            trackBy="id"
                                            :field="'materials.' + type"
                                            :placeholder="placeholder" />
                                    </ValidationProvider>
                                </div>
                                
                                <div class="min-value sai-field">
                                    <ValidationProvider :bails="false" :vid="'minValue' + index" slim :rules="{ lessThanMaxValue: { maxValue: '@maxValue' + index} }">
                                        <SaiInput type="number" :placeholder="$t('min')" class="sai-input minValue" name="minValue" v-model="property.minValue"/>
                                    </ValidationProvider>
                                </div>
                                
                                
                                <div class="max-value sai-field">
                                    <ValidationProvider :bails="false" :vid="'maxValue' + index" slim>
                                        <SaiInput type="number" :placeholder="$t('max')" class="sai-input maxValue" name="maxValue" v-model="property.maxValue"/>
                                    </ValidationProvider>
                                </div>   
                                <div class="deviation sai-field">
                                    <ValidationProvider :bails="false" :vid="'deviation' + index" slim rules="between:1,100">
                                        <SaiInput type="number" :placeholder="$t('per_deviation')" class="sai-input deviation" name="deviation" v-model="property.deviation"/>
                                    </ValidationProvider>
                                </div>
                                <div class="delete-element"><i class="si si-bin" v-if="index > 0" @click="deleteFilter($event, index)"></i></div>
                        </div>
                    </div>
                    <div class="add-more-element">
                        <span @click="addFilter"> {{ $t("add_more") }} </span>
                    </div>
                    <div class="btn-groups">
                        <span :class="'sai-btn-secondary save save' + type + 'Composition'" @click="saveFilters">{{ $t("save") }}</span>
                        <span :class="'sai-btn-action discard' + type" @click="discardFilters">{{ $t("discard") }}</span>
                    </div>
                    <div class="error-message">
                        <span>{{ getMaterialsPropertyFiltersErrMsg(type) }}</span>
                    </div>
                </div>

            </div>
            </ValidationObserver>
        </div>
    </SaiModal>
</template>
<script>
    import SaiInput from '@/components/common/SaiInput';
    import SaiModal from '@/components/common/SaiModal';
    import FilterDropDown from '../../FilterDropDown';
    import { createNamespacedHelpers } from 'vuex';
    const { mapGetters, mapActions } = createNamespacedHelpers('searchbar');
    const newChemComp = { element:'', elementValue:'', minValue:'', maxValue:'', singleValue:'', deviation:'', isLoading: false };
    import {ValidationProvider, ValidationObserver } from 'vee-validate'
    import '../../validation';
    import { apiGetMaterialsFieldOptions } from '@/components/SearchBar/api';
    export default {
        name: 'FilterPopupProperties',
        props: {
            type: { type: String,  default: '', required: true },
            placeholder: { type: String, required: true },
            display: { type: Boolean, default: false, required: true}
        },
        components: {
            SaiInput,
            SaiModal,
            FilterDropDown,
            ValidationProvider,
            ValidationObserver
        },
        data: function () {
            return {
                properties: [],
                errorMsgs: '',
                elementOptions: [],
                initilizeElementOptions: true
            }
        },
        mounted() {
            this.initPropertiesFiltersFromState();
        },
        computed: {
            ...mapGetters([
                'getMaterialsPropertyFilters',
                'getMaterialsPropertyFiltersErrMsg'
            ])
        },
        methods: {
            ...mapActions([
                'setMaterialsPropertyFilters',
                'showMaterialsPropertyFilters',
                'setMaterialsPropertyFiltersErrMsg'
            ]),
            closeFilterPoup() {
                this.initPropertiesFiltersFromState();
                this.showMaterialsPropertyFilters({type: this.type, show: false});
            },
            addFilter() {
                this.properties.push({ ...newChemComp });
            },
            discardFilters() {
                this.properties = [{ ...newChemComp }];
                this.setMaterialsPropertyFilters({type: this.type, properties: []});
                this.showMaterialsPropertyFilters({type: this.type, show: false});
            },
            async saveFilters() {
                const validationInfo = await this.$refs.validationObserver.validateWithInfo();
                if(!validationInfo.isValid) {
                    for (const arrFields of Object.entries(validationInfo.fields)) {
                        const fieldInfo = arrFields[1];
                        if(!fieldInfo.isValid) {
                            if (fieldInfo.failedRules.between) {
                                this.setMaterialsPropertyFiltersErrMsg({type: this.type, errMsg: this.$t("err_num_between")});
                                break;   
                            }
                            if (fieldInfo.failedRules.elementRequired) {
                                this.setMaterialsPropertyFiltersErrMsg({type: this.type, errMsg: this.$t("err_ele_required")});
                                break;
                            }
                            if (fieldInfo.failedRules.lessThanMaxValue) {
                                this.setMaterialsPropertyFiltersErrMsg({type: this.type, errMsg: this.$t("err_min_max")});
                                break;
                            }
                            if (fieldInfo.failedRules.uniqueElements) {
                                this.setMaterialsPropertyFiltersErrMsg({type: this.type, errMsg: this.$t("err_unique_ele")});
                                break;
                            }
                        }
                    }
                } else {
                    let properties = JSON.stringify(this.properties);
                    properties = JSON.parse(properties);
                    this.setMaterialsPropertyFilters({type: this.type, properties});
                    this.showMaterialsPropertyFilters({type: this.type, show: false});
                }
            },
            deleteFilter(event, index) {
                this.properties.splice(index, 1);
            },
            selectElement(item, index) {
                this.properties[index].element = item.text;
                this.properties[index].elementValue = item.id;
            },
            async loadElements(event, index) {
                if(this.initilizeElementOptions) {
                    this.properties[index].isLoading = true;
                    this.elementOptions = await apiGetMaterialsFieldOptions(this.type);
                    this.properties[index].isLoading = false;
                    this.initilizeElementOptions = false;
                }
            },
            initPropertiesFiltersFromState() {
                const materialsProperties = this.getMaterialsPropertyFilters(this.type);
                if(materialsProperties.length) {
                    //need to create newly refrenced array, as we shouldnot modify vuex store data without vuex mutations
                    let preselectdproperties = JSON.stringify(materialsProperties);
                    this.properties = JSON.parse(preselectdproperties);
                } else {
                    this.properties = [{ ...newChemComp }];
                }
            }
        },
        watch: {
            display: function(newValue) {
                //when popup is displayed load the initial compositions from state
                if(newValue) {
                    this.initPropertiesFiltersFromState();
                }
            }
        }
    }
</script>