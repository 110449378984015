export const findEle = (tagName, innerHTML) => {
    let list = document.getElementsByTagName(tagName);
    for (let i = 0; i < list.length; i++) {
        if (list[i].innerHTML == innerHTML) {
            return list[i];
        }
    }
}


export const show = (startNode,startIsText,startOffset,endNode,endIsText,endOffset,sP,eP) => {
    var s, e;
    if (startIsText) {
        let childs = sP.childNodes;
        for (let i = 0; i < childs.length; i++) {
            if (childs[i].nodeType == 3 && childs[i].nodeValue == startNode)
                s = childs[i];
        }
    } else {
        s = startNode;
    }
    if (endIsText) {
        let childs = eP.childNodes;
        for (let i = 0; i < childs.length; i++) {
            if (childs[i].nodeType == 3 && childs[i].nodeValue == endNode)
                e = childs[i];
        }
    } else {
        e = endNode;
    }
    let range = document.createRange();
    range.setStart(s, startOffset);
    range.setEnd(e, endOffset);
    let sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
    return sel;
}

export const use = (highlight) => {
    const obj = highlight.offsets
    const sP = findEle(obj.startTagName, obj.startHTML);
    const eP = findEle(obj.endTagName, obj.endHTML);
    if(!sP || !eP) {
        return
    }
    let sel = show(
        obj.startNode,
        obj.startIsText,
        obj.startOffset,
        obj.endNode,
        obj.endIsText,
        obj.endOffset,
        sP,
        eP
    );
    sel = sel.getRangeAt(0);
    let selectedContent = sel.extractContents();
    let mark = document.createElement("mark");
    mark.className = "highlighted-text";
    mark.setAttribute('id', 'highlightId'+highlight.highlightId);
    mark.setAttribute('data-id', highlight.highlightId);
    mark.appendChild(selectedContent);
    sel.insertNode(mark);
    removeSelection();
}

export const removeSelection = () => {
    if (window.getSelection) {
        if (window.getSelection().empty) {
            // Chrome
            window.getSelection().empty();
        } else if (window.getSelection().removeAllRanges) {
            // Firefox
            window.getSelection().removeAllRanges();
        }
    } else if (document.selection) {
        // IE?
        document.selection.empty();
    }
}

export const unHighlight = (highlightId) => {
    let targets = document.querySelectorAll("[id^=highlightId" + highlightId + "]");
    for (let target of targets) {
        target.outerHTML = target.innerHTML;
    }
}

